import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ComplextionViewModel } from 'src/app/shared/ViewModels/ComplextionViewModel';
import { ComplextionService } from 'src/app/shared/complextion/complextion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complextion',
  templateUrl: './complextion.component.html',
  styleUrls: ['./complextion.component.css']
})
export class ComplextionComponent implements OnInit {

  ComplextionList: any;
    complextion: any;
    complextionId: any; genid: any;
  
    constructor(private complextionService:ComplextionService , private router: Router) { }
  
    ngOnInit() {
      this.Complextion();
      this.complextion = "";
    }
    ResetData(){
      this.complextion = "";
    }
  
   Complextion() {
      this.complextionService.getComplextion().subscribe((Data: ComplextionViewModel[]) => {
        this.ComplextionList = Data;
      })
    }
  
    SaveComplextionForm(form: NgForm) {
      const inputRequest: ComplextionViewModel = {
        ID: 0,
        ComplextionType: form.controls["complextion"].value,
      }
      this.complextionService.saveComplextion(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/complextion']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/complextion']);
        }
      });
    }
  
    editcomplextion(data: { complextionType: any; id: any; }) {
      this.complextion = data.complextionType;
      this.complextionId = data.id;
    }
  
    UpdateComplextion(form: NgForm) {
      const inputRequest1: ComplextionViewModel = {
        ID: this.complextionId,
        ComplextionType: form.controls["complextion"].value,
      }
      this.complextionService.UpdateComplextion(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Complextion Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletecomplextion(data: { id: any; }) {
      this.genid = data.id;
      this.complextionService.deletecomplextion(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Complextion Deleted Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  