import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { SavepaymentDetails } from 'src/app/shared/ViewModels/SavepaymentDetails';

@Component({
  selector: 'app-payu-plans',
  templateUrl: './payu-plans.component.html',
  styleUrls: ['./payu-plans.component.scss']
})
export class PayuPlansComponent implements OnInit {
  PayuPlans: any;closeResult:any;
  plandetails: any;
  selectedplan: any;
  plantype: any;
  period: any;
  storage: any;
  price: any;
  isLoggedIn: boolean;
  accountid: string;
  orderid: any;
  CardDetails: any;
  planid: any;
  Customers: any;
  roleid: string;

  constructor(private userService: UserServiceService,private modalService: NgbModal,private route : Router,private router:ActivatedRoute,) { 

    this.router.params.subscribe(params=>{
      if(params["id"]){
        this.orderid= params["id"];
      }
    });
    if(localStorage.getItem("IsLoggedIn")=="true")
    {
      this.isLoggedIn=true;
      this.accountid = localStorage.getItem("uid")
      this.roleid=localStorage.getItem("role");
    }
  }

  ngOnInit() {
    this.GetFriendDetails();
    this.GetAllCustomers();
  }
  GetFriendDetails(){
    this.userService.getPayuPlans().subscribe((respose:any) =>{
      this.PayuPlans = respose;     
  });
}

GetAllCustomers(){
  this.userService.getAllCustomers().subscribe((respose:any) =>{
    this.Customers = respose;
   });
}

SelectedPlans(plans,PlansContent) {
  this.plandetails=plans;
  this.planid = plans.id;
  this.plantype = plans.type;
  this.price = plans.price;
  this.modalService.open(PlansContent).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
    
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
         this.plandetails=this.selectedplan;
  });
}

payforplan(){
  // if(this.isLoggedIn == true){
    const aa:SavepaymentDetails = {
      CustomerId:this.orderid,
      Amount:this.price,
      PlanType:this.planid
    }
    this.userService.SaveOrderDetails(aa).subscribe((data:any)=>{
        this.CardDetails = data;
        this.orderid = data;
        if(data!=null){
          this.route.navigate(['/payment',this.orderid]);
          this.getDismissReason("Cross click")
        }
        else{
          alert("Something went wrong")
        }     
    });
 // }
  // else{
  //   this.route.navigate(['/login']);
  // }
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return  `with: ${reason}`;
  }
}

PaybyAdmin(id) {
  const aa:SavepaymentDetails = {
    CustomerId:id,
    Amount:this.price,
    PlanType:this.planid
  }
  this.userService.SaveOrderDetails(aa).subscribe((data:any)=>{
      this.CardDetails = data;
      this.orderid = data;
      if(data!=null){
        this.route.navigate(['/payment',this.orderid]);
        this.getDismissReason("Cross click")
      }
      else{
        alert("Something went wrong")
      }     
  });
}

}
