import { Component, OnInit } from '@angular/core';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  profile:any;
  isLoggedIn: boolean;
  uid: string;
  accid: string;
  constructor(private userservice:UserServiceService ) { 
    if(localStorage.getItem("IsLoggedIn")=="true")
    {
      this.isLoggedIn=true;
      this.uid = localStorage.getItem("userId");
      this.accid = localStorage.getItem("uid");
           
    }
  }

  ngOnInit() {
    this.GetProfileDetails();
  }
  GetProfileDetails(){
    this.userservice.getProfileDetails(this.uid).subscribe((respose:any) =>{
      this.profile = respose;
       });
}
}
