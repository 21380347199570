import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PhysicalStatusViewModel } from '../ViewModels/PhysicalStatusViewModel';

@Injectable({
  providedIn: 'root'
})
export class PhysicalstatusService {
 
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  savePhysicalStatus(data: PhysicalStatusViewModel) {
    return this.http.post(this.rooturl1 + "/api/PhysicalStatusMaster/PostSavePhysicalStatus", data);
  }
  getPhysicalStatus() {
    return this.http.get(this.rooturl1 + "/api/PhysicalStatusMaster/GetPhysicalStatus");
  }
  deletephysicalstatus(id: number) {
    return this.http.delete(this.rooturl1 + "/api/PhysicalStatusMaster/DeletePhysicalStatusData/" + id);
  }

  UpdatePhysicalStatus(data: PhysicalStatusViewModel) {
    return this.http.put(this.rooturl1 + "/api/PhysicalStatusMaster/PutPhysicalStatus", data);
  }
}

