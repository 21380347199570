import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/shared/role/role.service';
import { Router } from '@angular/router';
import { RoleViewModel } from 'src/app/shared/ViewModels/RoleViewModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  
    RoleList: any;
    role: any;
    roleId: any; genid: any;
    
      constructor(private roleService:RoleService , private router: Router) { }
    
      ngOnInit() {
        this.Role();
        this.role = "";
      }
      ResetData(){
        this.role = "";
      }
    
      Role() {
        this.roleService.getRole().subscribe((Data: RoleViewModel[]) => {
          this.RoleList = Data;
        })
      }
    
      SaveRoleForm(form: NgForm) {
        const inputRequest: RoleViewModel = {
          RoleID: 0,
          RoleName: form.controls["role"].value,
          CreatedBy:"",
          LastUpdated:""
        }
        this.roleService.saveRole(inputRequest).subscribe((data: any) => {
          if (data != null) {
            alert("Added Successfully");
            this.router.navigate(['/role']);
          }
          else {
            alert("Something went Wrong");
            this.router.navigate(['/role']);
          }
        });
      }
    
      editrole(data: { roleName: any; roleID: any; }) {
        this.role = data.roleName;
        this.roleId = data.roleID;
      }
    
      UpdateRole(form: NgForm) {
        const inputRequest1: RoleViewModel = {
          RoleID: this.roleId,
          RoleName: form.controls["role"].value,
          CreatedBy:"",
          LastUpdated:""
        }
        this.roleService.UpdateRole(inputRequest1).subscribe(
          (data: any) => {
            if (data == 1) {
              alert("Role Updated Succesfully");
              window.location.reload();
            } else {
              alert("Something Went Wrong. Try again.!!");
              window.location.reload();
            }
          });
      }
      deleterole(data: { roleID: any; }) {
        this.genid = data.roleID;
        this.roleService.deleterole(this.genid).subscribe(
          (data: any) => {
            if (data != null) {
              alert("Role Deleted Succesfully");
              window.location.reload();
            } else {
              alert("Role Went Wrong. Try again.!!");
              window.location.reload();
            }
          });
      }
    
    }
    
    
      
  
    