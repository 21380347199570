import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { Friend } from 'src/app/shared/ViewModels/Friend';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
  closeResult: any; m: any; accid: any; accountid: any;
  isLoggedIn: any; uid: any; details: any; msg: any;
  Friends: any;

  constructor(private userservice: UserServiceService, private router: Router, private modalService: NgbModal) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.accountid = localStorage.getItem("uid")
    }
  }

  GetDetails() {
    this.userservice.friendlist(this.uid).subscribe((respose: any) => {
      this.Friends = respose;

    });
  }

  ngOnInit() {
    this.GetDetails();
  }

  addFriend(addfriendcontent) {
    this.modalService.open(addfriendcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.accid;
    });
  }

  savefrienddetails() {
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.uid,
      Status: 4,
      IsEnabled: true,

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Sent Successfully");
        // this.route.navigate(['/allfolders']);
        window.location.reload();
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
        window.location.reload();
      }
    });
  }
  acceptrequest(f) {

    this.userservice.AcceptReq(f).subscribe((data: any) => {
      alert("Accepted Successfully");
      // this.route.navigate(['/allfolders']);
      this.GetDetails();
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }






}
