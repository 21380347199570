import { Component, OnInit } from '@angular/core';
import { HeightViewModel } from 'src/app/shared/ViewModels/HeightViewModel';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HeightService } from 'src/app/shared/height/height.service';

@Component({
  selector: 'app-height',
  templateUrl: './height.component.html',
  styleUrls: ['./height.component.css']
})
export class HeightComponent implements OnInit {

  HeightList: any;
  height: any;
  heightId: any; genid: any;
  
    constructor(private heightService:HeightService , private router: Router) { }
  
    ngOnInit() {
      this. Height();
      this.height = "";
    }
    ResetData(){
      this.height = "";
    }
  
  
    Height() {
      this.heightService.getHeight().subscribe((Data:  HeightViewModel[]) => {
        this.HeightList = Data;
      })
    }
  
    SaveHeightForm(form: NgForm) {
      const inputRequest: HeightViewModel = {
        ID: 0,
        Height: form.controls["height"].value,
      }
      this.heightService.saveHeight(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/height']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/height']);
          window.location.reload();
        }
      });
    }
  
    editheight(data: { height: any; id: any; }) {
      this.height = data.height;
      this.heightId = data.id;
    }
  
    UpdateHeight(form: NgForm) {
      const inputRequest1:HeightViewModel = {
        ID: this.heightId,
        Height: form.controls["height"].value,
      }
      this.heightService.UpdateHeight(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Height Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deleteheight(data: { id: any; }) {
      this.genid = data.id;
      this.heightService.deleteheight(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Height Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Height Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

