import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FamilyStatusViewModel } from '../ViewModels/FamilyStatusViewModel';

@Injectable({
  providedIn: 'root'
})
export class FamilystatusService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveFamilyStatus(data: FamilyStatusViewModel) {
    return this.http.post(this.rooturl1 + "/api/FamilyStatusType/PostSaveFamilyStatus", data);
  }
  getFamilyStatus() {
    return this.http.get(this.rooturl1 + "/api/FamilyStatusType/GetFamilyStatus");
  }
  deletefamilystatus(id: number) {
    return this.http.delete(this.rooturl1 + "/api/FamilyStatusType/DeleteFamilyStatusdata/" + id);
  }

  UpdateFamilyStatus(data: FamilyStatusViewModel) {
    return this.http.put(this.rooturl1 + "/api/FamilyStatusType/PutFamilyStatus", data);
  }
}
