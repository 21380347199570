import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { BM_MobileOTP } from 'src/app/shared/ViewModels/BM_MobileOTP';
import { MobileViewModel } from 'src/app/shared/ViewModels/MobileViewModel';
import { ChangePassword } from 'src/app/shared/ViewModels/ChangePassword';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  enableOTP: boolean;
  enableMobileVerify: boolean;
  ShowChangePassword:boolean;
  mobileNo: any;
  p1: any;
  p2: any;
  constructor(private userService:UserServiceService, private spinner: NgxSpinnerService,private router: Router) { }

  ngOnInit() {
    this.enableOTP = false;
    this.ShowChangePassword = false;
    this.enableMobileVerify = true;
  }
  VerifyNumber(form:NgForm)
  {
    this.spinner.show();

    this.mobileNo = form.controls["phone"].value;
    const inputRequest:BM_MobileOTP={
      Id:0,
      Mobile:form.controls["phone"].value,
      AliasDeviceId:"",
      Attempts:"",
      OTP:"",
      OTPExpiryTime:"",
      OTPStartingTime:""
    }
    this.userService.verifyPhone(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Please Follow");
          this.enableOTP = true;
          this.enableMobileVerify = false;
          this.ShowChangePassword = false;
          this.spinner.hide();

        }else{
          alert("Something Went Wrong. Try again.!!");
          this.spinner.hide();
         window.location.reload();
        } 
      });
  }
  VerifyOTP(form:NgForm)
  {
    this.spinner.show();
    const inputRequest:MobileViewModel={
   CustomerId:null,
   CustomerName:null,
   Email:null,
   MobileNumber:this.mobileNo,
   Otp:form.controls["otp"].value,
   Subject:null
    }
    this.userService.postVerifyOTP(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Your Otp is Succesfully Verified");
          this.enableOTP = false;
          this.enableMobileVerify = false;
          this.ShowChangePassword = true;
          this.spinner.hide();

        }else{
          alert("Something Went Wrong. Try again.!!");
          this.spinner.hide();
          window.location.reload();
        } 
      });
  }
  ChangePassword(form:NgForm)
  {
    this.spinner.show();

    this.p1=form.controls["passwordone"].value;
    this.p2=form.controls["passwordtwo"].value;
    if(this.p1 != this.p2){
        alert("Enter Password Correctly")
    }
    const inputRequest:ChangePassword={
      CustomerId:"",
      MobileNumber:this.mobileNo,
      Password:form.controls["passwordone"].value,
      ConfirmPassword:form.controls["passwordtwo"].value,
    }
    this.userService.postchangePassword(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Your Password Successfully Changed");
          this.enableOTP = false;
          this.enableMobileVerify = false;
          this.ShowChangePassword = true;
          this.router.navigate(['/login']);
          this.spinner.hide();

        }else{
          alert("Something Went Wrong. Try again.!!");
          this.spinner.hide();
          window.location.reload();
        } 
      });
  }

}
