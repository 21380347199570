import { Injectable } from '@angular/core';
import { FamilyTypeViewModel } from '../ViewModels/FamilyTypeViewModel';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FamilytypeService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveFamilyType(data: FamilyTypeViewModel) {
    return this.http.post(this.rooturl1 + "/api/FamilyType/PostSaveFamilyType", data);
  }
  getFamilyType() {
    return this.http.get(this.rooturl1 + "/api/FamilyType/GetFamilyType");
  }
  deletefamilytype(id: number) {
    return this.http.delete(this.rooturl1 + "/api/FamilyType/DeleteFamilyTypedata/" + id);
  }

  UpdateFamilyType(data: FamilyTypeViewModel) {
    return this.http.put(this.rooturl1 + "/api/FamilyType/PutFamilyType", data);
  }
}
