import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BridegroomService } from '../shared/Services/BridegroomService/bridegroom.service';
import { NgForm } from '@angular/forms';
import { RequestVM } from '../shared/ViewModels/RequestVM';

@Component({
  selector: 'app-your-matches',
  templateUrl: './your-matches.component.html',
  styleUrls: ['./your-matches.component.css']
})
export class YourMatchesComponent implements OnInit {
  FoundMathes: any;
  MatrimonyId: string;
  imageUrl: string;
  toid: any;
  toname: any;
  constructor(private router: Router,private spinner: NgxSpinnerService,private bridegroomService:BridegroomService) { 
    this.MatrimonyId= localStorage.getItem('uid');
  }
  ngOnInit() {
    this.GetMatchesFound();
  }
  
  GetMatchesFound() {
    this.spinner.show();
    this.bridegroomService.GetMacthesFound(this.MatrimonyId).subscribe((response: any) => {
      this.FoundMathes = response;
      this.spinner.hide();
    });
  }

  Moredetails(id, per){
    var data = id +"," +per;
    this.router.navigate(['/fulldetails',data]);
  }

  SendIntrestRequesttoid(toid, name) {
    this.toid = toid;
    this.toname = name
  }
  SendIntrestRequest(form: NgForm){
    this.spinner.show();
    const inputRequest: RequestVM = {
       RequestIdFrom: this.MatrimonyId,
      RequestIdTo: this.toid,
      RequestStatus: null,
      RequestAcceptReject: null,
      Message: form.controls["meassage"].value
    }
    this.bridegroomService.SendRequest(inputRequest).subscribe((data: any) => {
      if (data == "1") {
        alert("Sent Successfully");
        this.spinner.hide();
      }
      else {
        alert("Please Tryagain");
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
}
