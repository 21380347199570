import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GanaViewModel } from '../ViewModels/GanaViewModel';

@Injectable({
  providedIn: 'root'
})
export class GanaService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveGana(data: GanaViewModel) {
    return this.http.post(this.rooturl1 + "/api/GanaMaster/PostSaveGana", data);
  }
  getGana() {
    return this.http.get(this.rooturl1 + "/api/GanaMaster/GetGana");
  }
  deletegana(id: number) {
    return this.http.delete(this.rooturl1 + "/api/GanaMaster/DeleteGanadata/" + id);
  }

  UpdateGana(data: GanaViewModel) {
    return this.http.put(this.rooturl1 + "/api/GanaMaster/PutGana", data);
  }
}

 