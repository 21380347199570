import { Component, OnInit } from '@angular/core';
import { ManglikViewModel } from 'src/app/shared/ViewModels/ManglikViewModel';
import { NgForm } from '@angular/forms';
import { ManglikService } from 'src/app/shared/manglik/manglik.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manglik',
  templateUrl: './manglik.component.html',
  styleUrls: ['./manglik.component.css']
})
export class ManglikComponent implements OnInit {

  ManglikList: any;
  manglik: any;
  manglikId: any; genid: any;
  
    constructor(private manglikService:ManglikService , private router: Router) { }
  
    ngOnInit() {
      this.Manglik();
      this.manglik = "";
    }
    ResetData(){
      this.manglik = "";
    }
  
  
    Manglik() {
      this.manglikService.getManglik().subscribe((Data: ManglikViewModel[]) => {
        this.ManglikList = Data;
      })
    }
  
    SaveManglikForm(form: NgForm) {
      const inputRequest: ManglikViewModel = {
        ID: 0,
        ManglinkType: form.controls["manglik"].value,
      }
      this.manglikService.saveManglik(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/manglik']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/manglik']);
          window.location.reload();
        }
      });
    }
  
    editmanglik(data: { manglinkType: any; id: any; }) {
      this.manglik = data.manglinkType;
      this.manglikId = data.id;
    }
  
    UpdateManglik(form: NgForm) {
      const inputRequest1: ManglikViewModel = {
        ID: this.manglikId,
        ManglinkType: form.controls["manglik"].value,
      }
      this.manglikService.UpdateManglik(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Manglik Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletemanglik(data: { id: any; }) {
      this.genid = data.id;
      this.manglikService.deletemanglik(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Manglik Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Manglik Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  