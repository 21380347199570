import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { ActivatedRoute, Router, } from '@angular/router';
import { addresslist } from 'src/app/shared/ViewModels/addresslist';
import { PayU } from 'src/app/shared/ViewModels/Payment';

@Component({
  selector: 'app-payu',
  templateUrl: './payu.component.html',
  styleUrls: ['./payu.component.css']
})
export class PayuComponent implements OnInit {

  subscription: Subscription;
  public isLoggedIn:boolean=false;
  UserName:string;
  Detailss:any;
  CID:string;
  orderid:string;
  public CardDetails= [];
  continue:boolean;
  constructor(private userService: UserServiceService,private router:ActivatedRoute,private routerr : Router) {
    this.router.params.subscribe(params=>{
      if(params["id"]){
        this.orderid= params["id"];
      }
    });
   }
   paymentMessage :any;
   timeWithInterval=0;
   myInterval:number;
   Amountpaid:any;
   Details:any;


  ngOnInit() {
    this.GetOrderPayments(this.orderid);
    this.continue=false;
  }

  StartTimer(){
    const source = interval(20000);
    this.subscription = source.subscribe(val => {
      this.GetPaymentStatus();
    });
  }

  CancelTimer(){
    this.subscription.unsubscribe();
  }

  GetOrderPayments(OID){
    const aa:addresslist = {
      CustomerId:OID,
    }
    this.userService.getorderpayment(aa).subscribe((data:any)=>{
      this.CardDetails = data;
      console.log("dataa", this.CardDetails);
    });
    
  }
  

  GetPaymentStatus(){
    const aa:PayU = {
      OrderId:this.orderid,
    }
    this.userService.getpaymentstatus(aa).subscribe((data:any)=>{
      if(data!=null && data.transactionStatus != ''){
        this.Details = data;
        if(this.Details.transactionStatus == "success"){
          this.continue = true;
          this.CancelTimer();
            this.paymentMessage = "The payment transaction with Transaction ID: "+" " + this.Details.TransactionId +" " +" and the Amount of Rs." +" "+ this.Details.Amount + " "+" has been successfully received.";                             
        }
        else if(this.Details.transactionStatus == "failure"){
          this.continue = true;
          this.CancelTimer();
           this.paymentMessage = "The payment transaction with Transaction ID: " + " " +this.Details.TransactionId +" " +" and the Amount of Rs." +" "+this.Details.Amount +" "+" failed. Please try again!";
        }
        else if(this.Details.transactionStatus == null){
          this.GetPaymentStatus();
        }
      }      
    });
  }

  gotologin(){
      this.routerr.navigate(['/login']);    
  }

}
