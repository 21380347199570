import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/IdentityManagement/UserService/user.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FolderviewService } from 'src/app/shared/FlderViewService/folderview.service';
import { AddNewFolder } from 'src/app/shared/ViewModels/AddNewFolder';
import { IDViewModel } from 'src/app/shared/ViewModels/IDViewModel';
import { Search } from 'src/app/shared/ViewModels/Search';
import { UserInfo } from 'src/app/shared/ViewModels/UserInfo';

@Component({
  selector: 'app-dimall-folders',
  templateUrl: './dimall-folders.component.html',
  styleUrls: ['./dimall-folders.component.css']
})
export class DIMAllFoldersComponent implements OnInit {
  public isLoggedIn:boolean=false;
  uid:any;
  allFolders:any;
  allSubFolders:any;
  roleid:any;
  closeResult:any;
  msg:string; m:string;zzz:any;
  code:string;
  userid:any;
  updatemsg:any;u:any;
  details:any;subf:any;
  searchvalue: any;
  val: any;
  public SearchEnabled: boolean= false;
  SearchResult: any;
  pdfUrl:any;
  username: string;
  DataUSed: any;
  numfolderid:number;
  constructor(private userservice:UserService,private router : Router,private modalService: NgbModal,private folderviewservice:FolderviewService,) { 
    if(localStorage.getItem("IsLoggedIn")=="true")
    {
      this.isLoggedIn=true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserName");
    }
    this.userservice.getfolders(this.uid).subscribe((respose:any) =>{
      this.allFolders = respose;
     console.log("user",this.allFolders)
    });
  }

  ngOnInit() { 
    this.GetDataUsed();
  }
  
  
  addnewFolder(folderview,subfoldercontent) {
    this.modalService.open(subfoldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
     
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m=this.msg;
    });
  }
 
  savenewFolder() {
        
    const inputRequest:AddNewFolder={   
      Id: 0, 
      FolderID: "0",
      RoleId:this.roleid,
      FolderName:this.msg,
      UserId:this.uid,
      Code:"Folder",
      ParentID: "0",
      Click: null
    }
    this.userservice.addNewFolder(inputRequest).subscribe((data:any)=>{
      if(data!=null){
        alert("Added Successfully");
        this.router.navigate(['/allfolders']);
        window.location.reload();
      }
      else{
        alert("Something went Wrong");
        this.router.navigate(['/allfolders']);
      }
    });
  }
  
  UpdateFolder(folderview,editfolder) {
    this.details=folderview;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u=this.updatemsg;
    });
  }

  UpdateFolderName() {
    const inputRequest:AddNewFolder={   
      Id: this.details.Id, 
      FolderID: this.details.FolderID,
      RoleId:this.roleid,
      FolderName:this.updatemsg,
      UserId:this.uid,
      Code:"Folder",
      ParentID:this.details.ParentID,
      Click: null
    }
    this.userservice.UpdateFolder(inputRequest).subscribe((data:any)=>{
      if(data == true){
        alert("Added Successfully");
        this.router.navigate(['/allfolders']);
        window.location.reload();
      }
      else{
       alert("Something went Wrong");
        this.router.navigate(['/allfolders']);
        window.location.reload();
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  GetSubfolder(data){
    this.subf = data.FolderName;
    this.numfolderid = data.FolderID;
    const inputRequest :IDViewModel ={
      folderid:data.FolderID
    }
    this.userservice.getsubfolders(inputRequest).subscribe((respose:any) =>{
      this.allSubFolders = respose;
           this.subf = this.allSubFolders.FolderName;
           var Pid = this.allSubFolders.ParentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata',JSON.stringify(this.allSubFolders.RetData) );
      localStorage.setItem('subfolders',JSON.stringify(this.allSubFolders.SubFolders) );
      localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
     
      console.log("user",this.allSubFolders);
    this.router.navigate(['allsubfolders/'+data.FolderID]); 
  });
}

  openModal(id: string) {
    this.modalService.open(id);
  }

  // closeModal(id: string) {
  //     this.modalService.close(id);
  // }

  // addFolder(){
  //   this.router.navigate(['/addnewfolder/' + 1]);
  // }

  Delete(data){
    var res;
    if(data.FolderName==null){
      res = confirm("Do You Want to Delete " + data.DocumentName + "!!!");
    }
    else{
      res = confirm("Do You Want to Delete " + data.FolderName + "!!!");
    }
    if(res ==true){
      if (data.Hash) {
        var info = {
            Id: data.Id,
            FolderID: data.FolderId,
            FolderName: data.FolderName,
            UserId: this.uid,
            Code: "Document",
            ParentID: data.FolderId,
            RoleId: this.roleid,
            Click: null
        };
      }
      else {
        info = data;
    }
    }
    this.userservice.deletefolder(info).subscribe((data:any)=>{
      alert("Deleted Successfully");
      window.location.reload();
    });
  }


  onSearchChange(searchValue : string ){
    this.val = searchValue;
    if (this.val.length != 0) {
      this.SearchEnabled = true;
      }
      else  {
        this.SearchEnabled = false;
      }
      if (this.val.length > 1) {
        const inputRequest:Search={
          Value:this.val,
          UserId:this.uid
        } 
        
        this.userservice.SearchFiles(inputRequest).subscribe((data:any)=>{
          this.SearchResult= data;
          //alert("Deleted Successfully");
          //window.location.reload();
  
        }); }
  }

  ViewDoc(aa , content){
    this.zzz = aa;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m=this.msg;
    });
}

ViewDocument(bb,pdfcontent){
  this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
    this.pdfUrl = data;
    this.modalService.open(pdfcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m=this.msg;
    });
  });
}

ViewxlsDoc(bb,xlscontent){
  this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
    this.pdfUrl = data;
    this.modalService.open(xlscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m=this.msg;
    });
  });
}

ViewPpt(bb,pptcontent){
  this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
    this.pdfUrl = data;
    this.modalService.open(pptcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m=this.msg;
    });
  });
}


ViewVideo(cc,videocontent){
  this.folderviewservice.viewDocument(cc).subscribe((data:any)=>{
    this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m=this.msg;
    });
  });
}
GetDataUsed(){
  const inputRequest:UserInfo={
    UserId:this.uid,
    Accountid:null  
    };
  this.folderviewservice.GetDataUsed(inputRequest).subscribe((respose:any) =>{
    this.DataUSed = respose;
     });
}



}
