import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BridegroomService } from '../shared/Services/BridegroomService/bridegroom.service';
import { HeightService } from '../shared/height/height.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestListVM, RequestVM } from '../shared/ViewModels/RequestVM';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-request-msg',
  templateUrl: './request-msg.component.html',
  styleUrls: ['./request-msg.component.css']
})
export class RequestMsgComponent implements OnInit {
  LoginMatID: string;
  Reqmsg: any;
  lenofreqmsg: boolean;
  replyenable: boolean;
  toid: any;
  toname: any;
  constructor(private spinner: NgxSpinnerService,private modalService: NgbModal,private router: ActivatedRoute, private route: Router, private bridegroomService: BridegroomService,private heightService:HeightService) {
    this.LoginMatID = localStorage.getItem('uid');
  
   
//     this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
//  this.age = Date.now() - this.DetailsList.dateOfBirth;
  }
  ngOnInit() {
    this.GetReqestMsg();
  }
  GetReqestMsg(){
    this.spinner.show();
    this.Reqmsg = null;
    const inputRequest: RequestListVM = {
      RequestIdFrom: this.LoginMatID,
      RequestIdTo: null
    }
    this.bridegroomService.GetMsgList(inputRequest).subscribe((data: any) => {
      if (data != null) {   
        this.Reqmsg = data;   
        // if (this.Reqmsg.length == 1) {
        //   this.lenofreqmsg = true;
        // }
        // else if(this.Reqmsg.length != 0){
        //   this.lenofreqmsg = false;
        //   this.replyenable = true;
        // }        
        this.spinner.hide();
      }
      else {       
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
  SendIntrestRequesttoid(toid, name) {
    this.toid = toid;
    this.toname = name
  }
  GotoProfile(id){
    this.route.navigate(['/fulldetails',id]);
  }
  SendIntrestRequest(form: NgForm){
    this.spinner.show();
    const inputRequest: RequestVM = {
      RequestIdFrom: this.LoginMatID,
      RequestIdTo: this.toid,
      RequestStatus: null,
      RequestAcceptReject: null,
      Message: form.controls["meassage"].value
    }
    this.bridegroomService.SendRequest(inputRequest).subscribe((data: any) => {
      if (data == "1") {
        alert("Sent Successfully");
        //this.GetFullDetails();
        this.GetReqestMsg();
        this.spinner.hide();
      }
      else {
        alert("Please Tryagain");
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
}
