import { Component, OnInit } from '@angular/core';
import { ImageDetails } from 'src/app/shared/ViewModels/ImageDetails';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { DocDetails } from 'src/app/shared/ViewModels/DocDetails';
import { NgForm } from '@angular/forms';
import { BiometricDetails } from 'src/app/shared/ViewModels/BiometricDetails';
import { AddressDetails } from 'src/app/shared/ViewModels/AddressDetails';
import { PersonalDetails } from 'src/app/shared/ViewModels/PersonalDetails';
import { PreDetails } from 'src/app/shared/ViewModels/PreDetails';
import { UserService } from 'src/app/shared/IdentityManagement/UserService/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityservicesService } from 'src/app/shared/IdentityManagement/IdentityServices/identityservices.service';

@Component({
  selector: 'app-identity-registration-form',
  templateUrl: './identity-registration-form.component.html',
  styleUrls: ['./identity-registration-form.component.css']
})
export class IdentityRegistrationFormComponent implements OnInit {
  fileupload: File;
  imageUrl: any;
  Id: any;
  userid: string;
  lng: any;
  lat: any;
  imagggg: any;


  bioval: string;
  UID: any;
  bioval1: string;
  bid: any;

  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  public trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  public nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  selectimage: string;
  public forstudentonly: boolean = false;
  public forcompanyonly: boolean = false;
  public forGPonly: boolean = false;
  public forIndividualonly: boolean = false;
  public isemailtype: boolean = false;
  public ishardcopytype: boolean = false;
  formvalue: any;

  imgdata: any;
  imagedet: any;

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
  };
  Districts: any; Areas: any;
  districtid: number; areaid: number;
  personaldetails: boolean;
  addressdetails: boolean;
  predetails: boolean;
  docdetails: boolean;
  firstname: string;
  lastname: string;
  categoryid: any; requestid: any;
  ispassportavail: string;
  colorchange: boolean;
  falsedata: boolean;
  otherdetails: boolean;
  imagecapture: boolean;
  mobile: string; email: string; dist: number; area: number;
  address: string; pincode: number;
  username: string; password: string;
  ClientCategory: any;
  catid: any;
  RequestTypes: any;
  reqid: any;
  reltypeid: any;
  Relationships: any;
  MaritalStatus: any;
  maritalstatusid: any;
  genderid: any;
  Genders: any;
  qualificationid: any;
  Qualifications: any;
  States: any;
  stateid: any;
  countryid: any;
  Countries: any;
  requestdate: any;
  filedone: boolean;
  emaildate: any;
  emailaddress: any;
  emailtime: any;
  showupload: boolean;
  middlename: string;
  //mothername: string;
  nickname: string;
  ralatorname: string;
  dateofbirth: number;
  occupation: string;
  organisation: string;
  branches: number;
  locationandpostalofcompany: string;
  educationalinstitution: string;
  universityname: string;
  purposeofjourney: string;
  periodfrom: string;
  periodto: string;
  nameandaddressofemployer: string;
  contactoffNameanddesignation: string;
  pahouseNo: string;
  pastreetName: string;
  pacity: string;
  paarea: string;
  panno: string;
  aadharNo: string;
  passportno: string;
  passportAuthorityDetails: string;
  nextdateofpassportrenewal: string;
  purposeOftendigitFP: string;
  SessionId: any;
  numid: number;
  purposeoffingerprint: any;



  constructor(private userService: UserService, private identityService: IdentityservicesService, private router: Router, private routerr: ActivatedRoute) {

    this.userid = localStorage.getItem("userId");
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
      });
    }

    this.bioval = localStorage.getItem("BValueOne");
    this.bioval1 = localStorage.getItem("BValueTwo");
  }

  ngOnInit() {
    this.GetClientCategory();
    this.GetRequestTypes();
    this.GetRelationshipTypes();
    this.GetMaritalStatus();
    this.GetQualifications();
    this.GetStates();
    this.GetCountries();
    this.GetGenders();
    this.GetSessionDetails();

    this.selectimage = "";
    this.predetails = true;
    this.ispassportavail = "";
    this.colorchange = false;
    this.showupload = false;

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  //PreDetails #1
  OnSubmitPreDetails(form: NgForm) {

    if (form.controls["requestdate"].value != null && form.controls["requestdate"].value != "" &&
      this.reqid != null && this.reqid != "" && this.catid != null && this.catid != "") {

      this.personaldetails = true;
      this.predetails = false;
      this.addressdetails = false;
      this.docdetails = false;
      this.otherdetails = false;
      this.imagecapture = false;
    }

    this.requestdate = form.controls["requestdate"].value;
    if (this.filedone != true) {
      this.showupload = true;
      this.personaldetails = false;
      this.predetails = true;
      alert("Please Upload the Request Letter ");
    }
    if (this.catid == null || this.catid == "") {
      alert("Please Select The Category You Belong");
    }
    else if (this.reqid == null || this.reqid == "") {
      alert("Please Select the Request Date");
    }
    else if (form.controls["requestdate"].value == null || form.controls["requestdate"].value == "") {
      alert("Please Please Select Your Request Type");
    }
    else {
      const inputRequest: PreDetails = {
        Id: this.userid,
        ClientCategoryId: this.catid,
        RequestTypeId: this.reqid,
        RequestLetterdated: this.requestdate,
        EmailDate: this.emaildate,
        EmailAddress: this.emailaddress,
        EmailTime: this.emailtime
      }
      this.identityService.savePreDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Registered Successfully");
        }
        else {
          alert("Please Fill Details Properly");
        }
      });
    }
  }
  //Request Letter Upload
  OnSubmitUploadLetter(Image) {
    this.identityService.postrequestletter(this.userid, this.fileupload).subscribe(
      data => {
        Image.value = null;
        this.imageUrl = "/assets/img/img.png";
        alert("File Uploaded Succesfully");
        if (data == "File Uploaded Successfully") {
          this.filedone = true;
          this.personaldetails = true;
          this.predetails = false;
        }
        else {
          alert("Something went wrong")
        }

      });
  }
  //PersonalDetails #2
  OnSubmitPersonal(form: NgForm) {
    if (form.controls["fname"].value != null || form.controls["fname"].value != "" &&
      form.controls["miname"].value != null || form.controls["miname"].value != "" &&
      form.controls["lname"].value != null || form.controls["lname"].value != "" &&
      form.controls["moname"].value != null || form.controls["moname"].value != "" &&
      form.controls["nname"].value != null || form.controls["nname"].value != "" &&
      form.controls["rname"].value != null || form.controls["rname"].value != "" &&
      form.controls["dob"].value != null || form.controls["dob"].value != "" &&
      form.controls["occupation"].value != null || form.controls["occupation"].value != "" &&
      form.controls["organisation"].value != null || form.controls["organisation"].value != "" &&
      form.controls["branch"].value != null || form.controls["branch"].value != "" &&
      form.controls["locationandpostalofcompany"].value != null || form.controls["locationandpostalofcompany"].value != "" &&
      form.controls["contactoffNameanddesignation"].value != null || form.controls["contactoffNameanddesignation"].value != "" &&
      form.controls["nameandaddressofemployer"].value != null || form.controls["nameandaddressofemployer"].value != "" &&
      form.controls["purposeofjourney"].value != null || form.controls["purposeofjourney"].value != "" &&
      form.controls["periodfrom"].value != null || form.controls["periodfrom"].value != "" &&
      form.controls["periodto"].value != null || form.controls["periodto"].value != "" &&
      form.controls["educationalinstitution"].value != null || form.controls["educationalinstitution"].value == "" &&
      form.controls["universityname"].value != null || form.controls["universityname"].value != "" &&
      form.controls["mobile"].value != null || form.controls["mobile"].value != "" &&
      form.controls["email"].value != null || form.controls["email"].value != "") {

      this.personaldetails = false;
      this.predetails = false;
      this.addressdetails = true;
      this.docdetails = false;
      this.otherdetails = false;
      this.imagecapture = false;
    }

    this.firstname = form.controls["fname"].value;
    this.middlename = form.controls["miname"].value;
    this.lastname = form.controls["lname"].value;
    //this.mothername = form.controls["moname"].value;
    this.nickname = form.controls["nname"].value;
    this.ralatorname = form.controls["rname"].value;
    this.dateofbirth = form.controls["dob"].value;
    this.occupation = form.controls["occupation"].value;
    this.organisation = form.controls["organisation"].value;
    if (this.forcompanyonly == true) {
      this.branches = form.controls["branch"].value;
      this.locationandpostalofcompany = form.controls["locationandpostalofcompany"].value;
      this.contactoffNameanddesignation = form.controls["contactoffNameanddesignation"].value;
    }
    if (this.forIndividualonly == true) {
      this.nameandaddressofemployer = form.controls["nameandaddressofemployer"].value;
    }
    if (this.forGPonly == true) {
      this.purposeofjourney = form.controls["purposeofjourney"].value;
      this.periodfrom = form.controls["periodfrom"].value;
      this.periodto = form.controls["periodto"].value;
    }
    if (this.forstudentonly == true) {
      this.educationalinstitution = form.controls["educationalinstitution"].value;
      this.universityname = form.controls["universityname"].value;
    }
    this.mobile = form.controls["mobile"].value;
    this.email = form.controls["email"].value;
    if (form.controls["fname"].value == null || form.controls["fname"].value == "") {
      alert("Please Enter First Name");
    }
    else if (form.controls["miname"].value == null || form.controls["miname"].value == "") {
      alert("Please Enter Middle Name");
    }
    else if (form.controls["lname"].value == null || form.controls["lname"].value == "") {
      alert("Please Enter Last Name");
    }
    // else if (form.controls["moname"].value == null || form.controls["moname"].value == "") {
    //   alert("Please Enter Mother Name");
    // }
    else if (form.controls["nname"].value == null || form.controls["nname"].value == "") {
      alert("Please Enter Nick Name");
    }
    else if (form.controls["rname"].value == null || form.controls["rname"].value == "") {
      alert("Please Enter Relator's Name");
    }
    else if (form.controls["dob"].value == null || form.controls["dob"].value == "") {
      alert("Please Select Your DOB");
    }
    else if (form.controls["occupation"].value == null || form.controls["occupation"].value == "") {
      alert("Please Enter Occupation");
    }
    else if (form.controls["organisation"].value == null || form.controls["organisation"].value == "") {
      alert("Please Enter Organisation");
    }
    else if (form.controls["mobile"].value == null || form.controls["mobile"].value == "") {
      alert("Please Enter Your Mobile");
    }
    else if (form.controls["email"].value == null || form.controls["email"].value == "") {
      alert("Please Enter Your Email");
    }
    else if (this.reltypeid == null || this.reltypeid == "") {
      alert("Please Select Relation Type");
    }
    else if (this.maritalstatusid == null || this.maritalstatusid == "") {
      alert("Please Select Your Marital Status");
    }
    else {
      const inputRequest: PersonalDetails = {
        CustomerUserId: this.userid,
        FirstName: this.firstname,
        MiddleName: this.middlename,
        LastName: this.lastname,
        //MotherName: this.mothername,
        NickName: this.nickname,
        RelationshipId: this.reltypeid,
        RelatorName: this.ralatorname,
        MaritalStatusId: this.maritalstatusid,
        Dateofbirth: this.dateofbirth,
        Gender: this.genderid,
        QualificationId: this.qualificationid,
        Occupation: this.occupation,
        Organisation: this.organisation,
        Branches: this.branches,
        LocationAndPostalAddressOfCompany: this.locationandpostalofcompany,
        ContactOfficerNameAndDesignation: this.contactoffNameanddesignation,
        MobileNumber: this.mobile,
        CusotmerEmail: this.email,
        CurrentlyStudying: this.qualificationid,
        EducationalInstituteName: this.educationalinstitution,
        UniversityName: this.universityname,
        PurposeOfJourney: this.purposeofjourney,
        PeriodOfStayFrom: this.periodfrom,
        PeriodOfStayTo: this.periodto,
        NameAndAddressOfEmployer: this.nameandaddressofemployer,

      }
      this.identityService.savePersonalDetails(inputRequest).subscribe((data: any) => {
        console.log(data)
        if (data != null) {
          alert("Registered Personal Details Successfully");
          this.personaldetails = false;
          this.addressdetails = true;
        }
        else {
          alert("Please Fill Details Properly");
        }
      });
    }
  }
  //AddressDetails #3
  OnSubmitAddressDetails(form: NgForm) {

    if (form.controls["houseno"].value != null && form.controls["houseno"].value != "" &&
      form.controls["streetname"].value != null && form.controls["streetname"].value != ""
      && form.controls["area"].value != null && form.controls["area"].value != ""
      && form.controls["city"].value != null && form.controls["city"].value != ""
      && form.controls["pincode"].value != null && form.controls["pincode"].value != ""
      && this.stateid && this.countryid) {

      this.personaldetails = false;
      this.predetails = false;
      this.addressdetails = false;
      this.docdetails = false;
      this.otherdetails = true;
      this.imagecapture = false;
    }
    this.pahouseNo = form.controls["houseno"].value;
    this.pastreetName = form.controls["streetname"].value;
    this.paarea = form.controls["area"].value;
    this.pacity = form.controls["city"].value;
    this.pincode = form.controls["pincode"].value;

    if (form.controls["city"].value == null || form.controls["city"].value == "") {
      alert("Please Enter the city");
    }
    else if (this.pincode == null) {
      alert("Please Enter The Pincode");
    }
    else if (form.controls["area"].value == null || form.controls["area"].value == "") {
      alert("Please Enter City");
    }
    else if (form.controls["streetname"].value == null || form.controls["streetname"].value == "") {
      alert("Please Enter City");
    }
    else if (form.controls["houseno"].value == null || form.controls["houseno"].value == "") {
      alert("Please Enter House Number or Building Name");
    }
    else if (this.stateid == null || this.stateid == "") {
      alert("Please Select State");
    }
    else if (this.countryid == null || this.countryid == "") {
      alert("Please Select Country");
    }
    else {
      const inputRequest: AddressDetails = {
        CustomerUserId: this.userid,
        PAHouseNo: this.pahouseNo,
        PAStreetName: this.pastreetName,
        PAArea: this.paarea,
        PACity: this.pacity,
        StateId: this.stateid,
        CountryId: this.countryid,
        Pincode: this.pincode
      }
      this.identityService.saveAddressDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Registered Address Details Successfully");
        }
        else {
          alert("Please Fill Details Properly");
        }
      });
    }
  }
  //BiometriDetails #4 #Otherdetails
  // OnSubmitBiometric(form: NgForm) {

  //   if (form.controls["purposeoffingerprint"].value != null && form.controls["purposeoffingerprint"].value != "") {

  //     this.personaldetails = false;
  //     this.predetails = false;
  //     this.addressdetails = false;
  //     this.docdetails = true;
  //     this.otherdetails = false;
  //     this.imagecapture = false;
  //   }
  //   this.purposeoffingerprint = form.controls["purposeoffingerprint"].value;

  //   if (form.controls["purposeoffingerprint"].value == null || form.controls["purposeoffingerprint"].value == "") {
  //     alert("Please Mention the Purpose of Finger Print");
  //   }
  //   else {
  //     const inputRequest: BiometricDetails = {
  //       UserId: this.userid,
  //       PurposeOfTenFP: this.purposeoffingerprint
  //     }
  //     this.identityService.saveBiometricDetails(inputRequest).subscribe((data: any) => {
  //       if (data != null) {
  //         alert("Registered Biometric Details Details Successfully");
  //         this.docdetails = true;
  //         this.otherdetails = false;
  //       }
  //       else {
  //         alert("Please Fill Details Properly");
  //       }
  //     });
  //   }
  // }


  OnSubmitBiometric(form: NgForm) {
    this.bioval = localStorage.getItem("BValue");

  }
  fnVerify() {
    const inputRequest: BiometricDetails = {
      UserId: this.userid,
      FingerPrintOne: this.bioval,
      FingerPrintTwo: this.bioval1,
      PurposeOfTenFP:this.purposeOftendigitFP
    }
    this.identityService.saveBiometricDetails(inputRequest).subscribe((response: any) => {
      if(response != null){
        this.bid = response;
        alert("Verified Successfully!!");
        this.docdetails = true;
        this.otherdetails = false;
      }
      else{
        alert("Try Again! :( ");
      }
      
    });
  }

  //DocDetails #5
  OnSubmitDocs(form: NgForm) {

    this.aadharNo = form.controls["adharno"].value;
    this.panno = form.controls["panno"].value;
    if (this.ispassportavail == "1") {
      this.passportno = form.controls["passportno"].value;
      this.nextdateofpassportrenewal = form.controls["renewaldate"].value;
      this.passportAuthorityDetails = form.controls["passportIssuingAuthDetails"].value;
    }
    // else{
    //   this.passportno=null;
    //   this.nextdateofpassportrenewal=null;
    //   this.passportAuthorityDetails=null;

    // }
      const inputRequest: DocDetails = {
        UserId: this.userid,
        AadharNo: this.aadharNo,
        PANno: this.panno,
        PassportNo: this.passportno,
        PassportIssuingAuthorityDetails: this.passportAuthorityDetails,
        NextDateOfPassportRenewal: this.nextdateofpassportrenewal
      }
      this.identityService.saveDocDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Registered Document Details Details Successfully");
          this.docdetails = false;
          this.imagecapture = true;
        }
        else {
          alert("Please Fill Details Properly");
        }
      });
  }

  handleFIeInput(file: FileList) {
    this.fileupload = file.item(0);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileupload);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  onee() {
    this.personaldetails = false;
    this.addressdetails = false;
    this.predetails = true; 
    this.docdetails = false;
    this.otherdetails = false;
    this.imagecapture = false;
  }
  two() {
    this.personaldetails = true;
    this.addressdetails = false;
    this.predetails = false;
    this.docdetails = false;
    this.otherdetails = false;
    this.imagecapture = false;
  }
  three() {
    this.personaldetails = false;
    this.addressdetails = true;
    this.predetails = false;
    this.docdetails = false;
    this.otherdetails = false;
    this.imagecapture = false;
  }
  four() {
    this.personaldetails = false;
    this.addressdetails = false;
    this.predetails = false;
    this.docdetails = false;
    this.otherdetails = true;
    this.imagecapture = false;
  }
  five() {
    this.personaldetails = false;
    this.addressdetails = false;
    this.predetails = false;
    this.docdetails = true;
    this.otherdetails = false;
    this.imagecapture = false;
  }
  six() {
    this.personaldetails = false;
    this.addressdetails = false;
    this.predetails = false;
    this.docdetails = false;
    this.otherdetails = false;
    this.imagecapture = true;
  }
  OnSubmiit(Image) {
    this.userService.postImageFile(this.userid, this.fileupload, this.lng, this.lat).subscribe(
      data => {
        console.log('done');
        Image.value = null;
        this.imageUrl = "/assets/img/img.png";
        alert("File Uploaded Succesfully");
        if (data == "File Uploaded Successfully") {
          this.router.navigate(['home']);
        }
        else {
          alert("Something went wrong")
        }

      });
  }
  GetSessionDetails() {
    this.numid = parseInt(this.userid)
    this.identityService.getSessionDetails(this.numid).subscribe((response: any) => {
      this.SessionId = response;
    });
  }
  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.imagggg = this.webcamImage.imageAsDataUrl;
    this.imagedet = this.imagggg.split(',')
    const inputRequest: ImageDetails = {
      Id: this.userid,
      CustomerImage: this.imagedet[1],
      Longitude: this.lng,
      Latitude: this.lat
    }

    // this.userService.uploaduserimage(inputRequest).subscribe(
    //   data => this.imgdata)
    this.userService.uploaduserimage(inputRequest).subscribe((response: any) => {
     if( response!= null){
       alert("Captured Successfully!");
       window.location.reload();
     }
    });
  }
  onSelectcategoryid(id) {
    this.catid = id
    if (this.catid == 2) {
      this.forstudentonly = true
    } else {
      this.forstudentonly = false
    }

    if (this.catid == 3) {
      this.forcompanyonly = true
    } else {
      this.forcompanyonly = false
    }

    if (this.catid == 4) {
      this.forIndividualonly = true
    } else {
      this.forIndividualonly = false
    }

    if (this.catid == 1) {
      this.forGPonly = true
    } else {
      this.forGPonly = false
    }
  }
  GetClientCategory() {
    this.userService.GetClientCategory().subscribe((response: any) => {
      this.ClientCategory = response;
    });
  }

  onSelectRequestType(id) {
    this.reqid = id
    if (this.reqid == 1) {
      this.ishardcopytype = true
    } else {
      this.ishardcopytype = false
    }
    if (this.reqid == 2) {
      this.isemailtype = true
    } else {
      this.isemailtype = false
    }
  }
  GetRequestTypes() {
    this.userService.GetRequestType().subscribe((response: any) => {
      this.RequestTypes = response;
    });
  }

  onSelectRelationshipType(id) {
    this.reltypeid = id
  }
  GetRelationshipTypes() {
    this.userService.GetRelationType().subscribe((response: any) => {
      this.Relationships = response;
    });
  }

  onSelectMaritalStatus(id) {
    this.maritalstatusid = id
  }
  GetMaritalStatus() {
    this.userService.GetMaritalStatus().subscribe((response: any) => {
      this.MaritalStatus = response;
    });
  }

  onSelectGenders(id) {
    this.genderid = id
  }
  GetGenders() {
    this.userService.GetGenders().subscribe((response: any) => {
      this.Genders = response;
    });
  }

  onSelectQualifications(id) {
    this.qualificationid = id
  }
  GetQualifications() {
    this.userService.GetQualifications().subscribe((response: any) => {
      this.Qualifications = response;
    });
  }

  onSelectState(id) {
    this.stateid = id
  }
  GetStates() {
    this.userService.GetStates().subscribe((response: any) => {
      this.States = response;
    });
  }

  onSelectCountry(id) {
    this.countryid = id
  }
  GetCountries() {
    this.userService.GetCountries().subscribe((response: any) => {
      this.Countries = response;
    });
  }
}