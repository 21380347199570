import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { BridegroomService } from 'src/app/shared/Services/BridegroomService/bridegroom.service';
import { BrideGroomIdVM } from 'src/app/shared/ViewModels/BrideGroomIdVM';
import { BrideGroomMatchingFuzzyIdVM } from 'src/app/shared/ViewModels/BrideGroomMatchingFuzzyIdVM';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { RegistrationprocessService } from 'src/app/shared/Services/Registration/registrationprocess.service';
import { NgForm } from '@angular/forms';
import { PreferredBrideGroomDetails } from 'src/app/shared/ViewModels/PreferredBrideGroomDetails';

@Component({
  selector: 'app-preferred-partner-info',
  templateUrl: './preferred-partner-info.component.html',
  styleUrls: ['./preferred-partner-info.component.css']
})
export class PreferredPartnerInfoComponent implements OnInit {
   
  MatrimonyId: string;
  maritalStatusId: any;
  PerviousMaritalStatus: any;
  stateid: any;
  States: [];
  lookid: any;
  Looks: any;
  physicalstatusid: any;
  PhysicalStatuses: any;
  religionId: any;
  Religions: any;
  casteId: any;
  Castes: any;
  SubcasteId: any;
  subCastes: any;
  RaasiId: any;
  Raasis: any;
  StarId: any;
  Stars: any;
  FoodPreferenceId: any;
  FoodPreferences: any;
  GothramId: any;
  Gothrams: any;
  ManglikId: any;
  Mangliks: any;
  qualificationid: any;
  Qualifications: any;
  OccupationId: any;
  Occupations: any;
  FamilyStatusId: any;
  FamilyStatuses: any;
  FamilyTypeId: any;
  FamilyValueId: any;
  FamilyTypes: any;
  FamilyValues: any;
  DrinkingTypeId: any;
  DrinkingTypes: any;
  SmokingTypeId: any;
  SmokingTypes: any;
  PayuPlans: any;
  PaidOrNotCustomer: any;
  GetPreferredFuzzyOutput: any;
  priorityOne: any;
  priorityTwo: any;
  priorityThree: any;

  dropdownSettingsForLocation = {};
  dropdownSettingsForLooks: {};
  dropdownSettingsForRaasis: {};
  dropdownSettingsForStars: {};
  dropdownSettingsForFoodPreferences: {};
  dropdownSettingsForGothrams: {};
  dropdownSettingsForSubCates: {};
  dropdownSettingsForDrinkingType: {};
  dropdownSettingsForSmokingType: {};
  dropdownSettingsForFamilyType: {};
  dropdownSettingsForFamilyValue: {};
  dropdownSettingsForFamilyStatus: {};

  


  selectedItemsLocation: any = [];
  selectedItemsRaasi:any = [];
  selectedItemsLooks:any = [];
  selectedItemsStars:any = [];
  selectedItemsFoodPreference:any = [];
  selectedItemsGothrams:any = [];
  selectedItemsSubCates:any = [];
  selectedItemsDrinkingTypes:any = [];
  selectedItemsSmokingTypes:any = [];
  selectedItemsFamilyTypes:any = [];
  selectedItemsFamilyValues:any = [];
  selectedItemsFamilyStatuses:any = [];



  MultipleLooks: any =[];
  MultipleRaasi: any=[];
  Multiplestate: any=[];
  Multiplestar: any=[];
  MultipleFoodPreference: any=[];
  MultipleGothram: any=[];
  MultipleSubCate: any=[];
  MultipleDrinkingType: any=[];
  MultipleDSmokingTypes: any=[];
  MultipleFamilyTypes: any=[];
  MultipleFamilyValues: any=[];
  MultipleFamilyStatuses: any=[];

  
  
  allStates: any;
  allLooks: any;
  allRaasis: any;
  allStars: any;
  allFoodPreferences: any;
  allGothrams: any;
  allSubCates: any;
  allDrinkingTypes: any;
  allSmokingTypes: any;
  allFamilyTypes: any;
  allFamilyValues: any;
  allFamilyStatuses: any;
  SalaryId: any;
  PrefferedSalaries: any;
  MTmarked: boolean;
  Lmarked: boolean;
  PSmarked: boolean;
  Lookmarked: boolean;
  PRmarked: boolean;
  PCmarked: boolean;
  PSCmarked: boolean;
  Hmarked: boolean;
  Amarked: boolean;
  Rmarked: boolean;
  Starmarked: boolean;
  PSmokingmarked: boolean;
  PDmarked: boolean;
  PManglikmarked: boolean;
  PGmarked: boolean;
  FPmarked: boolean;
  PFTmarked: boolean;
  PFVmarked: boolean;
  PFStmarked: boolean;
  SRPmarked: boolean;
  EPmarked: boolean;
  FPrtmarked: boolean;
  HQmarked: boolean;
  Occmarked: boolean;
  Salarymarked: boolean;
  PMSmarked:boolean;
  

  constructor(private userService: UserServiceService, private registrationService:RegistrationprocessService, private router: Router, private spinner: NgxSpinnerService, private bridegroomService: BridegroomService) {
    this.MatrimonyId = localStorage.getItem('uid');
    this.spinner.show();
    //this.Salarymarked= false;
    
  }

  ngOnInit() {
    this.GetLooks();
    this.GetMaritalStatus();
    this.GetStates();
    this.GetPhysicalStatusTypes();
    this.GetManglikTypes();
    this.GetGothramTypes();
    this.GetFoodPreferencesTypes();
    this.GetStarsTypes();
    this.GetRaasiTypes();
    this.GetsubCasteTypes();
    this.GetCasteTypes();
    this.GetReligionTypes();
    this.GetFamilyStatusTypes();
    this.GetFamilyTypesTypes();
    this.GetFamilyValuesTypes();
    this.GetOccupationTypes();
    this.GetQualifications();
    this.GetDrinkingTypeTypes();
    this.GetSmokingTypeTypes();
    this.GetISEligibleToShowMatchesDetails();
    this.GetSalaryRanges();
    this.Salarymarked=false;
    this.EPmarked=false;
   
    this.MTmarked=false;
    this.Lmarked=false;
    this.PSmarked=false;
    this.Lookmarked=false;
    this.PRmarked=false;
    this.PCmarked= false;
    this.PSCmarked= false;
    this.Hmarked=false;
    this.Amarked= false;
    this.Rmarked= false;
    this.Starmarked= false;
    this.PSmokingmarked= false;
    this.PDmarked=false;
    this.PManglikmarked= false;
    this.PGmarked= false;
    this.FPmarked=false;
    this.PFTmarked= false;
    this.PFVmarked= false;
    this.PFStmarked= false;
    this.SRPmarked= false;
   this.FPrtmarked= false;
    this.HQmarked= false;
    this.Occmarked= false;
    this.PMSmarked=false;







    this.dropdownSettingsForLocation = {
      singleSelection: false,
      idField: 'id',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForLooks = {
      singleSelection: false,
      idField: 'id',
      textField: 'lookType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForRaasis = {
      singleSelection: false,
      idField: 'id',
      textField: 'raasiType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForStars = {
      singleSelection: false,
      idField: 'id',
      textField: 'starType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFoodPreferences = {
      singleSelection: false,
      idField: 'id',
      textField: 'foodPrefernceType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForGothrams = {
      singleSelection: false,
      idField: 'id',
      textField: 'gothramName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForSubCates = {
      singleSelection: false,
      idField: 'id',
      textField: 'subCasteType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForDrinkingType = {
      singleSelection: false,
      idField: 'id',
      textField: 'drinkingType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForSmokingType = {
      singleSelection: false,
      idField: 'id',
      textField: 'smokingType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFamilyType = {
      singleSelection: false,
      idField: 'id',
      textField: 'familyType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFamilyValue = {
      singleSelection: false,
      idField: 'id',
      textField: 'familyValueType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFamilyStatus = {
      singleSelection: false,
      idField: 'id',
      textField: 'familyStatusType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.spinner.hide();
  }
  onItemSelectFamilyStatus(item: any) {
    this.MultipleFamilyStatuses.push(item);
  }
  onSelectAllFamilyStatuses(items: any) {
    this.allFamilyStatuses = items;
  }
  onItemSelectFamilyValue(item: any) {
    this.MultipleFamilyValues.push(item);
  }
  onSelectAllFamilyValues(items: any) {
    this.allFamilyValues = items;
  }
  onItemSelectFamilyType(item: any) {
    this.MultipleFamilyTypes.push(item);
  }
  onSelectAllFamilyTypes(items: any) {
    this.allFamilyTypes = items;
  }
  onItemSelectSmokingTypes(item: any) {
    this.MultipleDSmokingTypes.push(item);
  }
  onSelectAllSmokingTypes(items: any) {
    this.allSmokingTypes = items;
  }
  onItemSelectDrinkingTypes(item: any) {
    this.MultipleDrinkingType.push(item);
  }
  onSelectAllDrinkingTypes(items: any) {
    this.allDrinkingTypes = items;
  }
  onItemSelectSubCates(item: any) {
    this.MultipleSubCate.push(item);
  }
  onSelectAllSubCates(items: any) {
    this.allSubCates = items;
  }
  onItemSelectGothrams(item: any) {
    this.MultipleGothram.push(item);
  }
  onSelectAllGothrams(items: any) {
    this.allGothrams = items;
  }
  onItemSelectFoodPreferences(item: any) {
    this.MultipleFoodPreference.push(item);
  }
  onSelectAllFoodPreferences(items: any) {
    this.allFoodPreferences = items;
  }
  onItemSelectStars(item: any) {
    this.Multiplestar.push(item);
  }
  onSelectAllStars(items: any) {
    this.allStars = items;
  }
  onItemSelectRaasis(item: any) {
    this.MultipleRaasi.push(item);
  }
  onSelectAllRaasis(items: any) {
    this.allRaasis = items;
  }
  onItemSelectLooks(item: any) {
    this.MultipleLooks.push(item);
  }
  onSelectAllLooks(items: any) {
    this.allLooks = items;
  }
  onItemSelectLocation(item: any) {
    this.Multiplestate.push(item);
  }
  onSelectAllLocations(items: any) {
    this.allStates = items;
  }
  GetISEligibleToShowMatchesDetails() {
    const inputRequest: BrideGroomIdVM = {
      BrideGroomId: this.MatrimonyId
    }
    this.bridegroomService.GetIsPaymentSuccess(inputRequest).subscribe((respose: any) => {
      this.PaidOrNotCustomer = respose;
    });
    const inputRequest1: BrideGroomMatchingFuzzyIdVM = {
      BrideGroomId: this.MatrimonyId,
      Status: this.PaidOrNotCustomer,
      Priorityone: this.priorityOne,
      Prioritytwo: this.priorityTwo,
      Prioritythree: this.priorityThree
    }
    this.bridegroomService.GetFuzzySDetails(inputRequest1).subscribe((respose: any) => {
      this.GetPreferredFuzzyOutput = respose;
    });
  }
  GetFriendDetails() {
    this.userService.getPayuPlans().subscribe((respose: any) => {
      this.PayuPlans = respose;
    });
  }
  onSelectSalary(id) {
    this.SalaryId = id
  }
  GetSalaryRanges() {
    this.bridegroomService.GetSalaryRanges().subscribe((response: any) => {
      this.PrefferedSalaries = response;
    });
  }
  onSelectFamilyStatus(id) {
    this.FamilyStatusId = id
  }
  GetFamilyStatusTypes() {
    this.bridegroomService.GetFamilyStatusesTypes().subscribe((response: any) => {
      this.FamilyStatuses = response;
    });
  }
  onSelectFamilyType(id) {
    this.FamilyTypeId = id
  }
  GetFamilyTypesTypes() {
    this.bridegroomService.GetFamilyTypeTypes().subscribe((response: any) => {
      this.FamilyTypes = response;
    });
  }
  onSelectFamilyValue(id) {
    this.FamilyValueId = id
  }
  GetFamilyValuesTypes() {
    this.bridegroomService.GetFamilyValueTypes().subscribe((response: any) => {
      this.FamilyValues = response;
    });
  }
  onSelectOccupation(id) {
    this.OccupationId = id
  }
  GetOccupationTypes() {
    this.bridegroomService.GetOccupationTypes().subscribe((response: any) => {
      this.Occupations = response;
    });
  }
  onSelectQualifications(id) {
    this.qualificationid = id
  }
  GetQualifications() {
    this.registrationService.GetQualifications().subscribe((response: any) => {
      this.Qualifications = response;
    });
  }
  onSelectManglik(id) {
    this.ManglikId = id
  }
  GetManglikTypes() {
    this.registrationService.GetManglikTypes().subscribe((response: any) => {
      this.Mangliks = response;
    });
  }
  onSelectDrinkingType(id) {
    this.DrinkingTypeId = id
  }
  GetDrinkingTypeTypes() {
    this.registrationService.GetDrinkingTypeTypes().subscribe((response: any) => {
      this.DrinkingTypes = response;
    });
  }
  onSelectSmokingeType(id) {
    this.SmokingTypeId = id
  }
  GetSmokingTypeTypes() {
    this.registrationService.GetSmokingTypeTypes().subscribe((response: any) => {
      this.SmokingTypes = response;
    });
  }
  onSelectGothram(id) {
    this.GothramId = id
  }
  GetGothramTypes() {
    this.registrationService.GetGothramTypes().subscribe((response: any) => {
      this.Gothrams = response;
    });
  }
  
  onSelectFoodPreferences(id) {
    this.FoodPreferenceId = id
  }
  GetFoodPreferencesTypes() {
    this.registrationService.GetFoodPreferenceTypes().subscribe((response: any) => {
      this.FoodPreferences = response;
    });
  }
  onSelecStar(id) {
    this.StarId = id
  }
  GetStarsTypes() {
    this.bridegroomService.GetStarTypes().subscribe((response: any) => {
      this.Stars = response;
    });
  }
  onSelectRaasi(id) {
    this.RaasiId = id
  }
  GetRaasiTypes() {
    this.registrationService.GetRaasiTypes().subscribe((response: any) => {
      this.Raasis = response;
    });
  }
  onSelectSubCaste(id) {
    this.SubcasteId = id
  }
  GetsubCasteTypes() {
    this.bridegroomService.GetsubCasteTypes().subscribe((response: any) => {
      this.subCastes = response;
    });
  }
  onSelectCaste(id) {
    this.casteId = id
  }
  GetCasteTypes() {
    this.bridegroomService.GetCasteTypes().subscribe((response: any) => {
      this.Castes = response;
    });
  }
  onSelectReligion(id) {
    this.religionId = id
  }
  GetReligionTypes() {
    this.registrationService.GetreligionTypes().subscribe((response: any) => {
      this.Religions = response;
    });
  }
  onSelectPhysicalStatus(id) {
    this.physicalstatusid = id
  }
  GetPhysicalStatusTypes() {
    this.registrationService.GetphysicalStatusTypes().subscribe((response: any) => {
      this.PhysicalStatuses = response;
    });
  }
  onSelectPreviousMaritalStatus(id) {
    this.maritalStatusId = id
  }
  GetMaritalStatus() {
    this.registrationService.GetPreviousMaritalStatus().subscribe((response: any) => {
      this.PerviousMaritalStatus = response;
    });
  }
  onSelectState(id) {
    this.stateid = id
  }
  GetStates() {
    this.registrationService.GetStates().subscribe((response: any) => {
      this.States = response;
    });
  }
  onSelectLook(id) {
    this.lookid = id
  }
  GetLooks() {
    this.registrationService.GetLooks().subscribe((response: any) => {
      this.Looks = response;
    });
  }
  HtoggleVisibility(e){
    this.Hmarked= e.target.checked;
  }
  AtoggleVisibility(e){
    this.Amarked= e.target.checked;
  }
  PMStoggleVisibility(e){
    this.PMSmarked= e.target.checked;
  }
  MTtoggleVisibility(e){
    this.MTmarked= e.target.checked;
  }
  LtoggleVisibility(e){
    this.Lmarked= e.target.checked;
  }
  PStoggleVisibility(e){
    this.PSmarked= e.target.checked;
  }
  LooKtoggleVisibility(e){
    this.Lookmarked= e.target.checked;
  }
  PRtoggleVisibility(e){
    this.PRmarked= e.target.checked;
  }
  PCtoggleVisibility(e){
    this.PCmarked= e.target.checked;
  }
  PSCtoggleVisibility(e){
    this.PSCmarked= e.target.checked;
  }
  RtoggleVisibility(e){
    this.Rmarked= e.target.checked;
  }
  StartoggleVisibility(e){
    this.Starmarked= e.target.checked;
  }
  FPtoggleVisibility(e){
    this.FPmarked= e.target.checked;
  }
  PGtoggleVisibility(e){
    this.PGmarked= e.target.checked;
  }
  PMangliktoggleVisibility(e){
    this.PManglikmarked= e.target.checked;
  }
  PDtoggleVisibility(e){
    this.PDmarked= e.target.checked;
  }
  PSmokingtoggleVisibility(e){
    this.PSmokingmarked= e.target.checked;
  }
  PFTtoggleVisibility(e){
    this.PFTmarked= e.target.checked;
  }
  PFVtoggleVisibility(e){
    this.PFVmarked= e.target.checked;
  }
  PFSttoggleVisibility(e){
    this.PFStmarked= e.target.checked;
  }
  SRPtoggleVisibility(e){
    this.SRPmarked= e.target.checked;
  }
  EPtoggleVisibility(e){
    this.EPmarked= e.target.checked;
  }
  FPrtoggleVisibility(e){
    this.FPrtmarked= e.target.checked;
  }
  HQtoggleVisibility(e){
    this.HQmarked= e.target.checked;
  }
  OcctoggleVisibility(e){
    this.Occmarked= e.target.checked;
  }
  SalarytoggleVisibility(e){
    this.Salarymarked= e.target.checked;
  }






  OnSubmitPreferredBrideGroomRegistration(form: NgForm) {
    if (form.controls["heightone"].value == null && form.controls["heightone"].value == "") {
      alert("Please Enter the Height Range")
    }
    else if (form.controls["heighttwo"].value == null && form.controls["heighttwo"].value == "") {
      alert("Please Enter the Height Range")
    }
    else if (form.controls["preferredageone"].value == null && form.controls["preferredageone"].value == "") {
      alert("Please Enter the Age From")
    }
    else if (form.controls["preferredagetwo"].value == null && form.controls["preferredagetwo"].value == "") {
      alert("Please Enter the Age To")
    }
    else if (form.controls["mothertoungue"].value == null && form.controls["mothertoungue"].value == "") {
      alert("Please Enter Preferred Mother Toungue")
    }
    else if (this.maritalStatusId == null && this.maritalStatusId == undefined) {
      alert("Please Provide Us Preferred Previous Marital Status")
    }
    else if (this.physicalstatusid == null && this.physicalstatusid == undefined) {
      alert("Please Enter Preferred Physical Status Type")
    }
    else if (this.religionId == null && this.religionId == undefined) {
      alert("Please Select Preferred Religion")
    }
    else if (this.Multiplestar == null && this.Multiplestar == undefined) {
      alert("Please Select Preferred Star")
    }
    else if (this.casteId == null && this.casteId == undefined) {
      alert("Please Select Preferred Caste")
    }
    else if (this.SalaryId == null && this.SalaryId == undefined) {
      alert("Please Select Preferred Salary Range")
    }
    else if (this.MultipleSubCate == null && this.MultipleSubCate == undefined) {
      alert("Please Select Preferred Sub-Caste")
    }
    else if (this.MultipleLooks == null && this.MultipleLooks == undefined) {
      alert("Please Select Preferred Looks")
    }
    else if (this.MultipleGothram == null && this.MultipleGothram == undefined) {
      alert("Please Select Preferred Gothram")
    }
    else if (this.ManglikId == null && this.ManglikId == undefined) {
      alert("Please Select Preferred Manglik")
    }
    else if (this.MultipleDrinkingType == null && this.MultipleDrinkingType == undefined) {
      alert("Please Select Preferred Drinking Type")
    }
    else if (this.MultipleFoodPreference == null && this.MultipleFoodPreference == undefined) {
      alert("Please Select Preferred Smoking Type")
    }
    else if (this.MultipleDSmokingTypes == null && this.MultipleDSmokingTypes == undefined) {
      alert("Please Select Preferred Smoking Type")
    }
    else if (this.MultipleRaasi == null && this.MultipleRaasi == undefined) {
      alert("Please Select Preferred Raasi")
    }
    else if (this.qualificationid == null && this.qualificationid == undefined) {
      alert("Please Select Preferred Qualification")
    }
    else if (this.MultipleFamilyTypes == null && this.MultipleFamilyTypes == undefined) {
      alert("Please Select Preferred Family Type")
    }
    else if (this.MultipleFamilyValues == null && this.MultipleFamilyValues == undefined) {
      alert("Please Select Preferred  Family Value")
    }
    else if (this.MultipleFamilyStatuses == null && this.MultipleFamilyStatuses == undefined) {
      alert("Please Select Preferred Family Status")
    }
    else if (this.OccupationId == null && this.OccupationId == undefined) {
      alert("Please Select Preferred Occupation")
    }
    else {
      this.spinner.show();
      const inputRequest: PreferredBrideGroomDetails = {
        BrideGroomId: this.MatrimonyId,
        PreferredHeightRangeOne: form.controls["heightone"].value,
        PreferredHeightRangeTwo: form.controls["heighttwo"].value,
        PreferredAgeFrom: form.controls["preferredageone"].value,
        PreferredAgeTo: form.controls["preferredagetwo"].value,
        PhysicalStatus: this.physicalstatusid,
        MotherToungue: form.controls["mothertoungue"].value,
        PreferredPreviousMaritalStatus: this.maritalStatusId,
        PrefferedLocation: this.Multiplestate,
        PreferredLooks: this.MultipleLooks,
        PreferredReligion: this.religionId,
        PreferredCaste: this.casteId,
        PreferredSubCaste: this.MultipleSubCate,
        PreferredFoodPreference: this.MultipleFoodPreference,
        Star: this.Multiplestar,
        Raasi: this.MultipleRaasi,
        Gothram: this.MultipleGothram,
        Manglik: this.ManglikId,
        PreferredSmoking: this.MultipleDSmokingTypes,
        PreferredDrinking: this.MultipleDrinkingType,
        PreferredEducationQualification: this.qualificationid,
        PreferredJob: this.OccupationId,
        PreferredFamilyType: this.MultipleFamilyTypes,
        PreferredFamilyValues: this.MultipleFamilyValues,
        PreferredFamilyStatus: this.MultipleFamilyStatuses,
        PreferredSalary :this.SalaryId,
        SocioReligious: form.controls["priorityone"].value,
        Education: form.controls["prioritytwo"].value,
        Family: form.controls["prioritythree"].value,
        MusthaveHeightRange:this.Hmarked,
        MusthaveAge:this.Amarked,
        MusthavePhysicalStatus:this.PSmarked,
        MusthaveMotherTounge:this.MTmarked,
        MusthavePreviousMaritalStatus:this.PMSmarked,
        MusthaveLocation:this.Lmarked,
        MusthaveLook:this.Lookmarked,
        MusthaveReligion:this.PRmarked,
        MusthaveCaste:this.PCmarked,
        MusthaveSubcaste:this.PSCmarked,
        MusthaveFoodPreference:this.FPmarked,
        MusthaveStar:this.Starmarked,
        MusthaveRaasi:this.Rmarked,
        MusthaveGothram:this.PGmarked,
        MusthaveManglik:this.PManglikmarked,
        MusthaveSmoking:this.PSmokingmarked,
        MusthaveDrinking:this.PDmarked,
        MusthaveEducation:this.HQmarked,
        MusthaveJob:this.Occmarked,
        MusthaveSalary:this.Salarymarked,
        MusthaveFamilyType:this.PFTmarked,
        MusthaveFamilyValues:this.PFVmarked,
        MusthaveFamilyStatus:this.PFStmarked,
        MusthaveSocioReligious:this.SRPmarked,
        MusthaveEducationPriority:this.EPmarked,
        MusthaveFamily:this.FPrtmarked,

      }
      this.bridegroomService.savePreferredBrideGroomDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Registered Your Preferred Details Successfully");
          this.router.navigate(['/']);
          this.GetFriendDetails();
          this.router.navigate(['/payuplans', data]);
          this.spinner.hide();
        }
        else {
          alert("Please Fill Details Properly");
          this.spinner.hide();
        }
      });
    }
  }

}
