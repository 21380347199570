import { Component, OnInit } from '@angular/core';
import { LoginService } from '../shared/Services/Login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../shared/MessageService';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
var authorizationData;
@Component({
  selector: 'app-loginto-see-profile',
  templateUrl: './loginto-see-profile.component.html',
  styleUrls: ['./loginto-see-profile.component.css']
})
export class LogintoSeeProfileComponent implements OnInit {
  BrideGroomId: any;
  userid: any;
  isLoginError: boolean;
  loading: boolean;
  fulldetailsId: any;
  MatrimonyId: string;
  FromId: any;
  Toid: any;
  isLoggedIn: boolean;

  constructor(private route: ActivatedRoute,private loginService: LoginService, private router: Router, private messageService: MessageService, private spinner: NgxSpinnerService) {
    var adata = localStorage.getItem('authdata');
    authorizationData = JSON.parse(adata);
    this.route.params.subscribe(params => {
      if (params["id"]) {
     var tid  = params["id"];
     var data =tid.split(',');
    this.FromId = data[1];
    this.Toid = data[0];
      }
    });
  }

  ngOnInit() {

    if(localStorage.getItem("IsLoggedIn") == "true"){
      this.MatrimonyId= localStorage.getItem('uid');
    this.Moredetails();
    }

  }

  Moredetails() {
    if (this.MatrimonyId == this.Toid) {
      this.router.navigate(['/fulldetails', this.fulldetailsId]);
    }
    else{
      this.isLoggedIn = false;
        localStorage.setItem("IsLoggedIn", "false");
        localStorage.removeItem('userToken');
        window.location.reload();
        //this.router.navigate(['/']);
    }
  }
  OnSubmit(userName, password) {
    // this.spinner.show();
    localStorage.setItem('loadingpage', "true");
    // this.loading = true;
    if (userName == null || userName == "") {
      alert("Please enter Your UserName");
    }
    else if (password == null || password == "") {
      alert("Please enter Your Password");
    }
    else {
      this.spinner.show();
      this.loginService.userAuthentication(userName, password).subscribe((data: any) => {

        this.spinner.hide();
        var movies = [data.access_token, userName, data.roleName, data.refresh_token, true, data.userUid,
        data.fullName, data.username, data.nameofRole, data.departmentname, data.userId, data.uid, data.area, data.IsMember];
       // this.Registrationstage();
        localStorage.setItem('IsLoggedIn', "true");
        localStorage.setItem('userToken', data.access_token);
        localStorage.setItem('authorizationData', data);
        localStorage.setItem('Token_type', data.token_type);
        localStorage.setItem("UserName", data.userName);

        localStorage.setItem("Password", password);
        localStorage.setItem("role", data.roleName);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("uid", data.uid);
        localStorage.setItem("area", data.area);
        localStorage.setItem("isMember", data.IsMember);
        localStorage.setItem('authdata', JSON.stringify(movies));
        // send message to subscribers via observable subject
        this.messageService.sendMessage('true');
        this.messageService.sendName(data.userName);

        this.BrideGroomId = data.uid;
        this.userid = data.userId;  
        this.router.navigate(['/fulldetails',this.FromId]);
      },
        (err: HttpErrorResponse) => {
          this.isLoginError = true;
          this.spinner.hide();
          this.loading = false;

        }

      )
    }
  };
}
