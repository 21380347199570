import { Component, OnInit } from '@angular/core';
import { NoOfSiblingsViewModel } from 'src/app/shared/ViewModels/NoOfSiblingsViewModel';
import { NoofsiblingsService } from 'src/app/shared/noofsiblings/noofsiblings.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-noofsiblings',
  templateUrl: './noofsiblings.component.html',
  styleUrls: ['./noofsiblings.component.css']
})
export class NoofsiblingsComponent implements OnInit {
  NoOfSiblingsList: any;
  noofsiblings: any;
  noofsiblingsId: any; genid: any;
  
    constructor(private noofsiblingsService:NoofsiblingsService , private router: Router) { }
  
    ngOnInit() {
      this. NoOfSiblings();
      this.noofsiblings = "";
    }
    ResetData(){
      this.noofsiblings = "";
    }
  
  
    NoOfSiblings() {
      this.noofsiblingsService.getNoOfSiblings().subscribe((Data:  NoOfSiblingsViewModel[]) => {
        this.NoOfSiblingsList = Data;
      })
    }
  
    SaveNoOfSiblingForm(form: NgForm) {
      const inputRequest: NoOfSiblingsViewModel = {
        ID: 0,
        NoOfSiblings: form.controls["noofsiblings"].value,
      }
      this.noofsiblingsService.saveNoOfSiblings(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/noofsiblings']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/noofsiblings']);
          window.location.reload();
        }
      });
    }
  
    editnoofsiblings(data: { noOfSiblings: any; id: any; }) {
      this.noofsiblings = data.noOfSiblings;
      this.noofsiblingsId = data.id;
    }
  
    UpdateNoOfSiblings(form: NgForm) {
      const inputRequest1: NoOfSiblingsViewModel = {
        ID: this.noofsiblingsId,
        NoOfSiblings: form.controls["noofsiblings"].value,
      }
      this.noofsiblingsService.UpdateNoOfSiblings(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("NoOfSiblings Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletenoofsiblings(data: { id: any; }) {
      this.genid = data.id;
      this.noofsiblingsService.deletenoofsiblings(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("NoOfSiblings Deleted Succesfully");
            window.location.reload();
        } else {
            alert("NoOfSiblings Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  
