import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HoroscopeViewModel } from '../ViewModels/HoroscopeViewModel';

@Injectable({
  providedIn: 'root'
})
export class HoroscopeService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  getHoroscope(data: HoroscopeViewModel) {
    return this.http.post(this.rooturl1 + "/api/BrideGroomDetails/Main", data);
  }
}
