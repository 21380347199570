import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menumasterlist',
  templateUrl: './menumasterlist.component.html',
  styleUrls: ['./menumasterlist.component.scss']
})
export class MenumasterlistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
