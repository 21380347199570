import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SalaryViewModel } from '../ViewModels/SalaryViewModel';

@Injectable({
  providedIn: 'root'
})
export class SalaryService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveSalary(data: SalaryViewModel) {
    return this.http.post(this.rooturl1 + "/api/SalaryRangeMaster/PostSaveSalary", data);
  }
  getSalary() {
    return this.http.get(this.rooturl1 + "/api/SalaryRangeMaster/GetSalary");
  }
  deletesalary(id: number) {
    return this.http.delete(this.rooturl1 + "/api/SalaryRangeMaster/DeleteSalarydata/" + id);
  }

  UpdateSalary(data: SalaryViewModel) {
    return this.http.put(this.rooturl1 + "/api/SalaryRangeMaster/PutSalary", data);
  }
}