import { Component, OnInit } from '@angular/core';
import { User } from '../user.model';
import { UserRole } from '../userrole.model';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { UserroleService } from 'src/app/shared/UserRoleService/userrole.service';


@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AdduserComponent implements OnInit {

  user:User;
  public roles:UserRole[];
  roleid:number
  role:string
  departmentid:number;
  areaid:number;
  isHidden: boolean
  public depts=[];
  public divisions=[];
  public locations=[];
  public offices=[];
  public banks=[];
  public branches=[];

  dists:any;
  areas:any;
  diviid: number;
  sectionid: number;
  locationid: number;
  bankid: string;
  headofficeid: string;
  branchid: string;
  constructor(private userservice:UserServiceService, private roleService:UserroleService, 
    private router : Router) { 

      this.roleService.getUserRole().subscribe((response:UserRole[]) =>{
        this.roles = response
       });
      this.roleService.getDepartments().subscribe((response:any) =>{
        this.depts = response
       
       });
       this.roleService.getDivisions().subscribe((response:any) =>{
        this.divisions = response
       });
       this.roleService.getLocation().subscribe((response:any) =>{  
        this.locations = response
       });
       this.roleService.getHeadOffices().subscribe((response:any) =>{
        this.offices = response
       });
       this.roleService.getBankNames().subscribe((response:any) =>{
        this.banks = response
       });
       this.roleService.getBranches().subscribe((response:any) =>{
        this.branches = response
       });
    }

    onSelect(Id) {  
      this.roleid = Id;
    }

    onSelectDepa(id){
      this.departmentid = id;
     
  }
  onSelectDivi(id){
    this.diviid = id;
  
}
onSelectSect(Id) {  
  this.sectionid = Id;
}

onSelectLoca(id){
  this.locationid = id;
 
}
onSelectBank(id){
this.bankid = id;

}
onSelectHeadOffice(id){
  this.headofficeid = id;
 
}
onSelectBranch(id){
this.branchid = id;

}

  ngOnInit() {
  }

  SaveUser(form:NgForm){
    const inputRequest:User={
      CustomerId:null,
      FirstName:form.controls["firstName"].value,
      MiddleName:form.controls["middleName"].value,
      LastName:form.controls["lastName"].value,
      UserName:form.controls["userName"].value,
      Password:form.controls["password"].value,
      ConfirmPassword:form.controls["cpassword"].value,
      Email:form.controls["email"].value,
      PhoneNumber:form.controls["phone"].value,
      UserRole:this.roleid,
      Department:this.departmentid,
      Division:this.diviid,
           Location:this.locationid,
      BranchName:this.branchid,
      BankName:this.bankid,
      HeadOffice:this.headofficeid,
      Question:form.controls["question"].value,
      Answer:form.controls["answer"].value,
      IsActive:null,
      IsPhoneNumberConfirmed:null,
      LastUpdated:null,
      UserID:null,
    }

    
 }

}
