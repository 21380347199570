import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LookViewModel } from 'src/app/shared/ViewModels/LookViewModel';
import { LookService } from 'src/app/shared/look/look.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-look',
  templateUrl: './look.component.html',
  styleUrls: ['./look.component.css']
})
export class LookComponent implements OnInit {

  
  LookList: any;
  look: any;
  lookId: any; genid: any;
  
    constructor(private lookService:LookService , private router: Router) { }
  
    ngOnInit() {
      this. Look();
      this.look = "";
    }
    ResetData(){
      this.look = "";
    }
  
  
    Look() {
      this.lookService.getLook().subscribe((Data:  LookViewModel[]) => {
        this.LookList = Data;
      })
    }
  
    SaveLookForm(form: NgForm) {
      const inputRequest: LookViewModel = {
        ID: 0,
        LookType: form.controls["look"].value,
      }
      this.lookService.saveLook(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/look']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/look']);
          window.location.reload();
        }
      });
    }
  
    editlook(data: { lookType: any; id: any; }) {
      this.look = data.lookType;
      this.lookId = data.id;
    }
  
    UpdateLook(form: NgForm) {
      const inputRequest1:LookViewModel = {
        ID: this.lookId,
        LookType: form.controls["look"].value,
      }
      this.lookService.UpdateLook(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Look Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletelook(data: { id: any; }) {
      this.genid = data.id;
      this.lookService.deletelook(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Look Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Look Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

