import { Component, OnInit } from '@angular/core';
import { QualificationViewModel } from 'src/app/shared/ViewModels/QualificationViewModel';
import { NgForm } from '@angular/forms';
import { QualificationService } from 'src/app/shared/qualification/qualification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit {
  QualificationList: any;
  qualification: any;
  qualificationId: any; genid: any;
  
    constructor(private qualificationService:QualificationService , private router: Router) { }
  
    ngOnInit() {
      this. Qualification();
      this.qualification = "";
    }
    ResetData(){
      this.qualification = "";
    }
  
  
    Qualification() {
      this.qualificationService.getQualification().subscribe((Data:  QualificationViewModel[]) => {
        this.QualificationList = Data;
      })
    }
  
    SaveQualificationForm(form: NgForm) {
      const inputRequest: QualificationViewModel = {
        ID: 0,
        QualificationName: form.controls["qualification"].value,
      }
      this.qualificationService.saveQualification(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/qualification']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/qualification']);
          window.location.reload();
        }
      });
    }
  
    editqualification(data: { qualificationName: any; id: any; }) {
      this.qualification = data.qualificationName;
      this.qualificationId = data.id;
    }
  
    UpdateQualification(form: NgForm) {
      const inputRequest1: QualificationViewModel = {
        ID: this.qualificationId,
        QualificationName: form.controls["qualification"].value,
      }
      this.qualificationService.UpdateQualification(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Qualification Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletequalification(data: { id: any; }) {
      this.genid = data.id;
      this.qualificationService.deletequalification(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Qualification Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Qualification Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  
