import { Injectable } from '@angular/core';
import { ImportFromMailVW } from '../../ViewModels/ImportFromMailVW';
import { ShareFileViaMailVM } from '../../ViewModels/ShareFileViaMailVM';
import { UserId } from '../../ViewModels/UserIdViewModel';
import { ImageDetails } from '../../ViewModels/ImageDetails';
import { Plan } from '../../ViewModels/plan';
import { PayU } from '../../ViewModels/Payment';
import { addresslist } from '../../ViewModels/addresslist';
import { SavepaymentDetails } from '../../ViewModels/SavepaymentDetails';
import { Search } from '../../ViewModels/Search';
import { Share } from '../../ViewModels/Share';
import { Friend } from '../../ViewModels/Friend';
import { Signup } from '../../ViewModels/Signup';
import { AddNewFolder } from '../../ViewModels/AddNewFolder';
import { IDViewModel } from '../../ViewModels/IDViewModel';
import { User } from 'src/app/USRM/User/user.model';
import { EmailMessageVM } from '../../ViewModels/IdentityManagementVM/EmailMessageVM';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDMUserId } from '../../ViewModels/IdentityManagementVM/IDMUserId';

@Injectable({
  providedIn: 'root'
})
export class UserService {
//readonly rooturl1 = "http://localhost:19200";
 readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyidusmapi";
 readonly url = "http://localhost:8538";
 //readonly url = "http://analyticals.co/digitalidentitydmsapi";

 readonly rooturl = "https://blockchainmatrimony.com/matrimonyusmapi";
 //readonly planurl ="http://localhost:8538"
 readonly planurl ="http://analyticals.co/digitalidentitydmsapi"


 constructor(private http: HttpClient) {

  }
  userAuthentication(userName, password) {
   var data = "username=" + userName + "&password=" + password + "&grant_type=password";
   var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded;','No-Auth':'True' });
   return this.http.post(this.rooturl1 + '/token', data, { headers: reqHeader });
 }
 selectMenubyUserRoleDetails(usrole) {
   return this.http.get(this.rooturl1 + '/api/MenuMaster/GetMenubyUserRole/'+usrole);
 }
 selectMenubyMenuId(usrole) {
   return this.http.get(this.rooturl1 + '/api/MenuMaster/GetMenubyUserRole/'+usrole);
 }

 saveUser(user:User){
   return this.http.post(this.rooturl1 +"/api/AuManagement/PostA_U_Management",user);
 }
 getUser(){
   return this.http.get(this.rooturl1 +"/api/AuManagement/GetA_U_Management"); 
 }
 deleteUser(userID){
   return this.http.delete(this.rooturl1 +"/api/AuManagement/DeleteA_U_Management/"+userID);
 }
 editUser(user:User){
   return this.http.put(this.rooturl1 +"/api/Users/PutUser",user);
 }
 getUserDetails(id){
   return this.http.get(this.rooturl1 +"/api/AuManagement/GetA_U_Management/"+id);
 }

 getfolders(id){
   return this.http.get(this.url +"/api/FolderMasters/GetallFolder/"+id);
 }

 // getsubfolders(id:IDViewModel){
 //   return this.http.post(this.url +"/api/GetFolderId/PostFolderIdGet",id);
 // }
 getsubfolders(data:IDViewModel){
   return this.http.post("http://analyticals.co/digitalidentitydmsapi/api/UploadDocument/PostFoldaerIdtake/" , data);
 }
 getbackdata(pid){
   return this.http.get(this.url +"/api/UploadDocument/GetBackdocument/"+pid);
 }

 UpdateFolder(data:AddNewFolder){
   return this.http.post(this.url +"/api/FolderMasters/PostUpdateContent",data);
 }

 addNewFolder(data:AddNewFolder){
   return this.http.post(this.url +"/api/FolderMasters/PostFolderMaster", data);
 }

 deletefolder(aa){
   return this.http.post(this.url +"/api/FolderMasters/PostDeleteContent",aa);
 }
 getUserforBiometrics(){
   return this.http.get(this.rooturl1 +"api/AuManagement");
   }
   sendBiometrics(bio){
     return this.http.post(this.rooturl1 +"/api/Biometrics/PostBiometricsAuthentication",bio);
   }
 // postFile(fileupload:File,fid:string,uid:any,uname:string){
 //   const url = "http://localhost:8538/UploadDocument/DocumentUpload";
 //   const formData : FormData = new FormData();

 //   formData.append("Image" , fileupload, fileupload.name);
 //   formData.append("FolderId" , fid);
 //   return this.http.post(url ,formData );
 // }
 postdocuments(files:File,uid:any,uname:string){
   const url = "http://analyticals.co/digitalidentitydmsapi/api/UploadDocument/NewDocumentUpload";
   // data: {
   //   files: files;
   // }
   const formData : FormData = new FormData();
   formData.append("Image" , files);
   formData.append("userId" , uid);
   formData.append("username" , uname);
   return this.http.post(url ,formData );
 }

 saveDetails(data:Signup){
   return this.http.post(this.rooturl1 +"/api/account/register",data);
 }

 SaveFrienddetails(data:Friend){
   return this.http.post(this.url +"/api/FriendLists/PostFriendList", data);
 }
 friendlist(id){
   return this.http.get(this.url +"/api/FriendLists/GetFriendList/"+id);

 }

 ShareFiles(data:Share){
   return this.http.post(this.url +"/api/Contract/ShareFiles", data);
 }

 GetAllTransactions(id){
   return this.http.get(this.url +"/api/Contract/GetTransactions/"+id);
 }
 
 SearchFiles(data:Search){
   return this.http.post(this.url +"/api/Search/PostSearch", data);
 }
 AcceptReq(f){
   return this.http.post(this.url +"/api/FriendLists/AcceptRequest", f);
 }


 updateretailstores(id){
   return this.http.get(this.url + "api/ReturnedOrders/Updateretailstores/"+id);
 }
 getPayuPlans(){
   return this.http.get(this.rooturl1 +"/api/PlansTypeMasters/GetPlansTypeMasters");
   }

   SaveOrderDetails(aaa:SavepaymentDetails){
     return this.http.post(this.rooturl1 +"/api/CustomerRegistration/GetOrderPayments",aaa);
   }

   getorderpayment(mm:addresslist){
     return this.http.post(this.rooturl1 +"/api/CustomerRegistration/GetOrderDetailsforPayment", mm);
   }

   getpaymentstatus(aa:PayU){
     return this.http.post(this.rooturl1 +"/api/CustomerRegistration/GetPaymentStatus",aa);
   }

   UpdatePlan(id){ 
     return this.http.put(this.planurl +"/api/PlansTypeMasters/PutPlansTypeMaster/", id);
   }
   DeletePlan(id){
     return this.http.delete(this.planurl +"/api/PlansTypeMasters/DeletePlansTypeMaster/"+ id);
   }
   SavePlan(data:Plan){
     return this.http.post(this.planurl +"/api/PlansTypeMasters/PostPlansTypeMaster", data);
   }
   PlantList(){
     return this.http.get(this.rooturl1  +"/api/PlansTypeMasters/GetPlansTypeMasters");
   }
   getAllCustomers(){
     return this.http.get(this.rooturl1 +"/api/AuManagement/GetA_U_Management");
     }

      GetCustomerTransaction(){
         return this.http.get(this.url +"/api/Contract/GetAllTransactions");
     }
     getProfileDetails(data){
       return this.http.get(this.rooturl1 +"/api/AuManagement/GetA_U_Management/" + data);
     }

     postImageFile(caption: string, fileupload: File,long:string,lat:string) {
       const url = "https://blockchainmatrimony.com/matrimonyidusmapi/api/AuManagement/UploadCustomerImage";
       const formData: FormData = new FormData();
       formData.append("Image", fileupload, fileupload.name);
       formData.append("MenuId", caption);
       formData.append("Longitude", long);
       formData.append("Latitude", lat);
       return this.http.post(url, formData);
     }

     uploaduserimage(imgdet:ImageDetails){
       return this.http.post("https://blockchainmatrimony.com/matrimonyidusmapi/api/AuManagement/UploadCustomerImageFromCamera", imgdet);
   
     }
   GetClientCategory(){
       return this.http.get(this.rooturl1 +"/api/ClientCategoryMasters/GetClientCategoryMasters");
   }

   GetRequestType(){
     return this.http.get(this.rooturl1 +"/api/RequestTypeMasters/GetRequestTypeMasters");
   }

   GetRelationType(){
     return this.http.get(this.rooturl1 + "/api/RelationshipMasters/GetRelationshipMasters");
   }
 
   GetMaritalStatus(){
     return this.http.get(this.rooturl1 + "/api/MaritalStatusMasters/GetMaritalStatusMasters");
   }

   GetGenders(){
     return this.http.get(this.rooturl1 + "/api/GenderMasters/GetGenderMasters");
   }

   GetQualifications(){
     return this.http.get(this.rooturl1 + "/api/QualificationMasters/GetQualificationMasters");
   }

   GetStates(){
     return this.http.get(this.rooturl1 + "/api/StateMasters/GetStateMasters");
   }

   GetCountries(){
     return this.http.get(this.rooturl1 + "/api/CountryMasters/GetCountryMasters");
   }
   postFile(caption: string, fileupload: File) {
     const url = "https://blockchainmatrimony.com/matrimonyidusmapi/api/UploadSignature/UploadCustomerSignature";
     const formData: FormData = new FormData();
     formData.append("Image", fileupload, fileupload.name);
     formData.append("MenuId", caption);
     return this.http.post(url, formData);
   }
   getcustomerreportdetails(data:IDMUserId){
     return this.http.post(this.rooturl1 + "/api/IdentityForm/PostGetUserDetailsForReport",data);
   }
   sharefileviamail(data:ShareFileViaMailVM){
     return this.http.post(this.url +"/api/ShareFilesViaMail/ShareFilesThroughMail", data);
   }
   importfilefrommail(data:ImportFromMailVW){
     return this.http.post(this.url +"/api/ShareFilesViaMail/ImportFilesFromMail", data);
 
   }
   UploadEmailFiles(mail:EmailMessageVM){
     return this.http.post(this.url +"/api/ShareFilesViaMail/TestUpload" , mail);
 
   }
}
