import { Injectable } from '@angular/core';
import { MaritalStatusViewModel } from '../ViewModels/MaritalStatusViewModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaritalstatusService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";
  
  constructor(private http:HttpClient) { }
   
  saveMaritalStatus(data: MaritalStatusViewModel) {
       return this.http.post(this.rooturl1 + "/api/MaritalStatusMaster/PostSaveMaritalStatus", data);
     }
     getMaritalStatus(){
       return this.http.get(this.rooturl1 +"/api/MaritalStatusMaster/GetMaritalStatus");
     }
     deletemaritalstatus(id: number){
       return this.http.delete(this.rooturl1 +"/api/MaritalStatusMaster/DeleteMaritalStatusdata/"+ id);
     }
   
     UpdateMaritalStatus(data:MaritalStatusViewModel){
       return this.http.put(this.rooturl1 +"/api/MaritalStatusMaster/PutMaritalStatus",data);
     }
   }
   