import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tand-c',
  templateUrl: './tand-c.component.html',
  styleUrls: ['./tand-c.component.css']
})
export class TandCComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
