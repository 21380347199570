import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerregistrationService {
 readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyidusmapi";
//readonly rooturl1 = "http://localhost:19200";


 constructor(private http: HttpClient) { }
 
 saveIndividualCustomerDetails(data){
   return this.http.post(this.rooturl1 + "/api/CustomerRegistration/PostIndividualCustomerRegDetails", data);
 }
 
 saveStudentCustomerDetails(data){
   return this.http.post(this.rooturl1 + "/api/CustomerRegistration/PostStudentCustomerRegDetails", data);
 }

 saveCompanyCustomerDetails(data){
   return this.http.post(this.rooturl1 + "/api/CustomerRegistration/PostCompanyCustomerRegDetails", data);
 }
 
}

 