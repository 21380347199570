import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LookViewModel } from '../ViewModels/LookViewModel';

@Injectable({
  providedIn: 'root'
})
export class LookService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveLook(data: LookViewModel) {
    return this.http.post(this.rooturl1 + "/api/LookType/PostSaveLook", data);
  }
  getLook() {
    return this.http.get(this.rooturl1 + "/api/LookType/GetLook");
  }
  deletelook(id: number) {
    return this.http.delete(this.rooturl1 + "/api/LookType/DeleteLookdata/" + id);
  }

  UpdateLook(data: LookViewModel) {
    return this.http.put(this.rooturl1 + "/api/LookType/PutLook", data);
  }
}