import { Component, OnInit } from '@angular/core';
import { ZodiacService } from 'src/app/shared/zodiac/zodiac.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ZodiacMasterViewModel } from 'src/app/shared/ViewModels/ZodiacMasterViewModel';

@Component({
  selector: 'app-zodiac',
  templateUrl: './zodiac.component.html',
  styleUrls: ['./zodiac.component.css']
})
export class ZodiacComponent implements OnInit {
  ZodiacList: any;
  zodiac: any;
  zodiacId: any; genid: any;
  
    constructor(private zodiacService:ZodiacService , private router: Router) { }
  
    ngOnInit() {
      this. Zodiac();
      this.zodiac = "";
    }
    ResetData(){
      this.zodiac = "";
    }
  
    Zodiac() {
      this.zodiacService.getZodiac().subscribe((Data:ZodiacMasterViewModel[]) => {
        this.ZodiacList = Data;
      })
    }
  
    SavezodiacForm(form: NgForm) {
      const inputRequest: ZodiacMasterViewModel = {
        ID: 0,
        ZodiacSign: form.controls["zodiac"].value,
      }
      this.zodiacService.SaveZodiac(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/zodiac']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/zodiac']);
          window.location.reload();
        }
      });
    }
  
    editzodiac(data: { zodiacSign: any; id: any; }) {
      this.zodiac = data.zodiacSign;
      this.zodiacId = data.id;
    }
    
  
    UpdateZodiac(form: NgForm) {
      const inputRequest1: ZodiacMasterViewModel = {
        ID: this.zodiacId,
        ZodiacSign: form.controls["zodiac"].value,
      }
      this.zodiacService.UpdateZodiac(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Zodiac Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
 

    deletezodiac(data: { id: any; }) {
      this.genid = data.id;
      this.zodiacService.deletezodiac(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Zodiac Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Zodiac Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  
