import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StarViewModel } from '../ViewModels/StarViewModel';

@Injectable({
  providedIn: 'root'
})
export class StarService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveStar(data: StarViewModel) {
    return this.http.post(this.rooturl1 + "/api/StarTypeMaster/PostSaveStar", data);
  }
  getStar() {
    return this.http.get(this.rooturl1 + "/api/StarTypeMaster/GetStar");
  }
  deletestar(id: number) {
    return this.http.delete(this.rooturl1 + "/api/StarTypeMaster/DeleteStardata/" + id);
  }

  UpdateStar(data: StarViewModel) {
    return this.http.put(this.rooturl1 + "/api/StarTypeMaster/PutStar", data);
  }
}