import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editmenumaster',
  templateUrl: './editmenumaster.component.html',
  styleUrls: ['./editmenumaster.component.scss']
})
export class EditmenumasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
