import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GothramViewModel } from '../ViewModels/GothramViewModel';

@Injectable({
  providedIn: 'root'
})
export class GothramService {
  
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveGothram(data: GothramViewModel) {
    return this.http.post(this.rooturl1 + "/api/GothramTypeMaster/PostSaveGothram", data);
  }
  getGothram() {
    return this.http.get(this.rooturl1 + "/api/GothramTypeMaster/GetGothram");
  }
  deletegothram(id: number) {
    return this.http.delete(this.rooturl1 + "/api/GothramTypeMaster/DeleteGothramdata/" + id);
  }

  UpdateGothram(data: GothramViewModel) {
    return this.http.put(this.rooturl1 + "/api/GothramTypeMaster/PutGothram", data);
  }
}
