import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../shared/MessageService';
import { MenuMaster } from '../shared/MenuMaster/menumaster.model';
import { LoginService } from '../shared/Services/Login/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

var authorizationData;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  public loading = false;

  isLoginError: boolean = false;
  public generateMenuData = {};
  role: number;
  mastermenus: MenuMaster[];
  menuroles: MenuMaster[];
  public message: string = "";
  loadingpage: boolean = false;
  true: any;

  backoffice: any
  BrideGroomId: any;
  userid: any;
  value: any;
  Statge: any;

  setOption(option, value) {
    this.generateMenuData[option] = value;
  }

  getOption() {
    return this.generateMenuData;
  }
  loginForm = new FormGroup({
    UserId: new FormControl(),
    Password: new FormControl()
  });

  constructor(private loginService: LoginService, private router: Router, private messageService: MessageService, private spinner: NgxSpinnerService) {
    var adata = localStorage.getItem('authdata');
    authorizationData = JSON.parse(adata);
  }

  ngOnInit() {

    // if(localStorage.getItem("IsLoggedIn") == "true"){
    //   this.messageService.sendMessage('true');
    // }

  }
  OnSubmit(userName, password) {
    // this.spinner.show();
    localStorage.setItem('loadingpage', "true");
    // this.loading = true;
    if (userName == null || userName == "") {
      alert("Please enter Your UserName");
    }
    else if (password == null || password == "") {
      alert("Please enter Your Password");
    }
    else {
      this.spinner.show();
      this.loginService.userAuthentication(userName, password).subscribe((data: any) => {

        this.spinner.hide();
        var movies = [data.access_token, userName, data.roleName, data.refresh_token, true, data.userUid,
        data.fullName, data.username, data.nameofRole, data.departmentname, data.userId, data.uid, data.area, data.IsMember];
       // this.Registrationstage();
        localStorage.setItem('IsLoggedIn', "true");
        localStorage.setItem('userToken', data.access_token);
        localStorage.setItem('authorizationData', data);
        localStorage.setItem('Token_type', data.token_type);
        localStorage.setItem("UserName", data.userName);

        localStorage.setItem("Password", password);
        localStorage.setItem("role", data.roleName);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("uid", data.uid);
        localStorage.setItem("area", data.area);
        localStorage.setItem("isMember", data.IsMember);
        localStorage.setItem('authdata', JSON.stringify(movies));
        // send message to subscribers via observable subject
        this.messageService.sendMessage('true');
        this.messageService.sendName(data.userName);

        this.BrideGroomId = data.uid;
        this.userid = data.userId;

        this.value = this.Statge       

        if (authorizationData != null) {
          this.loginService.regStage(this.BrideGroomId).subscribe((dataone: any) => {
    
            this.Statge = dataone;
              if (this.Statge==0){
               // window.location.reload();
                this.router.navigate(['/SeekerInfo']);
                localStorage.setItem("value", this.Statge); 
              }
             else if (this.Statge == 3) {        
                //window.location.reload();
                this.router.navigate(['/AboutAttributesInfo']);  
                localStorage.setItem("value", this.Statge);        
              }
              else if (this.Statge == 4) {
                this.router.navigate(['/UploadPicture']);
                localStorage.setItem("value", this.Statge);
              }
              else if (this.Statge == 5) {
                this.router.navigate(['/PreferredPartnerInfo ']);
                localStorage.setItem("value", this.Statge);
              }
              else if (this.Statge == 6) {         
                this.router.navigate(['/YourMatches']);   
                //window.location.reload();      
                localStorage.setItem("value", this.Statge);
    
                //this.value = data;
              }
          });
        }
        //this.spinner.hide();

        //this.router.navigate(['/SeekerInfo']);
        // window.location.reload();      


        // this.role = data.roleName;
        // if (this.role == 2) {
        //   this.router.navigate(['home']);
        //   this.getmenu(this.role);
        // } else {
        //   this.router.navigate(['home']);
        // }
      },
        (err: HttpErrorResponse) => {
          this.isLoginError = true;
          this.spinner.hide();
          this.loading = false;

        }

      )
    }
  };

  Registrationstage() {
    if (authorizationData != null) {
      this.loginService.regStage(this.BrideGroomId).subscribe((dataone: any) => {

        this.Statge = dataone;
        //   if (data==0){
        //    // window.location.reload();
        //     this.router.navigate(['/SeekerInfo']);
        //     localStorage.setItem("value", data); 
        //   }
        //  else if (data == 3) {        
        //     //window.location.reload();
        //     this.router.navigate(['/AboutAttributesInfo']);  
        //     localStorage.setItem("value", data);        
        //   }
        //   else if (data == 4) {
        //     this.router.navigate(['/UploadPicture']);
        //     localStorage.setItem("value", data);
        //   }
        //   else if (data == 5) {
        //     this.router.navigate(['/PreferredPartnerInfo ']);
        //     localStorage.setItem("value", data);
        //   }
        //   else if (data == 6) {         
        //     this.router.navigate(['/YourMatches']);   
        //     //window.location.reload();      
        //     localStorage.setItem("value", data);

        //     //this.value = data;
        //   }
      });
    }
  }


  getmenu(rid) {
    if (authorizationData != null) {
      this.loginService.selectMenubyMenuId(rid).subscribe((menudata: MenuMaster[]) => {
        console.log("menudata", menudata);
        this.mastermenus = menudata;
        console.log("mastermenus", this.mastermenus)
        localStorage.setItem('generatemastermenu', JSON.stringify(menudata));
        this.invoke(this.role);
      });
    }
  }

  invoke(rid) {
    if (authorizationData != null) {
      this.loginService.selectMenubyUserRoleDetails(rid).subscribe((menudata: MenuMaster[]) => {
        console.log("menudata", menudata)
        this.menuroles = menudata
        console.log("menuroles", this.menuroles)
        localStorage.setItem('generatemenudata', JSON.stringify(menudata));
        this.router.navigate(['/homepage']);
        //this.backoffice = true;
        //this.router.navigate(['dashboard']);
        window.location.reload();
      });
    }
  }
  signup() {
    this.router.navigate(['layout/signup']);
  }

}
