import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NoOfSiblingsViewModel } from '../ViewModels/NoOfSiblingsViewModel';

@Injectable({
  providedIn: 'root'
})
export class NoofsiblingsService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveNoOfSiblings(data: NoOfSiblingsViewModel) {
    return this.http.post(this.rooturl1 + "/api/NoOfSiblings/PostSaveSiblings", data);
  }
  getNoOfSiblings() {
    return this.http.get(this.rooturl1 + "/api/NoOfSiblings/GetSiblings");
  }
  deletenoofsiblings(id: number) {
    return this.http.delete(this.rooturl1 + "/api/NoOfSiblings/DeleteSiblingsdata/" + id);
  }

  UpdateNoOfSiblings(data: NoOfSiblingsViewModel) {
    return this.http.put(this.rooturl1 + "/api/NoOfSiblings/PutSiblings", data);
  }
}