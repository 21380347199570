import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QualificationViewModel } from '../ViewModels/QualificationViewModel';

@Injectable({
  providedIn: 'root'
})
export class QualificationService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveQualification(data: QualificationViewModel) {
    return this.http.post(this.rooturl1 + "/api/QualificationMaster/PostSaveQualification", data);
  }
  getQualification() {
    return this.http.get(this.rooturl1 + "/api/QualificationMaster/GetQualification");
  }
  deletequalification(id: number) {
    return this.http.delete(this.rooturl1 + "/api/QualificationMaster/DeleteQualificationdata/" + id);
  }

  UpdateQualification(data: QualificationViewModel) {
    return this.http.put(this.rooturl1 + "/api/QualificationMaster/PutQualification", data);
  }
}