import { Component, OnInit } from '@angular/core';
import { DesignationService } from 'src/app/shared/designation/designation.service';
import { Router } from '@angular/router';
import { DesignationViewModel } from 'src/app/shared/ViewModels/DesignationViewModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {
  
  DesignationList: any;
  designation: any;
  designationId: any; genid: any;
  
    constructor(private designationService:DesignationService , private router: Router) { }
  
    ngOnInit() {
      this. Designation();
      this.designation = "";
    }
    ResetData(){
      this.designation = "";
    }
  
  
    Designation() {
      this.designationService.getDesignation().subscribe((Data:  DesignationViewModel[]) => {
        this.DesignationList = Data;
      })
    }
  
    SaveDesignationForm(form: NgForm) {
      const inputRequest: DesignationViewModel = {
        ID: 0,
        DesignationName: form.controls["designation"].value,
      }
      this.designationService.saveDesignation(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/designation']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/designation']);
          window.location.reload();
        }
      });
    }
  
    editdesignation(data: { designationName: any; id: any; }) {
      this.designation = data.designationName;
      this.designationId = data.id;
    }
  
    UpdateDesignation(form: NgForm) {
      const inputRequest1:DesignationViewModel = {
        ID: this.designationId,
        DesignationName: form.controls["designation"].value,
      }
      this.designationService.UpdateDesignation(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Designation Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletedesignation(data: { id: any; }) {
      this.genid = data.id;
      this.designationService.deletedesignation(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Designation Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Designation Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  


