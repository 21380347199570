import { Injectable } from '@angular/core';
import { BrideGroomIdVM } from '../../ViewModels/BrideGroomIdVM';
import { HttpClient } from '@angular/common/http';
import { BrideGroomMatchingFuzzyIdVM } from '../../ViewModels/BrideGroomMatchingFuzzyIdVM';
import { PreferredBrideGroomDetails } from '../../ViewModels/PreferredBrideGroomDetails';
import { MatchinfGunasVM } from '../../ViewModels/MatchinfGunasVM';
import { BrideGroomDetails } from '../../ViewModels/BrideGroomDetails';
import { AboutBrideGroomDetails } from '../../ViewModels/AboutBrideGroomDetails';
import { RequestVM, RequestListVM } from '../../ViewModels/RequestVM';

@Injectable({
  providedIn: 'root'
})
export class BridegroomService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  
  GetIsPaymentSuccess(data: BrideGroomIdVM) {
    return this.http.post(this.rooturl1 + "/api/EligiblePaid/PostIsEligibleCustomers", data);
  }

  GetFuzzySDetails(data: BrideGroomMatchingFuzzyIdVM) {
    return this.http.post(this.rooturl1 + "/api/EligiblePaid/PostSearchFuzzyDetails", data);
  }  
  
  GetSalaryRanges() {
    return this.http.get(this.rooturl1 + "/api/SalaryRangeMasters/GetSalaryRangeMasters");
  }

  GetFamilyStatusesTypes() {
    return this.http.get(this.rooturl1 + "/api/FamilyStatusTypeMasters/GetFamilyStatusTypeMasters");
  }
  GetFamilyTypeTypes() {
    return this.http.get(this.rooturl1 + "/api/FamilyTypeMasters/GetFamilyTypeMasters");
  }
  GetFamilyValueTypes() {
    return this.http.get(this.rooturl1 + "/api/FamilyValueTypeMasters/GetFamilyValueTypeMasters");
  }
  GetOccupationTypes() {
    return this.http.get(this.rooturl1 + "/api/OccupationMasters/GetOccupationMasters");
  }
  GetStarTypes() {
    return this.http.get(this.rooturl1 + "/api/StarTypeMasters/GetStarTypeMasters");
  }
  GetsubCasteTypes() {
    return this.http.get(this.rooturl1 + "/api/SubCasteTypeMasters/GetSubCasteTypeMasters");
  }
  GetCasteTypes() {
    return this.http.get(this.rooturl1 + "/api/CasteMasters/GetCasteMasters");
  }
  savePreferredBrideGroomDetails(data: PreferredBrideGroomDetails) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostPreferredBrideGroomDetails", data);
  }
  GetMacthesFound(id) {
    return this.http.get(this.rooturl1 + "/api/MatchesFound/GetMacthesFoundData/" + id);
  }
  GetMactheDetails(id) {
    return this.http.get(this.rooturl1 + "/api/MatchesFound/GetMatchFullDetails/" + id);
  }
  GetMatchingGunas(data: MatchinfGunasVM) {
    return this.http.post(this.rooturl1 + "/api/MatchesFound/GetMatchingGunas", data);
  }
  SendRequest(data: RequestVM) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostSendIntrstRequest", data);
  }
  DeclineRequest(data: RequestVM) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostSendDecline", data);
  }
  GetRequestlist(data: RequestListVM) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostgetRequestlist", data);
  }
  GetMsgList(data: RequestListVM) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostgetRequestMsglist", data);
  }
  GetDetails(id) {
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetBrideGroomCompleteDetails/" + id);
  }
  GetGendetail(id){
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetPreffereddetail/" + id);

  }
  GetSoReligio(id){
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetPrefferedSocioReligio/" + id);

  }  
  GetEdu(id){
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetPrefferedEducation/" + id);

  }
  GetFami(id){
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetPrefferedFamily/" + id);

  }
  GetPrior(id){
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetPrefferedPriority/" + id);

  }
  
  UpdateBrideGrromCompleteDetails(data: BrideGroomDetails) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/UpdateBrideGroomCompleteDetails", data);
  }

  GetAttributesinfo(id) {
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetAttributesInfo/" + id);
  }
  GetImagesForUpdate(id) {
    return this.http.get(this.rooturl1 + "/api/InitialReg/GetImages/" + id);
  }
  saveAboutBrideGroomCompleteDetails(data: AboutBrideGroomDetails) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostAboutBrideGroomDetails", data);
  }

}
