import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmployedInViewModel } from '../ViewModels/EmployedInViewModel';

@Injectable({
  providedIn: 'root'
})
export class EmployedinService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveEmployedIn(data: EmployedInViewModel) {
    return this.http.post(this.rooturl1 + "/api/EmployedInMaster/PostSaveEmploy", data);
  }
  getEmployedIn() {
    return this.http.get(this.rooturl1 + "/api/EmployedInMaster/GetEmploy");
  }
  deleteemployedin(id: number) {
    return this.http.delete(this.rooturl1 + "/api/EmployedInMaster/DeleteEmploydata/" + id);
  }

  UpdateEmployedIn(data: EmployedInViewModel) {
    return this.http.put(this.rooturl1 + "/api/EmployedInMaster/PutEmploy", data);
  }
}
