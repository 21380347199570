import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ManglikViewModel } from '../ViewModels/ManglikViewModel';


@Injectable({
  providedIn: 'root'
})
export class ManglikService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveManglik(data: ManglikViewModel) {
    return this.http.post(this.rooturl1 + "/api/ManglikTypeMaster/PostSaveManglik", data);
  }
  getManglik() {
    return this.http.get(this.rooturl1 + "/api/ManglikTypeMaster/GetManglik");
  }
  deletemanglik(id: number) {
    return this.http.delete(this.rooturl1 + "/api/ManglikTypeMaster/DeleteManglikdata/" + id);
  }

  UpdateManglik(data: ManglikViewModel) {
    return this.http.put(this.rooturl1 + "/api/ManglikTypeMaster/PutManglik", data);
  }
}
