import { Component, OnInit } from '@angular/core';
import { FamilyStatusViewModel } from 'src/app/shared/ViewModels/FamilyStatusViewModel';
import { NgForm } from '@angular/forms';
import { FamilystatusService } from 'src/app/shared/familystatus/familystatus.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-familystatus',
  templateUrl: './familystatus.component.html',
  styleUrls: ['./familystatus.component.css']
})
export class FamilystatusComponent implements OnInit {
  FamilyStatusList: any;
  familystatus: any;
  familystatusId: any; genid: any;
  
    constructor(private familystatusService:FamilystatusService , private router: Router) { }
  
    ngOnInit() {
      this. FamilyStatus();
      this.familystatus = "";
    }
    ResetData(){
      this.familystatus = "";
    }
  
  
    FamilyStatus() {
      this.familystatusService.getFamilyStatus().subscribe((Data:FamilyStatusViewModel[]) => {
        this.FamilyStatusList = Data;
      })
    }
  
    SaveFamilyStatusForm(form: NgForm) {
      const inputRequest: FamilyStatusViewModel = {
        Id: 0,
        FamilyStatusType: form.controls["familystatus"].value,
      }
      this.familystatusService.saveFamilyStatus(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/familystatus']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/familystatus']);
          window.location.reload();
        }
      });
    }
  
    editfamilystatus(data: { familyStatusType: any; id: any; }) {
      this.familystatus = data.familyStatusType;
      this.familystatusId = data.id;
    }
  
    UpdateFamilyStatus(form: NgForm) {
      const inputRequest1: FamilyStatusViewModel = {
        Id: this.familystatusId,
        FamilyStatusType: form.controls["familystatus"].value,
      }
      this.familystatusService.UpdateFamilyStatus(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("FamilyStatus Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletefamilystatus(data: { id: any; }) {
      this.genid = data.id;
      this.familystatusService.deletefamilystatus(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("FamilyStatus Deleted Succesfully");
            window.location.reload();
        } else {
            alert("FamilyStatus Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

