import { Injectable } from '@angular/core';
import { MobileVM } from '../../ViewModels/MobileVM';
import { HttpClient } from '@angular/common/http';
import { MobileViewModel } from '../../ViewModels/MobileViewModel';
import { InitialDetails } from '../../ViewModels/InitialDetails';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  readonly url = "http://localhost:8538";


  readonly planurl = "http://localhost:8538"

  constructor(private http: HttpClient) { }

  SendOtp(num:MobileVM){
    return this.http.post(this.rooturl1 + "/api/Signup/PostSendOtpForResistration", num);
  }

  postVerifyOTP(data:MobileViewModel){
    return this.http.post(this.rooturl1 + "/api/Signup/RegistrationOTPValidation", data);
  }
  GetSeekersData(){
    return this.http.get(this.rooturl1 + "/api/SeekingMatchesForMasters/GetSeekingMatchesForMasters");
  }
  saveInitialRegDetails(data: InitialDetails) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostInitialDetails", data);
  }
}
