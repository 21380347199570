import { Component, OnInit } from '@angular/core';
import { EmployedInViewModel } from 'src/app/shared/ViewModels/EmployedInViewModel';
import { NgForm } from '@angular/forms';
import { EmployedinService } from 'src/app/shared/employedin/employedin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employedin',
  templateUrl: './employedin.component.html',
  styleUrls: ['./employedin.component.css']
})
export class EmployedinComponent implements OnInit {
  EmployedInList: any;
  employedin: any;
  employedinId: any; genid: any;
  
    constructor(private employedinService:EmployedinService , private router: Router) { }
  
    ngOnInit() {
      this. EmployedIn();
      this.employedin = "";
    }
    ResetData(){
      this.employedin = "";
    }
  
  
    EmployedIn() {
      this.employedinService.getEmployedIn().subscribe((Data:EmployedInViewModel[]) => {
        this.EmployedInList = Data;
      })
    }
  
    SaveEmployedInForm(form: NgForm) {
      const inputRequest: EmployedInViewModel = {
        ID: 0,
        Employedin: form.controls["employedin"].value,
      }
      this.employedinService.saveEmployedIn(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/employedin']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/employedin']);
          window.location.reload();
        }
      });
    }
  
    editemployedin(data: { employedin: any; id: any; }) {
      this.employedin = data.employedin;
      this.employedinId = data.id;
    }
  
    UpdateEmployedIn(form: NgForm) {
      const inputRequest1: EmployedInViewModel = {
        ID: this.employedinId,
        Employedin: form.controls["employedin"].value,
      }
      this.employedinService.UpdateEmployedIn(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("EmployedIn Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deleteemployedin(data: { id: any; }) {
      this.genid = data.id;
      this.employedinService.deleteemployedin(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("EmployedIn Deleted Succesfully");
            window.location.reload();
        } else {
            alert("EmployedIn Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  

