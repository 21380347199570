import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RaasiViewModel } from 'src/app/shared/ViewModels/RaasiViewModel';
import { RaasiService } from 'src/app/shared/raasi/raasi.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-raasi',
  templateUrl: './raasi.component.html',
  styleUrls: ['./raasi.component.css']
})
export class RaasiComponent implements OnInit {
  RaasiList: any;
  raasi: any;
  raasiId: any; genid: any;
  
    constructor(private raasiService:RaasiService , private router: Router) { }
  
    ngOnInit() {
      this.Raasi();
      this.raasi = "";
    }
    ResetData(){
      this.raasi = "";
    }
  
    Raasi() {
      this.raasiService.getRaasi().subscribe((Data: RaasiViewModel[]) => {
        this.RaasiList = Data;
      })
    }
  
    SaveRaasiForm(form: NgForm) {
      const inputRequest: RaasiViewModel = {
        ID: 0,
        RaasiType: form.controls["raasi"].value,
      }
      this.raasiService.saveRaasi(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/raasi']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/raasi']);
        }
      });
    }
  
    editraasi(data: { raasiType: any; id: any; }) {
      this.raasi = data.raasiType;
      this.raasiId = data.id;
    }
  
    UpdateRaasi(form: NgForm) {
      const inputRequest1: RaasiViewModel = {
        ID: this.raasiId,
        RaasiType: form.controls["raasi"].value,
      }
      this.raasiService.UpdateRaasi(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Raasi Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deleteraasi(data: { id: any; }) {
      this.genid = data.id;
      this.raasiService.deleteraasi(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Raasi Deleted Succesfully");
            window.location.reload();
          } else {
            alert("Raasi Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

  