import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import 'rxjs/add/observable/interval';
import { Observable } from 'rxjs';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { FolderviewService } from 'src/app/shared/FlderViewService/folderview.service';
import { AddNewFolder } from 'src/app/shared/ViewModels/AddNewFolder';
import { Search } from 'src/app/shared/ViewModels/Search';
import { Share } from 'src/app/shared/ViewModels/Share';
import { ShareFileViaMailVM } from 'src/app/shared/ViewModels/ShareFileViaMailVM';
import { ImportFromMailVW } from 'src/app/shared/ViewModels/ImportFromMailVW';

@Component({
  selector: 'app-allfiles',
  templateUrl: './allfiles.component.html',
  styleUrls: ['./allfiles.component.scss']
})
export class AllfilesComponent implements OnInit {
  allSubFolders: any;
  SelectedFiles: any;
  public isLoggedIn: boolean = false;
  public remove: any = false;
  uid: any; roleid: any;
  fileupload: File = null; fid: any;
  userName: string; sMsg: any;
  ID: any; pdfUrl: any; closeResult: any; pdfUrl1: any; pdfUrl2: any; pdfUrl3: any;
  msg: string; m: string; zzz: any;
  details: any;
  updatemsg: any; u: any;
  fl: any;
  pid: any; FormData: any; subf: any; Press: any;
  Pid: any; zoom_to: any;
  Friends: any; shareitem: any;
  selected = []; CurrentFiles: any;
  friendid: number;
  R: any;
  Transcations: any;
  totaltrans: any;
  FoldID: any;
  FileFoldID: any;
  searchvalue: any;
  val: any;
  public SearchEnabled: boolean = false;
  public ShowResult: boolean = false;
  SearchResult: any;
  temp: any;
  doc: string;
  current_url: any;
  displayUrl: any;
  current_url1: any;
  mail: boolean;
  MailMessage: string;
  mailmsg: string;
  usermail: string;
  FromMailID: string;
  FromPassword: any;
  frmmailpwd: any;
  ToMailID: any;
  tomail: any;
  responsemail: any;
  sub: any;
  alldata: any;
  importmailpwd: any;
  inboxmsgs: any;
  Particularusermail: string;
  videoplay: any;
  videodate: any;


  constructor(private router: ActivatedRoute, private userservice: UserServiceService,
    private route: Router, private httpService: HttpClient, private folderviewservice: FolderviewService,
    private modalService: NgbModal, private domSanitizer: DomSanitizer) {

    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.GetDetails(params["id"])
      }
    });
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
    }

    // this.sub = Observable.interval(60000)
    // .subscribe((val) => { 
    //     console.log('called'); 
    //     this.GetAutoCallDetails();
    // });
  }
  GetAutoCallDetails(){
    this.userservice.autocall().subscribe((respose:any) =>{
          this.alldata= respose;
      });
  }
  ngOnInit() {
    this.GetFriendDetails();
  }

  GetDetails(id) {
    this.FileFoldID = id;
    this.userservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.fileName;
      this.fid = this.allSubFolders.parentId;
      console.log("user", this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
  }

  GetSubfolderdetails(data) {
    this.temp = data;
    this.subf = data.folderName;
    this.FileFoldID = data.folderID;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.allSubFolders = respose;
      // this.subf = this.allSubFolders.FolderName;
      var Pid = this.allSubFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));

      console.log("user", this.allSubFolders);
    });
  }



  GetBack(allSubFolders) {
    this.userservice.getbackdata(allSubFolders.parentId).subscribe((respose: any) => {
      this.Press = true;
      this.allSubFolders = respose;
      this.Pid = this.allSubFolders.parentId;
      this.FileFoldID = this.allSubFolders.parentId;
      this.allSubFolders.folderID = this.allSubFolders.parentId;
      if (this.allSubFolders.parentId == "0") {
        localStorage.setItem('formdata', JSON.stringify(this.allSubFolders));
        this.route.navigate(['/allfolders']);
      }

    });
  }


  addSubFolder(allSubFolders, subfoldercontent) {
    this.modalService.open(subfoldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  savenewSubFolder() {
    if (this.allSubFolders.SubFolders.length == 0) {
      this.fl = "0";
    }
    else {
      this.fl = this.allSubFolders.SubFolders[0].folderID;
    }
    const inputRequest: AddNewFolder = {
      Id: 0,
      FolderID: this.fl,
      RoleId: this.roleid,
      FolderName: this.msg,
      UserId: this.uid,
      Code: "SubFolder",
      ParentID: this.allSubFolders.parentId,
      Click: null
    }
    this.userservice.addNewFolder(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Added Successfully");
        this.route.navigate(['/allfolders']);
        window.location.reload();
      }
      else {
        alert("Something went Wrong");
        this.route.navigate(['/allfolders']);
      }
    });
  }

  UpdateSubFolder(folderview, editfolder) {
    this.details = folderview;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u = this.updatemsg;
    });
  }

  UpdateSubFolderName() {
    const inputRequest: AddNewFolder = {
      Id: this.details.id,
      FolderID: this.details.folderID,
      RoleId: this.roleid,
      FolderName: this.updatemsg,
      UserId: this.uid,
      Code: "Folder",
      ParentID: this.details.parentID,
      Click: null
    }
    this.userservice.UpdateFolder(inputRequest).subscribe((data: any) => {
      if (data == true) {
        alert("Added Successfully");
        this.route.navigate(['/allfolders']);
        window.location.reload();
      }
      else {
        alert("Something went Wrong");
        this.route.navigate(['/allfolders']);
        window.location.reload();
      }
    });
  }

  // addFolder(){
  //   this.route.navigate(['/addnewfolder/' +2]);
  // }

  // handleFIeInput(file:FileList){
  //   this.fileupload = file.item(0);
  //   var reader = new FileReader();
  //    reader.onload = (event:any)=>{
  //     //this.imageUrl = event.target.result;
  //   // }
  //   reader.readAsDataURL(this.fileupload);
  // }

  GetFriendDetails() {
    this.userservice.friendlist(this.uid).subscribe((respose: any) => {
      this.Friends = respose;

      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
  }

  Share() {
    this.shareitem = true;

  }
  CancelShare() {
    this.shareitem = false;

  }
  onChange(event: any) {
    let files = [].slice.call(event.target.files);
    // input.value = files.map(f => f.name).join(', ');
    const frmData = new FormData();
    for (var i = 0; i < files.length; i++) {
      frmData.append("fileUpload", files[i]);
    }
    frmData.append("userId", this.uid);
    frmData.append("username", this.userName);
    frmData.append("folderid", this.allSubFolders.parentId);

    this.httpService.post('http://analyticals.co/bsdmsapi/api/UploadDocument/NewDocumentUpload/', frmData).subscribe(
      data => {
        // SHOW A MESSAGE RECEIVED FROM THE WEB API.
        this.sMsg = data as string;
        console.log(this.sMsg);
        if (this.sMsg = "F") {
          this.GetDetails(this.FileFoldID);
        }
        else {
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);    // Show error, if any.
      });
  }

  Delete(data) {
    var res;
    if (data.folderName == null) {
      res = confirm("Do You Want to Delete " + data.documentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.folderName + "!!!");
    }
    if (res == true) {
      if (data.hash) {
        var info = {
          Id: data.id,
          FolderID: data.folderId,
          FolderName: data.folderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.folderId,
          RoleId: this.roleid,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    this.userservice.deletefolder(info).subscribe((data: any) => {
      alert("Deleted Successfully");
      window.location.reload();
    });
  }

  ViewDoc(aa, content) {
    this.zzz = aa;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  // ViewDocument(bb,pdfcontent){
  //   this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
  //     this.pdfUrl = data;
  //     this.modalService.open(pdfcontent).result.then((result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       this.m=this.msg;
  //     });
  //   });
  // }

  ViewDocument(aa, pdfcontent) {
    this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdfcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  // ViewDocumentword(bb,pdfcontent){
  //   this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
  //     this.pdfUrl = data.DocPath;
  //     this.modalService.open(pdfcontent).result.then((result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       this.m=this.msg;
  //     });
  //   });
  // }

  ViewDocumentword(aa, wordcontent) {
    this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file)
    this.pdfUrl = this.zzz.file;
    this.doc = "http://docs.google.com/gview?" + this.zzz.file + "&embedded=true";

    this.modalService.open(wordcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  // ViewxlsDoc(bb,xlscontent){
  //   this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
  //     this.pdfUrl = data;
  //     this.modalService.open(xlscontent).result.then((result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       this.m=this.msg;
  //     });
  //   });
  // }

  ViewxlsDoc(aa, xlscontent) {
    this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(xlscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  // ViewPpt(bb,pptcontent){
  //   this.folderviewservice.viewDocument(bb).subscribe((data:any)=>{
  //     this.pdfUrl = data;
  //     this.modalService.open(pptcontent).result.then((result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       this.m=this.msg;
  //     });
  //   });
  // }

  ViewPpt(aa, pptcontent) {
    this.zzz = aa;
    this.current_url1 = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file)
    this.pdfUrl = this.zzz.file;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pptcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ViewVideo(cc, videocontent) {
    this.videoplay = cc.file
    this.videodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
      this.modalService.open(videocontent).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    //});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggle(data) {
    this.CurrentFiles = data;
    if (data != null) {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].Id == data.Id) {
          var index = this.selected.indexOf(data);
          this.selected.splice(index, 1);
          this.remove = true;
        }
      }
      if (this.remove == false) {
        this.selected.push(data);
      }
    }
    this.remove = false;

  }

  Search() {
    this.val = this.searchvalue;
    if (this.searchvalue.length != 0) {
      this.SearchEnabled = true;
    }
    else {
      this.SearchEnabled = false;
    }
    if (this.searchvalue.length > 1) {
      const inputRequest: Search = {
        Value: this.searchvalue,
        UserId: this.uid
      }

      this.userservice.SearchFiles(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }

  ShareFile(id) {
    const inputRequest: Share = {
      SelectedFiles: this.selected,
      FriendListId: id,
      UserId: this.uid
    }
    if (this.selected.length == 0) {
      alert("Please Select atleast one file")
    }
    if (id != null)
      this.userservice.ShareFiles(inputRequest).subscribe((data: any) => {
        this.R = data;
        var fol = this.CurrentFiles;
        if (fol.hash) {
          var info = {
            Id: fol.id,
            FolderID: fol.folderId,
            // FolderName: this.folder.FolderName,
            UserId: this.uid,
            Code: "Document",
            ParentID: fol.folderId,
            RoleId: this.roleid,
            Click: this.Press
          };
        }
        this.GetSubfolderdetails(info);
        alert("File Sent");
        window.location.reload();
      });
    else {
      alert("Select Users");
    }
  }
  onSearchChange(searchValue: string) {
    this.val = searchValue;
    if (this.val.length != 0) {
      this.SearchEnabled = true;
    }
    else {
      this.SearchEnabled = false;
      this.GetSubfolderdetails(this.temp);

    }
    if (this.val.length > 1) {
      const inputRequest: Search = {
        Value: this.val,
        UserId: this.uid
      }

      this.userservice.SearchFiles(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }

  // folder data

  GetSubfolder(data) {
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      this.ShowResult = true;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));

      console.log("user", this.allSubFolders);
      this.route.navigate(['allsubfolders/' + data.folderID]);
    });
  }

  ShareViaMailPopup(sharefileviamailcontent) {
    this.modalService.open(sharefileviamailcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.MailMessage = this.mailmsg;
      this.FromMailID = this.usermail;
      this.FromPassword = this.frmmailpwd;
      this.ToMailID = this.tomail;
    });
  }

  ShareFileViaMail() {
    const inputRequest: ShareFileViaMailVM = {
      FromMailId: this.usermail,
      FromMailPassword: this.frmmailpwd,
      ToMailId: this.tomail,
      MailMessage: this.mailmsg,
      SelectedFiles: this.selected,
    }
    if (this.selected.length == 0) {
      alert("Please Select atleast one file")
    }
    if (this.usermail == null || this.usermail=="") {
      alert("Please Enter Mail ID")
    }
   else if (this.frmmailpwd == null || this.frmmailpwd=="") {
      alert("Please Enter Password")
    }
    else if (this.tomail == null || this.tomail=="") {
      alert("Please Enter To Mail ID")
    }
   else{
    this.userservice.sharefileviamail(inputRequest).subscribe((respose: any) => {
      this.responsemail = respose;
      window.location.reload();

    });
  }
  }

  importfrommailpopup(importmailcontent){
    this.modalService.open(importmailcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     
    });
  }

  ImportFromMail() {
    this.Particularusermail = this.usermail
    const inputRequest: ImportFromMailVW = {
      ImportFromMail: this.usermail,
      ImportFromPassword: this.importmailpwd
         }
    if (this.usermail == null || this.usermail=="") {
      alert("Please Enter Mail ID")
    }
   else if (this.importmailpwd == null || this.importmailpwd=="") {
      alert("Please Enter Password")
    }
    else{
      localStorage.setItem("ParticularMailId",this.Particularusermail);
      localStorage.setItem("Particularpwd",this.importmailpwd);
      this.route.navigate(['/mailinbox']);
  }
  }
}


