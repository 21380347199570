import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BridegroomService } from '../shared/Services/BridegroomService/bridegroom.service';
import { MatchinfGunasVM } from '../shared/ViewModels/MatchinfGunasVM';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { HeightViewModel } from '../shared/ViewModels/HeightViewModel';
import { HeightService } from '../shared/height/height.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { RequestVM, RequestListVM } from '../shared/ViewModels/RequestVM';


@Component({
  selector: 'app-full-details',
  templateUrl: './full-details.component.html',
  styleUrls: ['./full-details.component.css']
})
export class FullDetailsComponent implements OnInit {

  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  MatrimonyId: any;
  DetailsList: any;
  aboutGroomBride: any;
  Fname: any;
  id: any;
  bigImage: any;
  LoginMatID: string;
  oneBid: any;
  TwoBid: any;
  HeightList: any[];

  panelOpenState = false;
  today= new Date();
  jstoday = '';
  age: any;
  percentagematched: any;
  viewprfileID: any;
  FoundMathes: any;
  toid: any;
  toname: any;
  Reqmsg: any;
  lenofreqmsg: any;
  replyenable: boolean;
  otherprofiels: boolean;

  constructor(private spinner: NgxSpinnerService,private modalService: NgbModal,private router: ActivatedRoute, private route: Router, private bridegroomService: BridegroomService,private heightService:HeightService) {
    this.LoginMatID = localStorage.getItem('uid');
    this.GetHeight();
    this.otherprofiels = false;
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.GetDetails(params["id"])
      }
    });
//     this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
//  this.age = Date.now() - this.DetailsList.dateOfBirth;
  }

 
 
  GetDetails(id) {
    var data =id.split(',');
    this.MatrimonyId = data[0];
    this.percentagematched = data[1];
  }

  ngOnInit() {
    this.GetFullDetails();    
  }
  GetanotherProfile(id) {
    this.MatrimonyId = id;
    this.GetFullDetails();   
    this.route.navigate(['/fulldetails',id]);
   // window.location.reload();
  }
  GetFullDetails() {
    //this.spinner.show();
    this.bridegroomService.GetMactheDetails(this.MatrimonyId).subscribe((response: any) => {
      this.DetailsList = response;
      this.viewprfileID =  this.DetailsList.brideGroomId;
      this.GetReqestMsg();
      this.GetMatchesFound();
      this.bigImage = this.DetailsList.imageCus[0].customerImage;
      if (this.DetailsList.payment == false) {
        this.id = this.DetailsList.brideGroomId;
        this.route.navigate(['/payuplans',this.id]);
      }
      this.spinner.hide();
    });
  }
  GetReqestMsg(){
    this.spinner.show();
    this.Reqmsg = null;
    const inputRequest: RequestListVM = {
      RequestIdFrom: this.LoginMatID,
      RequestIdTo: this.viewprfileID
    }
    this.bridegroomService.GetRequestlist(inputRequest).subscribe((data: any) => {
      if (data != null) {   
        this.Reqmsg = data;   

        if (this.Reqmsg.length == 1) {
          this.lenofreqmsg = true;
        }
        else if(this.Reqmsg.length != 0){
          this.lenofreqmsg = false;
        }
        for (let index = 0; index < this.Reqmsg.length; index++) {
          if (this.Reqmsg[index].requestIdFrom == this.LoginMatID) {
            this.replyenable = false;
          }
          else if(this.Reqmsg[index].requestIdTo != this.LoginMatID){            
            this.replyenable = true;
          }
        }        
        this.spinner.hide();
      }
      else {       
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
  
  GetMatchesFound() {
    this.spinner.show();
    this.bridegroomService.GetMacthesFound(this.LoginMatID).subscribe((response: any) => {
      this.FoundMathes = response;
      this.otherprofiels = true;
      this.spinner.hide();
    });
  }
  GetHeight(){
    this.heightService.getHeight().subscribe((Data:  HeightViewModel[]) => {
      this.HeightList = Data;
    });
  }
  getheight(aa)
  {
 for(var i=0;i<this.HeightList.length;i++)
 {
   var val;
   if (this.HeightList[i].id==aa)
   {
     val=this.HeightList[i].height;
   }
   return val;
 }
  }
  
 
  GetImage(data) {
    this.bigImage = data.customerImage
  }

  MatchingGunas(data) {
    this.oneBid = data.customerImage

    const inputRequest: MatchinfGunasVM = {
      MIdOne:data,
      MIdTwo:this.LoginMatID
    }
    this.bridegroomService.GetMatchingGunas(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Registered Your Details Successfully");
        //this.router.navigate(['/login']);
        this.spinner.hide();
      }
      else {
        alert("Please Fill Details Properly");
        this.spinner.hide();
      }
    });
  }

  SendIntrestRequesttoid(toid, name) {
    this.toid = toid;
    this.toname = name
  }
  SendIntrestRequest(form: NgForm){
    this.spinner.show();
    const inputRequest: RequestVM = {
      RequestIdFrom: this.LoginMatID,
      RequestIdTo: this.toid,
      RequestStatus: null,
      RequestAcceptReject: null,
      Message: form.controls["meassage"].value
    }
    this.bridegroomService.SendRequest(inputRequest).subscribe((data: any) => {
      if (data == "1") {
        alert("Sent Successfully");
        //this.GetFullDetails();
        this.GetReqestMsg();
        this.spinner.hide();
      }
      else {
        alert("Please Tryagain");
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
  DeclineIntrest(toid) {
    this.spinner.show();
    const inputRequest: RequestVM = {
      RequestIdFrom: this.LoginMatID,
      RequestIdTo: this.toid,
      RequestStatus: null,
      RequestAcceptReject: true,
      Message: null
    }
    this.bridegroomService.DeclineRequest(inputRequest).subscribe((data: any) => {
      if (data == "1") {
        alert("Sent Successfully");
        //this.GetFullDetails();
        this.GetReqestMsg();
        this.spinner.hide();
      }
      else {
        alert("Please Tryagain");
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }

}
