import { Component, OnInit } from '@angular/core';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BridegroomService } from 'src/app/shared/Services/BridegroomService/bridegroom.service';

@Component({
  selector: 'app-update-picture',
  templateUrl: './update-picture.component.html',
  styleUrls: ['./update-picture.component.css']
})
export class UpdatePictureComponent implements OnInit {
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  public trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  public nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  selectimage: string;
  public forstudentonly: boolean = false;
  public forcompanyonly: boolean = false;
  public forGPonly: boolean = false;
  public forIndividualonly: boolean = false;
  public isemailtype: boolean = false;
  public ishardcopytype: boolean = false;
  formvalue: any;

  imgdata: any;
  imagedet: any;
  fileupload: File;
  Id: any;
  userid: string;
  lng: any;
  lat: any;
  imagggg: any;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
  };
  imageUrl: string;
  MatrimonyId: string;
  uid: any;
  Images: any;

  constructor(private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService,private bridegroomService: BridegroomService, private userservice:UserServiceService ) {
    this.MatrimonyId = localStorage.getItem('uid');
    this.spinner.hide();
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.uid = params["id"];
      }
    });

  }
  ngOnInit() {
    this.GetImagees();
    this.selectimage = "";
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }

  GetImagees() {
    this.bridegroomService.GetImagesForUpdate(this.MatrimonyId).subscribe((response: any) => {
      this.Images = response;
    });
  }

  Delete(data) {
    var res;
    if (data != null) {
      res = confirm("Do You Want to Delete Image !!!");
    }    
    if (res == true) {
        this.userservice.deleteImage(data).subscribe((data: any) => {
          alert("Deleted Successfully");
          window.location.reload();
        });
    }
    
  }
  handleFIeInput(file: FileList) {
    this.fileupload = file.item(0);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileupload);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  OnSubmiit(Image) {
    this.userservice.postImageFile(this.MatrimonyId, this.fileupload, this.lng, this.lat).subscribe(
      data => {
        console.log('done');
        Image.value = null;
        this.imageUrl = "/assets/img/img.png";
        alert("File Uploaded Succesfully");
        if (data == "File Uploaded Successfully") {
          // this.router.navigate(['/PreferredPartnerInfo']);
          window.location.reload();
        }
        else {
          alert("Something went wrong")
          window.location.reload();
        }
      });
  }

}
