import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../user.model';
import { EditUser } from '../edituser.model';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {
  uid:number;
  pswrd:string;
  cpswrd:string;
  phn:string;
  rl:number;
  dept:number;
  divi:number;
  loc:number;
  office:string;
  bank:string;
  branch:string;
  question:string;
  answer:string;

  userForm = new FormGroup({
    
    firstName:new FormControl(),
    middleName: new FormControl(),
    lastName: new FormControl(),
    userName:new FormControl(),
    password:new FormControl(),
    cpassword: new FormControl(),
    email: new FormControl(),
    phone:new FormControl(),
    role: new FormControl(),
    department: new FormControl(),
    division: new FormControl(),
    location: new FormControl(),
    headoffice: new FormControl(),
    bank: new FormControl(),
    branch: new FormControl(),
    question: new FormControl(),
    answer: new FormControl(),
    
  })
  constructor(private userservice:UserServiceService, private route:Router, private router:ActivatedRoute) { 
    this.router.params.subscribe(params=>{
      if(params["id"]){
        this.GetUserDetails(params["id"])
      }
    });
  }

  ngOnInit() {
  }
  GetUserDetails(id){
    
    this.userservice.getUserDetails(id).subscribe((data:EditUser)=>{

      //this.uid=data.UserId
      this.pswrd=data.password
      this.cpswrd=data.confirmPassword
      this.phn=data.phoneNumber
      this.rl=data.userRole
      this.dept=data.department
      this.divi = data.division
      this.loc = data.location
      this.bank=data.bankName
      this.office=data.headOffice
      this.branch = data.branchName
      this.question = data.question
      this.answer = data.answer


      this.userForm.setValue({
        firstName:data.firstName,
        middleName:data.middleName,
        lastName:data.lastName,
        userName:data.userName,
        password:data.password,
        cpassword:data.confirmPassword,
        email:data.email,
        phone:data.phoneNumber,
        role:data.userRole,
        department:data.department,
        division:data.division,
        location:data.location,
        headoffice:data.headOffice,
        bank:data.bankName,
        branch:data.branchName,
        question:data.question,
        answer:data.answer

      });
    })
  }

  onFormSubmit():void{
    const inputRequest:User={
      FirstName:this.userForm.get("FirstName").value,
      MiddleName:this.userForm.get("MiddleName").value,
      LastName:this.userForm.get("LastName").value,
      UserName:this.userForm.get("UserName").value,
      Email:this.userForm.get("Email").value,
      Password:this.pswrd,
      ConfirmPassword:this.cpswrd,
      PhoneNumber:this.phn,
      UserRole:this.rl,
      IsActive:true,
      LastUpdated:new Date,
      IsPhoneNumberConfirmed:true,
      Department:this.dept,
      Division:this.divi,
      Location:this.loc,
      BankName:this.bank,
      HeadOffice:this.office,
      BranchName:this.branch,
      Question:this.question,
      Answer:this.answer,
      CustomerId:null,
      UserID:null,
    }
   
    this.userservice.editUser(inputRequest).subscribe((data:any)=>{
      if(data.succeeded==true){       
      }
    });
  }
}
