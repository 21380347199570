import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MoveFolderOrDocument } from '../../ViewModels/MoveFolderOrDocument';
import { MoveDocData } from '../../ViewModels/MoveDocData';
import { DownloadVM } from '../../ViewModels/DownloadVM';

@Injectable({
  providedIn: 'root'
})
export class MoveService {

  readonly url = "https://blockchainmatrimony.com/matrimonydms/api/";

  //readonly url = "http://analyticals.co/haspitdmsapi/api/";

  constructor(private http: HttpClient) { }
  saveMoveFolderOrDocument(data: MoveFolderOrDocument) {
    return this.http.post(this.url + "MoveFolderOrDocument/PostMoveFolderOrDocument", data);
  }
  moveDocument(data: MoveDocData){
    return this.http.post(this.url + "MoveFolderOrDocument/PostMoveDocument", data);
  }
  goDownloads(data:DownloadVM){
    return this.http.post(this.url + "MoveFolderOrDocument/PostDownloadLog", data);
  }
  getAllDownloadedFiles(id:any) {
    return this.http.get(this.url + "MoveFolderOrDocument/GetAllDownloadedFiles/" + id);
  }
}
