import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserRole } from 'src/app/USRM/User/userrole.model';

@Injectable({
  providedIn: 'root'
})
export class UserroleService {

  readonly url = "http://analyticals.co/bsusmapi/api/";

  constructor(private http:HttpClient) { }

  getUserRole(){
    return this.http.get(this.url +"AuRoleMaster");
  }
  getDepartments(){
    return this.http.get(this.url +"DepartmentMasters");
  }

  getDivisions(){
    return this.http.get(this.url +"DivisionsMasters/GetDivisionsMasters");
  }
  getSections(){
    return this.http.get(this.url +"AreaMasters");
  }
  getLocation(){
    return this.http.get(this.url +"LocationMasters");
  }
  getHeadOffices(){
    return this.http.get(this.url +"HeadOfficeMasters");
  }
  getBankNames(){
    return this.http.get(this.url +"BankMasters");
  }
  getBranches(){
    return this.http.get(this.url +"BranchMasters");
  }

  getUserRoleDetails(id){
    return this.http.get(this.url +"AuRoleMaster/GetA_U_RoleMaster/"+id);
  }

  saveUserRole(userRole:UserRole){
    return this.http.post(this.url +"AuRoleMaster",userRole);
  }

  editUserRole(userRole:UserRole){
    return this.http.put(this.url +"AuRoleMaster/PutA_U_RoleMaster",userRole);
  }

  deleteRole(roleID){
    return this.http.delete(this.url +"RoleMasters/DeleteRoleMaster/"+roleID);
  }
}
