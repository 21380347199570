import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { addresslist } from '../../ViewModels/addresslist';
import { PayU } from '../../ViewModels/Payment';
import { SavepaymentDetails } from '../../ViewModels/SavepaymentDetails';
import { AddNewFolder } from '../../ViewModels/AddNewFolder';
import { IDViewModel } from '../../ViewModels/IDViewModel';
import { Search } from '../../ViewModels/Search';
import { Share } from '../../ViewModels/Share';
import { ShareFileViaMailVM } from '../../ViewModels/ShareFileViaMailVM';
import { BM_MobileOTP } from '../../ViewModels/BM_MobileOTP';
import { MobileViewModel } from '../../ViewModels/MobileViewModel';
import { ChangePassword } from '../../ViewModels/ChangePassword';
import { Friend } from '../../ViewModels/Friend';
import { Plan } from '../../ViewModels/plan';
import { User } from 'src/app/USRM/User/user.model';
import { IndividualChatDetails } from '../../ViewModels/IndividualChatDetails';
import { UserId } from '../../ViewModels/UserIdViewModel';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  readonly url = "https://blockchainmatrimony.com/matrimonydms";


  readonly planurl = "http://localhost:8538"


  constructor(private http: HttpClient) { }

  getPayuPlans() {
    return this.http.get(this.rooturl1 + "/api/PlansTypeMasters/GetPlansTypeMasters");
  }
  getorderpayment(mm: addresslist) {
    return this.http.post(this.rooturl1 + "/api/CustomerRegistration/GetOrderDetailsforPayment", mm);
  }
  getpaymentstatus(aa: PayU) {
    return this.http.post(this.rooturl1 + "/api/CustomerRegistration/GetPaymentStatus", aa);
  }
  getAllCustomers() {
    return this.http.get(this.rooturl1 + "/api/AuManagement/GetA_U_Management");
  }
  SaveOrderDetails(aaa: SavepaymentDetails) {
    return this.http.post(this.rooturl1 + "/api/CustomerRegistration/GetOrderPayments", aaa);
  }

  UpdateFolder(data: AddNewFolder) {
    return this.http.post(this.url + "/api/FolderMasters/PostUpdateContent", data);
  }

  addNewFolder(data: AddNewFolder) {
    return this.http.post(this.url + "/api/FolderMasters/PostSaveFolder", data);
  }
  GetAllTransactions(id) {
    return this.http.get(this.url + "/api/Contract/GetTransactions/" + id);
  }
  GetCustomerTransaction() {
    return this.http.get(this.url + "/api/Contract/GetAllTransactions");
  }
  autocall() {
    return this.http.get(this.url + '/api/ShareFilesViaMail/GetCheckChainAsync/');
  }
  getsubfolders(data: IDViewModel) {
    return this.http.post("http://analyticals.co/digitalidentitydmsapi/api/UploadDocument/PostFolderIdGet/", data);
  }
  getbackdata(pid) {
    return this.http.get(this.url + "/api/UploadDocument/GetBackdocument/" + pid);
  }

  deletefolder(aa) {
    return this.http.post(this.url + "/api/FolderMasters/PostDeleteContent", aa);
  }
  getUserforBiometrics() {
    return this.http.get(this.rooturl1 + "api/AuManagement");
  }
  sendBiometrics(bio) {
    return this.http.post(this.rooturl1 + "/api/Biometrics/PostBiometricsAuthentication", bio);
  }
  friendlist(id) {
    return this.http.get(this.url + "/api/FriendLists/GetFriendList/" + id);
  }
  SearchFiles(data: Search) {
    return this.http.post(this.url + "/api/Search/PostSearch", data);
  }
  ShareFiles(data: Share) {
    return this.http.post(this.url + "/api/Contract/ShareFiles", data);
  }
  sharefileviamail(data: ShareFileViaMailVM) {
    return this.http.post(this.url + "/api/ShareFilesViaMail/ShareFilesThroughMail", data);
  }
  getfolders(id) {
    return this.http.get(this.url + "/api/FolderMasters/GetallFolder/" + id);
  }
  verifyPhone(num:BM_MobileOTP){
    return this.http.post(this.rooturl1 + "/api/Signup/MobileValidation", num);
  }
  postVerifyOTP(data:MobileViewModel){
    return this.http.post(this.rooturl1 + "/api/Signup/ForgotPswdOTPValidation", data);
  }
  postchangePassword(pwd:ChangePassword){
    return this.http.put(this.rooturl1 + "/api/Signup/UpdateForgotPassword", pwd);
  }
  postchangePasswordAfterLogin(pwd:ChangePassword){
    return this.http.put(this.rooturl1 + "/api/Signup/PutChangePassword", pwd);
  }
  SaveFrienddetails(data: Friend) {
    return this.http.post(this.url + "/api/FriendLists/PostFriendList", data);
  }
  AcceptReq(f) {
    return this.http.post(this.url + "/api/FriendLists/AcceptRequest", f);
  }
  PlantList() {
    return this.http.get(this.rooturl1 + "/api/PlansTypeMasters/GetPlansTypeMasters");
  }
  SavePlan(data: Plan) {
    return this.http.post(this.planurl + "/api/PlansTypeMasters/PostPlansTypeMaster", data);
  }
  UpdatePlan(id) {
    return this.http.put(this.planurl + "/api/PlansTypeMasters/PutPlansTypeMaster/", id);
  }
  DeletePlan(id) {
    return this.http.delete(this.planurl + "/api/PlansTypeMasters/DeletePlansTypeMaster/" + id);
  }
  getUserDetails(id) {
    return this.http.get(this.rooturl1 + "/api/AuManagement/GetA_U_Management/" + id);
  }
  editUser(user: User) {
    return this.http.put(this.rooturl1 + "/api/Users/PutUser", user);
  }
  getUser() {
    return this.http.get(this.rooturl1 + "/api/AuManagement/GetA_U_Management");
  }
  deleteUser(userID) {
    return this.http.delete(this.rooturl1 + "/api/AuManagement/DeleteA_U_Management/" + userID);
  }
  getProfileDetails(data) {
    return this.http.get(this.rooturl1 + "/api/AuManagement/GetA_U_Management/" + data);
  }
  saveuserChat(ChatDetail) {
    return this.http.post(this.url + "/api/ChatContractController/PostUserChat", ChatDetail);
  }
  GetIndividualChatRecivedDetails(individual: IndividualChatDetails) {
    return this.http.post(this.url + "/api/ChatContractController/RecivedIndividualChatDetails/", individual);
  }
  GetIndividualChatSentDetails(individual: IndividualChatDetails) {
    return this.http.post(this.rooturl1 + "/api/Default/SentIndividualChatDetails/", individual);
  }
  GetAllUsersExceptMe(data: UserId) {
    return this.http.post(this.rooturl1 + "/api/UserChat/GetAllUserExceptMe", data);
  }
  GetToUser(UserId) {
    return this.http.get(this.rooturl1 + "/api/UserChat/GetTouserDetails/"+ UserId);
  }
  getCurrentUSerImage(id) {
    return this.http.get(this.rooturl1 + "/api/UploadImage/" + id);
  }
  GetChatforParticularUser(id) {
    return this.http.get(this.url + "/api/ChatContractController/GetChatmsg/" + id);
  }
  // getallmenus() {
  //   return this.http.get(this.rooturl1 + "/api/User/GetMenuMasters/");
  // }
  // getallmastermenus(roleid) {
  //   return this.http.get(this.rooturl1 + "/api/User/GetMasterMenuData/" + roleid);
  // }
  postImageFile(caption: string, fileupload: File, long: string, lat: string) {
    const url = "http://localhost:15000/api/AuManagement/UploadCustomerImage";
    const formData: FormData = new FormData();
    formData.append("Image", fileupload, fileupload.name);
    formData.append("MenuId", caption);
    formData.append("Longitude", long);
    formData.append("Latitude", lat);
    return this.http.post(url, formData);
  }

  deleteImage(aa) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/DeleteImages", aa);
  }
}
