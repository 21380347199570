import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addmenumaster',
  templateUrl: './addmenumaster.component.html',
  styleUrls: ['./addmenumaster.component.scss']
})
export class AddmenumasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
