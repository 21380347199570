import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IdentityservicesService {
//readonly rooturl1 = "http://localhost:19200";
readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyidusmapi";
constructor(private http: HttpClient) { 

}

postrequestletter(caption: string, fileupload: File) {
  //const url = "http://localhost:19200/api/RequestLetterUpload/UploadRequestLetter";
  const formData: FormData = new FormData();
  formData.append("Image", fileupload, fileupload.name);
  formData.append("MenuId", caption);
  return this.http.post("https://blockchainmatrimony.com/matrimonyidusmapi/api/RequestLetterUpload/UploadRequestLetter", formData);
}

savePreDetails(data){
  return this.http.post(this.rooturl1 + "/api/IdentityForm/PostPreDetails", data);
}

savePersonalDetails(data){
  return this.http.post(this.rooturl1 + "/api/IdentityForm/PostPersonalDetails", data);
}
saveAddressDetails(data){
  return this.http.post(this.rooturl1 + "/api/IdentityForm/PostAddressDetails", data);
}
getSessionDetails(id) {
  return this.http.get(this.rooturl1 + "/api/IdentityForm/GetUserSessionDetail/" + id);
}
saveBiometricDetails(data) {
  return this.http.post(this.rooturl1 + "/api/IdentityForm/PostBiometricDetails" , data);
}
saveDocDetails(data) {
  return this.http.post(this.rooturl1 + "/api/AuManagement/PostDocDetails" , data);
}
}
