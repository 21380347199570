import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RaasiViewModel } from '../ViewModels/RaasiViewModel';

@Injectable({
  providedIn: 'root'
})
export class RaasiService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveRaasi(data: RaasiViewModel) {
    return this.http.post(this.rooturl1 + "/api/RaasiTypeMaster/PostSaveRaasi", data);
  }
  getRaasi() {
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMaster/GetRaasi");
  }
  deleteraasi(id: number) {
    return this.http.delete(this.rooturl1 + "/api/RaasiTypeMaster/DeleteRaasidata/" + id);
  }

  UpdateRaasi(data: RaasiViewModel) {
    return this.http.put(this.rooturl1 + "/api/RaasiTypeMaster/PutRaasi", data);
  }
}
