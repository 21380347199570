import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from 'selenium-webdriver/http';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';

@Component({
  selector: 'app-all-transactions',
  templateUrl: './all-transactions.component.html',
  styleUrls: ['./all-transactions.component.scss']
})
export class AllTransactionsComponent implements OnInit {
  Transcations:any
  totaltrans:any;isLoggedIn:any;uid:any;
  fromdate: Date;
  todate: Date;
  DatedTranscation: any;
  CustomerTranscations: any;
  totalcusttrans: any;
  roleid: string;

  constructor(private userservice:UserServiceService,
    private route : Router) {

      if(localStorage.getItem("IsLoggedIn")=="true")
    {
      this.isLoggedIn=true;
      this.uid = localStorage.getItem("userId");
      this.roleid=localStorage.getItem("role");

      
    }
     }

  ngOnInit() {
    this.GetAllTransactions();
    this.GetCustomerTransaction();
   
  }

  GetAllTransactions(){
    this.userservice.GetAllTransactions(this.uid).subscribe((respose:any) =>{
      this.Transcations = respose;
      this.totaltrans = this.Transcations.madeTrans.length + this.Transcations.recievedTrans.length;
  
  });
  }

  GetCustomerTransaction(){
    this.userservice.GetCustomerTransaction().subscribe((respose:any) =>{
      this.CustomerTranscations = respose;
      this.totalcusttrans = this.CustomerTranscations.length;
  
  });
  }

}
