import { Component, OnInit } from '@angular/core';
import { FoodPreferenceViewModel } from 'src/app/shared/ViewModels/FoodPreferenceViewModel';
import { NgForm } from '@angular/forms';
import { FoodpreferenceService } from 'src/app/shared/foodpreference/foodpreference.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-foodpreference',
  templateUrl: './foodpreference.component.html',
  styleUrls: ['./foodpreference.component.css']
})
export class FoodpreferenceComponent implements OnInit {

  FoodPreferenceList: any;
  foodpreference: any;
  foodpreferenceId: any; genid: any;
  
    constructor(private foodpreferenceService:FoodpreferenceService , private router: Router) { }
  
    ngOnInit() {
      this.FoodPreference();
      this.foodpreference = "";
    }
    ResetData(){
      this.foodpreference = "";
    }
  
  
    FoodPreference() {
      this.foodpreferenceService.getFoodPreference().subscribe((Data:FoodPreferenceViewModel[]) => {
        this.FoodPreferenceList = Data;
      })
    }
  
    SaveFoodPreferenceForm(form: NgForm) {
      const inputRequest: FoodPreferenceViewModel = {
        ID: 0,
        FoodPrefernceType: form.controls["foodpreference"].value,
      }
      this.foodpreferenceService.SaveFoodPreference(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/foodpreference']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/foodpreference']);
          window.location.reload();
        }
      });
    }
  
    editfoodprefernce(data: { foodPrefernceType: any; id: any; }) {
      this.foodpreference = data.foodPrefernceType;
      this.foodpreferenceId = data.id;
    }
  
    UpdateFoodPreference(form: NgForm) {
      const inputRequest1: FoodPreferenceViewModel = {
        ID: this.foodpreferenceId,
        FoodPrefernceType: form.controls["foodpreference"].value
      }
      this.foodpreferenceService.UpdateFoodPreference(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("FoodPreference Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletefoodprefernce(data: { id: any; }) {
      this.genid = data.id;
      this.foodpreferenceService.deletefoodprefernce(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("FoodPreference Deleted Succesfully");
            window.location.reload();
        } else {
            alert("FoodPreference Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

