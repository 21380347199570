import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { HoroscopeService } from 'src/app/shared/Horoscope/horoscope.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HoroscopeViewModel } from 'src/app/shared/ViewModels/HoroscopeViewModel';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Send } from 'src/app/shared/ViewModels/Send';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-horoscope-matching',
  templateUrl: './horoscope-matching.component.html',
  styleUrls: ['./horoscope-matching.component.css']
})
export class HoroscopeMatchingComponent implements OnInit {
  data: any;
  responce: any;
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  brideplanet_positions: any;
  bridegroomplanet_positions: any;
  Item: number;
  aaaa: void;
  returnval: string;
  horoscoresponce: any;


  constructor(private horoscopeservice: HoroscopeService, private httpService: HttpClient, private router: Router,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnInit() {
     this.mapsAPILoader.load().then(() => {
    // //   this.setCurrentLocation();
    // //   this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
     });
  }

  GetLocation() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  GetSecondLocation() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  // markerDragEnd($event: MouseEvent) {
  //   console.log($event);
  //   this.latitude = $event.coords.lat;
  //   this.longitude = $event.coords.lng;
  //   this.getAddress(this.latitude, this.longitude);
  // }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  brideone: any = [];
  bridetwo: any = [];
  bridethree: any = [];
  bridefour: any = [];
  bridefive: any = [];
  bridesix: any = [];
  brideseven: any = [];
  brideeight: any = [];
  bridenine: any = [];
  brideten: any = [];
  brideeleven: any = [];
  bridetwelve: any = [];
  bridegroomone: any = [];
  bridegroomtwo: any = [];
  bridegroomthree: any = [];
  bridegroomour: any = [];
  bridegroomfive: any = [];
  bridegroomsix: any = [];
  bridegroomseven: any = [];
  bridegroomeight: any = [];
  bridegroomnine: any = [];
  bridegroomten: any = [];
  bridegroomeleven: any = [];
  bridegroomtwelve: any = [];


  SaveCasteTypeForm(form: NgForm) {
    const inputRequest: HoroscopeViewModel = {

      // System: "kerala",
      ayanamsa: "1",
      bride_coordinates: this.latitude + "," + this.longitude,
      bride_dob: form.controls["gdate"].value + "T" + form.controls["gtime"].value + ":21" + "+" + "00:00",
      bridegroom_coordinates: this.latitude + "," + this.longitude,
      bridegroom_dob: form.controls["bdate"].value + "T" + form.controls["btime"].value + ":21" + "+" + "00:00",
      //"2004-02-12T15:19:21+00:00",
      //  form.controls["gplace"].value
      // form.controls["bplace"].value
    }

    this.horoscopeservice.getHoroscope(inputRequest).subscribe((data: any) => {
      this.horoscoresponce = data
      if (this.horoscoresponce != null) {
        this.brideplanet_positions = this.horoscoresponce.response.bride_details.planet_positions;
        this.bridegroomplanet_positions = this.horoscoresponce.response.bridegroom_details.planet_positions;

        for (var i = 0; i <= this.brideplanet_positions.length; i++) {
          if (this.brideplanet_positions[i].position == 0) {
            this.Item = this.brideplanet_positions[i]
            this.brideone.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 0) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomone.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 1) {
            this.Item = this.brideplanet_positions[i]
            this.bridetwo.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 1) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomtwo.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 2) {
            this.Item = this.brideplanet_positions[i]
            this.bridethree.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 2) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomthree.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 3) {
            this.Item = this.brideplanet_positions[i]
            this.bridefour.push(this.Item);           
          }
          if (this.bridegroomplanet_positions[i].position == 3) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomour.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 4) {
            this.Item = this.brideplanet_positions[i]
            this.bridefive.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 4) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomfive.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 5) {
            this.Item = this.brideplanet_positions[i]
            this.bridesix.push(this.Item);           
          }
          if (this.bridegroomplanet_positions[i].position == 5) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomsix.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 6) {
            this.Item = this.brideplanet_positions[i]
            this.brideseven.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 6) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomseven.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 7) {
            this.Item = this.brideplanet_positions[i]
            this.brideeight.push(this.Item);           
          }
          if (this.bridegroomplanet_positions[i].position == 7) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomeight.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 8) {
            this.Item = this.brideplanet_positions[i]
            this.bridenine.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 8) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomnine.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 9) {
            this.Item = this.brideplanet_positions[i]
            this.brideten.push(this.Item);           
          }
          if (this.bridegroomplanet_positions[i].position == 9) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomten.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 10) {
            this.Item = this.brideplanet_positions[i]
            this.brideeleven.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 10) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomeleven.push(this.Item);
          }
          if (this.brideplanet_positions[i].position == 11) {
            this.Item = this.brideplanet_positions[i]
            this.bridetwelve.push(this.Item);            
          }
          if (this.bridegroomplanet_positions[i].position == 11) {
            this.Item = this.bridegroomplanet_positions[i]
            this.bridegroomtwelve.push(this.Item);
          }

        }

        // for (var j = 0; j <= this.bridegroomplanet_positions.length; j++) {
        //   if (this.bridegroomplanet_positions[j].position != undefined) {
        //     if (this.bridegroomplanet_positions[j].position == 1) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomone.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 2) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomtwo.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 3) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomthree.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 4) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomour.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 5) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomfive.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 6) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomsix.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 7) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomseven.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 8) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomeight.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 9) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomnine.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 10) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomten.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 11) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomeleven.push(this.Item);
        //     }
        //     if (this.bridegroomplanet_positions[j].position == 12) {
        //       this.Item = this.bridegroomplanet_positions[j]
        //       this.bridegroomtwelve.push(this.Item);
        //     }
        //   }
        // }
      }

    });
  }

  GetBridePlanet() {


    //this.GetBridegroomPlanet()
    return "1";
  }
  GetBridegroomPlanet() {

    return "1";

  }

}
