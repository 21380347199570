import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BiometricDetails } from 'src/app/shared/ViewModels/BiometricDetails';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/IdentityManagement/UserService/user.service';
import { IdentityservicesService } from 'src/app/shared/IdentityManagement/IdentityServices/identityservices.service';

@Component({
  selector: 'app-biometrics',
  templateUrl: './biometrics.component.html',
  styleUrls: ['./biometrics.component.css']
})
export class BiometricsComponent implements OnInit {
  bioval: any;
  userid: any;
  UID: any;
  bioval1: any;
  bid: any;
  purposeOftendigitFP: string;


  constructor(private router: ActivatedRoute, private routerr: Router, private userservice: UserService,private identityService:IdentityservicesService) {
    this.userid = localStorage.getItem("userId");
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.GetDetails(params["id"])
      }
    });
    this.bioval = localStorage.getItem("BValueOne");
    this.bioval1 = localStorage.getItem("BValueTwo");

  }

  ngOnInit() {
  }
  GetDetails(id) {
    this.UID = id;
  }
  OnSubmit(form: NgForm): void {
    this.bioval = localStorage.getItem("BValue");
    // const inputRequest: BiometricDetails = {
    //   Biometricsdata: this.bioval,
    //   userId: this.UID
    // }
    //this.bioval = inputRequest.Biometricsdata
  }

  fnVerify() {
    const inputRequest: BiometricDetails = {
      UserId: this.userid,
      FingerPrintOne: this.bioval,
      FingerPrintTwo: this.bioval1,
      PurposeOfTenFP:this.purposeOftendigitFP
    }
    this.identityService.saveBiometricDetails(inputRequest).subscribe((response: any) => {
      this.bid = response;
      alert("Verified Successfully!!");
    });

  }

}