import { Component, OnInit } from '@angular/core';
import { OccupationService } from 'src/app/shared/occupation/occupation.service';
import { Router } from '@angular/router';
import { OccupationViewModel } from 'src/app/shared/ViewModels/OccupationViewModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-occupation',
  templateUrl: './occupation.component.html',
  styleUrls: ['./occupation.component.css']
})
export class OccupationComponent implements OnInit {

  OccupationList: any;
  occupation: any;
  occupationId: any; genid: any;
  
    constructor(private occupationService:OccupationService , private router: Router) { }
  
    ngOnInit() {
      this. Occupation();
      this.occupation = "";
    }
    ResetData(){
      this.occupation = "";
    }
  
  
    Occupation() {
      this.occupationService.getOccupation().subscribe((Data:  OccupationViewModel[]) => {
        this.OccupationList = Data;
      })
    }
  
    SaveOccupationForm(form: NgForm) {
      const inputRequest: OccupationViewModel = {
        ID: 0,
        OccupationName: form.controls["occupation"].value,
      }
      this.occupationService.saveOccupation(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/occupation']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/occupation']);
          window.location.reload();
        }
      });
    }
  
    editoccupation(data: { occupationName: any; id: any; }) {
      this.occupation = data.occupationName;
      this.occupationId = data.id;
    }
  
    UpdateOccupation(form: NgForm) {
      const inputRequest1:OccupationViewModel = {
        ID: this.occupationId,
        OccupationName: form.controls["occupation"].value,
      }
      this.occupationService.UpdateOccupation(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Occupation Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deleteoccupation(data: { id: any; }) {
      this.genid = data.id;
      this.occupationService.deleteoccupation(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Occupation Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Occupation Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

