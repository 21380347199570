import { Component, OnInit } from '@angular/core';
import { ParticlesConfig } from 'src/assets/js/particles';

declare var particlesJS : any;
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    constructor() { }

    ngOnInit() {
        particlesJS('particles-js', ParticlesConfig, function() {
            console.log('callback - particles.js config loaded');
          });
          var nucleoView = document.getElementsByClassName('icons-container')[0];
          window.addEventListener('scroll', function (event) {
              if (this.isInViewport(nucleoView)) {
                  nucleoView.classList.add('on-screen');
              }
            else{
              nucleoView.classList.remove('on-screen');
            }
          }.bind(this), false);

          let input_group_focus = document.getElementsByClassName('form-control');
          let input_group = document.getElementsByClassName('input-group');
          for (let i = 0; i < input_group.length; i++) {
              input_group[i].children[0].addEventListener('focus', function () {
                  input_group[i].classList.add('input-group-focus');
              });
              input_group[i].children[0].addEventListener('blur', function () {
                  input_group[i].classList.remove('input-group-focus');
              });
          }   
    }
}
