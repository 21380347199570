import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenderViewModel } from '../ViewModels/GenderViewModel';

@Injectable({
  providedIn: 'root'
})
export class GenderService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http:HttpClient) { }

  saveGender(data: GenderViewModel) {
    return this.http.post(this.rooturl1 + "/api/GenderMaster/PostSaveGender", data);
  }
  getGender(){
    return this.http.get(this.rooturl1 +"/api/GenderMaster/GetGenders");
  }
  deleteGender(id: number){
    return this.http.delete(this.rooturl1 +"/api/GenderMaster/DeleteGenderData/"+ id);
  }

  UpdateGender(data:GenderViewModel){
    return this.http.put(this.rooturl1 +"/api/GenderMaster/PutGender",data);
  }
}
