import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FamilyValueViewModel } from '../ViewModels/FamilyValueViewModel';

@Injectable({
  providedIn: 'root'
})
export class FamilyvalueService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveFamilyValue(data: FamilyValueViewModel) {
    return this.http.post(this.rooturl1 + "/api/FamilyValueTypeMaster/PostSaveFamilyValueType", data);
  }
  getFamilyValue() {
    return this.http.get(this.rooturl1 + "/api/FamilyValueTypeMaster/GetFamilyValueType");
  }
  deletefamilyvalue(id: number) {
    return this.http.delete(this.rooturl1 + "/api/FamilyValueTypeMaster/DeleteFamilyValueTypedata/" + id);
  }

  UpdateFamilyValue(data: FamilyValueViewModel) {
    return this.http.put(this.rooturl1 + "/api/FamilyValueTypeMaster/PutFamilyValueType", data);
  }
}
