import { Component, OnInit } from '@angular/core';
import { ContactPersonDetails } from 'src/app/shared/ViewModels/ContactPersonDetails';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'src/app/shared/registration/registration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationprocessService } from 'src/app/shared/Services/Registration/registrationprocess.service';

@Component({
  selector: 'app-brideg-groom-registration',
  templateUrl: './brideg-groom-registration.component.html',
  styleUrls: ['./brideg-groom-registration.component.css']
})
export class BridegGroomRegistrationComponent implements OnInit {

  stateid: any;
  States: any;
  countryid: any;
  Countries: any;
  pincode: any;
  UserID: any;
  serchfind: boolean;
  phovaild: boolean;
  phonetwo: boolean;
  serchfindtwo: boolean;

  constructor(private routernav: ActivatedRoute, private registrationService: RegistrationprocessService, private spinner: NgxSpinnerService, private router: Router) {

    this.routernav.params.subscribe(params => {
      if (params["id"]) {
        this.GetDetails(params["id"])
      }
    });
  }

  GetDetails(id) {
    this.UserID = id;
  }
  ngOnInit() {
    this.GetStates();
    this.GetCountries();
  }

  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  phonevalidation = new RegExp(/^(?:\+?88)?01[15-9]\d{8}$/);
  phone = new RegExp(/^[6-9]\d{9}$/);
  onSelectState(id) {
    this.stateid = id
  }
  GetStates() {
    this.registrationService.GetStates().subscribe((response: any) => {
      this.States = response;
    });
  }

  onSelectCountry(id) {
    this.countryid = id
  }
  GetCountries() {
    this.registrationService.GetCountries().subscribe((response: any) => {
      this.Countries = response;
    });
  }

  OnSubmitSeekerRegistration(form: NgForm) {
    
    this.serchfind = this.regexp.test(form.controls["email"].value);
    // this.serchfindtwo = this.regexp.test(form.controls["secondemail"].value);
    this.phovaild = this.phonevalidation.test(form.controls["mobile"].value);
    this.phonetwo = this.phone.test(form.controls["mobile"].value);

     this.spinner.show();
    
    if (form.controls["cname"].value == null && form.controls["cname"].value == "") {
      alert("Please Enter Your Name")
      this.spinner.hide();
    }
    else if (form.controls["mobile"].value == null && form.controls["mobile"].value == "") {
      alert("Please Provide Us Your Phone Number")
      this.spinner.hide();
    }
    else if (!this.phonetwo) { 
      alert('Please Enter valid Mobile Number');
      this.spinner.hide();
    }
    else if (form.controls["username"].value == null && form.controls["username"].value == "") {
      alert("Please Enter Username")
      this.spinner.hide();
    }
    else if (form.controls["password"].value == null && form.controls["password"].value == "") {
      alert("Please Enter Password")
      this.spinner.hide();
    }
    else if (form.controls["confirmpassword"].value == null && form.controls["confirmpassword"].value == "") {
      alert("Please Enter Confirm Password")
      this.spinner.hide();
    }   
    else if(form.controls["password"].value  != form.controls["confirmpassword"].value){
      alert("Password and Confirm Password not Same")
      this.spinner.hide();
    }
    else if (form.controls["email"].value == null && form.controls["email"].value == "") {
      alert("Please Provide Us Your E-Mail Id")
      this.spinner.hide();
    }
    else if (!this.serchfind) {
      alert('Please Enter valid Email');
      this.spinner.hide();
    }
    // else if (form.controls["secondemail"].value == null && form.controls["secondemail"].value == "") {
    //   alert("Please Provide Us Your E-Mail Id")
    //   this.spinner.hide();
    // }
    // else if (!this.serchfindtwo) {
    //   alert('Please Enter valid Email');
    //   this.spinner.hide();
    // }
    else if (form.controls["addressone"].value == null && form.controls["addressone"].value == "") {
      alert("Please Provide Us Your Residence Address");
      this.spinner.hide();
    }
    else if (form.controls["city"].value == null && form.controls["city"].value == "") {
      alert("Please Provide Us Your City Name");
      this.spinner.hide();
    }
    else if (form.controls["pincode"].value == null && form.controls["pincode"].value == "") {
      alert("Please Provide Pincode");
      this.spinner.hide();
    }
    else {
      const inputRequest: ContactPersonDetails = {
        BrideGroomId: 0,
        Username:form.controls["username"].value,
        Password:form.controls["password"].value,
        confirmpassword:form.controls["confirmpassword"].value,
        ContactPerson: form.controls["cname"].value,
        ContactPersonNumber: form.controls["mobile"].value,
        SecondPhoneNumber: form.controls["mobiletwo"].value,
        MailId: form.controls["email"].value,
        SecondMailId: null,
        AddressOne: form.controls["addressone"].value,
        AddressTwo: form.controls["addresstwo"].value,
        AddressThree: form.controls["addressthree"].value,
        LandMark: form.controls["landmark"].value,
        City: form.controls["city"].value,
        State: this.stateid,
        Country: this.countryid,
        Pincode: form.controls["pincode"].value,
        UserID: this.UserID
      }
      this.registrationService.saveContactPersonDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Registered Your Details Successfully");
          this.router.navigate(['/login']);
          this.spinner.hide();
        }
        else {
          alert("Please Fill Details Properly");
          this.spinner.hide();
        }
      });
    }
  }
}
