import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationprocessService } from 'src/app/shared/Services/Registration/registrationprocess.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { BridegroomService } from 'src/app/shared/Services/BridegroomService/bridegroom.service';
import { NgForm } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-update-preffered-partner-info',
  templateUrl: './update-preffered-partner-info.component.html',
  styleUrls: ['./update-preffered-partner-info.component.css']
})
export class UpdatePrefferedPartnerInfoComponent implements OnInit {
  mothertoungue: any;
  MatrimonyId: any;
  uid: any;
  gendetails: any;
  sociodetails: any;
  educationdetails: any;
  familydetails: any;
  prioritydetails: any;
  heightone: any;
  heighttwo: any;
  ageone: any;
  agetwo: any;
  priorone: any;
  priortwo: any;
  priorthree: any;
  phystatus: any;
  religionId: any;
  Castes: any;
  casteId: any;
  subCastes: any;
  Religions: any;
  SubcasteId: any;
  Raasis: any;
  RaasiId: any;
  Stars: any;
  StarId: any;
  FoodPreferences: any;
  FoodPreferenceId: any;
  Gothrams: any;
  GothramId: any;
  SmokingTypes: any;
  SmokingTypeId: any;
  DrinkingTypeId: any;
  DrinkingTypes: any;
  Mangliks: any;
  ManglikId: any;
  Qualifications: any;
  qualificationid: any;
  Occupations: any;
  FamilyValues: any;
  OccupationId: any;
  FamilyTypes: any;
  FamilyValueId: any;
  FamilyTypeId: any;
  FamilyStatuses: any;
  FamilyStatusId: any;
  SalaryId: any;
  PrefferedSalaries: any;
  physicalstatusid: any;
  PhysicalStatuses: any;
  maritalStatusId: any;
  PerviousMaritalStatus: any;
  stateid: any;
  States: any;
  lookid: any;
  Looks: any;

  dropdownSettingsForLocation = {};
  dropdownSettingsForLooks: {};
  dropdownSettingsForRaasis: {};
  dropdownSettingsForStars: {};
  dropdownSettingsForFoodPreferences: {};
  dropdownSettingsForGothrams: {};
  dropdownSettingsForSubCates: {};
  dropdownSettingsForDrinkingType: {};
  dropdownSettingsForSmokingType: {};
  dropdownSettingsForFamilyType: {};
  dropdownSettingsForFamilyValue: {};
  dropdownSettingsForFamilyStatus: {};




  selectedItemsLocation: any = [];
  selectedItemsRaasi: any = [];
  selectedItemsLooks: any = [];
  selectedItemsStars: any = [];
  selectedItemsFoodPreference: any = [];
  selectedItemsGothrams: any = [];
  selectedItemsSubCates: any = [];
  selectedItemsDrinkingTypes: any = [];
  selectedItemsSmokingTypes: any = [];
  selectedItemsFamilyTypes: any = [];
  selectedItemsFamilyValues: any = [];
  selectedItemsFamilyStatuses: any = [];



  MultipleLooks: any = [];
  MultipleRaasi: any = [];
  Multiplestate: any = [];
  Multiplestar: any = [];
  MultipleFoodPreference: any = [];
  MultipleGothram: any = [];
  MultipleSubCate: any = [];
  MultipleDrinkingType: any = [];
  MultipleDSmokingTypes: any = [];
  MultipleFamilyTypes: any = [];
  MultipleFamilyValues: any = [];
  MultipleFamilyStatuses: any = [];
  allLooks: any;
  allStates: any;
  allStars: any;
  allRaasis: any;
  allSubCates: any;
  allDrinkingTypes: any;
  allSmokingTypes: any;
  allFamilyTypes: any;
  allFamilyValues: any;
  allFamilyStatuses: any;
  allFoodPreferences: any;
  allGothrams: any;
  FPrtmarked: any;
  EPmarked: any;
  SRPmarked: any;
  PFStmarked: any;
  PFVmarked: any;
  PFTmarked: any;
  PSmokingmarked: any;
  PDmarked: any;
  PManglikmarked: any;
  PGmarked: any;
  FPmarked: any;
  Starmarked: any;
  Rmarked: any;
  PSCmarked: any;
  PCmarked: any;
  PRmarked: any;
  Lookmarked: any;
  PSmarked: any;
  Lmarked: any;
  MTmarked: any;
  PMSmarked: any;
  Amarked: any;
  Hmarked: any;
  HQmarked: any;
  Occmarked: any;
  Salarymarked: any;
  maristatus: any;
  prelocation: any;
  religion: any;
  caste: any;
  manglik: any;
  quali: any;
  salary: any;
  job: any;
  list: any;
  sl: any;
  data: any;
  oldLooks: any;
  locationold: any;
  raasiold: any;
  starold: any;
  subcasteold: any;
  foodpreferenceold: any;
  gothramold: any;
  smokingold: any;
  drinkingold: any;
  familytypegold: any;
  familystatusgold: any;
  familyvaluegold: any;



  constructor(private route: ActivatedRoute, private registrationService: RegistrationprocessService, private userService: UserServiceService, private router: Router, private spinner: NgxSpinnerService, private bridegroomService: BridegroomService) {
    this.MatrimonyId = localStorage.getItem('uid');

    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.uid = params["id"];
      }
    });
  }

  ngOnInit() {

    this.GetLooks();
    this.GetgenDetails();
    this.GetSocioDetails();
    this.GetEduDetails();
    this.GetfamiDetails();
    this.GetPriorDetails();

    this.GetMaritalStatus();
    this.GetStates();
    this.GetPhysicalStatusTypes();
    this.GetManglikTypes();
    this.GetGothramTypes();
    this.GetFoodPreferencesTypes();
    this.GetStarsTypes();
    this.GetRaasiTypes();
    this.GetsubCasteTypes();
    this.GetCasteTypes();
    this.GetReligionTypes();
    this.GetFamilyStatusTypes();
    this.GetFamilyTypesTypes();
    this.GetFamilyValuesTypes();
    this.GetOccupationTypes();
    this.GetQualifications();
    this.GetDrinkingTypeTypes();
    this.GetSmokingTypeTypes();

    this.GetSalaryRanges();
    this.dropdownSettingsForLocation = {
      singleSelection: false,
      idField: 'id',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForLooks = {
      singleSelection: false,
      idField: 'id',
      textField: 'lookType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForRaasis = {
      singleSelection: false,
      idField: 'id',
      textField: 'raasiType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForStars = {
      singleSelection: false,
      idField: 'id',
      textField: 'starType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFoodPreferences = {
      singleSelection: false,
      idField: 'id',
      textField: 'foodPrefernceType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForGothrams = {
      singleSelection: false,
      idField: 'id',
      textField: 'gothramName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForSubCates = {
      singleSelection: false,
      idField: 'id',
      textField: 'subCasteType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForDrinkingType = {
      singleSelection: false,
      idField: 'id',
      textField: 'drinkingType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForSmokingType = {
      singleSelection: false,
      idField: 'id',
      textField: 'smokingType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFamilyType = {
      singleSelection: false,
      idField: 'id',
      textField: 'familyType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFamilyValue = {
      singleSelection: false,
      idField: 'id',
      textField: 'familyValueType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsForFamilyStatus = {
      singleSelection: false,
      idField: 'id',
      textField: 'familyStatusType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.spinner.hide();
  }
  HtoggleVisibility(e) {
    this.Hmarked = e.target.checked;
  }
  AtoggleVisibility(e) {
    this.Amarked = e.target.checked;
  }
  PMStoggleVisibility(e) {
    this.PMSmarked = e.target.checked;
  }
  MTtoggleVisibility(e) {
    this.MTmarked = e.target.checked;
  }
  LtoggleVisibility(e) {
    this.Lmarked = e.target.checked;
  }
  PStoggleVisibility(e) {
    this.PSmarked = e.target.checked;
  }
  LooKtoggleVisibility(e) {
    this.Lookmarked = e.target.checked;
  }
  PRtoggleVisibility(e) {
    this.PRmarked = e.target.checked;
  }
  PCtoggleVisibility(e) {
    this.PCmarked = e.target.checked;
  }
  PSCtoggleVisibility(e) {
    this.PSCmarked = e.target.checked;
  }
  RtoggleVisibility(e) {
    this.Rmarked = e.target.checked;
  }
  StartoggleVisibility(e) {
    this.Starmarked = e.target.checked;
  }
  FPtoggleVisibility(e) {
    this.FPmarked = e.target.checked;
  }
  PGtoggleVisibility(e) {
    this.PGmarked = e.target.checked;
  }
  PMangliktoggleVisibility(e) {
    this.PManglikmarked = e.target.checked;
  }
  PDtoggleVisibility(e) {
    this.PDmarked = e.target.checked;
  }
  PSmokingtoggleVisibility(e) {
    this.PSmokingmarked = e.target.checked;
  }
  PFTtoggleVisibility(e) {
    this.PFTmarked = e.target.checked;
  }
  PFVtoggleVisibility(e) {
    this.PFVmarked = e.target.checked;
  }
  PFSttoggleVisibility(e) {
    this.PFStmarked = e.target.checked;
  }
  SRPtoggleVisibility(e) {
    this.SRPmarked = e.target.checked;
  }
  EPtoggleVisibility(e) {
    this.EPmarked = e.target.checked;
  }
  FPrtoggleVisibility(e) {
    this.FPrtmarked = e.target.checked;
  }
  HQtoggleVisibility(e) {
    this.HQmarked = e.target.checked;
  }
  OcctoggleVisibility(e) {
    this.Occmarked = e.target.checked;
  }
  SalarytoggleVisibility(e) {
    this.Salarymarked = e.target.checked;
  }

  onItemSelectFamilyStatus(item: any) {
    this.MultipleFamilyStatuses.push(item);
  }
  onSelectAllFamilyStatuses(items: any) {
    this.allFamilyStatuses = items;
  }
  onItemSelectFamilyValue(item: any) {
    this.MultipleFamilyValues.push(item);
  }
  onSelectAllFamilyValues(items: any) {
    this.allFamilyValues = items;
  }
  onItemSelectFamilyType(item: any) {
    this.MultipleFamilyTypes.push(item);
  }
  onSelectAllFamilyTypes(items: any) {
    this.allFamilyTypes = items;
  }
  onItemSelectSmokingTypes(item: any) {
    this.MultipleDSmokingTypes.push(item);
  }
  onSelectAllSmokingTypes(items: any) {
    this.allSmokingTypes = items;
  }
  onItemSelectDrinkingTypes(item: any) {
    this.MultipleDrinkingType.push(item);
  }
  onSelectAllDrinkingTypes(items: any) {
    this.allDrinkingTypes = items;
  }
  onItemSelectSubCates(item: any) {
    this.MultipleSubCate.push(item);
  }
  onSelectAllSubCates(items: any) {
    this.allSubCates = items;
  }
  onItemSelectGothrams(item: any) {
    this.MultipleGothram.push(item);
  }
  onSelectAllGothrams(items: any) {
    this.allGothrams = items;
  }
  onItemSelectFoodPreferences(item: any) {
    this.MultipleFoodPreference.push(item);
  }
  onSelectAllFoodPreferences(items: any) {
    this.allFoodPreferences = items;
  }
  onItemSelectStars(item: any) {
    this.Multiplestar.push(item);
  }
  onSelectAllStars(items: any) {
    this.allStars = items;
  }
  onItemSelectRaasis(item: any) {
    this.MultipleRaasi.push(item);
  }
  onSelectAllRaasis(items: any) {
    this.allRaasis = items;
  }
  onItemSelectLooks(item: any) {
    this.MultipleLooks.push(item);
  }
  onSelectAllLooks(items: any) {
    this.allLooks = items;
  }
  onItemSelectLocation(item: any) {
    this.Multiplestate.push(item);
  }
  onSelectAllLocations(items: any) {
    this.allStates = items;
  }


  GetgenDetails() {
    this.bridegroomService.GetGendetail(this.MatrimonyId).subscribe((response: any) => {
      this.data = response;
      this.gendetails = response[0];
      this.mothertoungue = this.data.generalDetail.motherToungue;
      this.heightone = this.data.generalDetail.preferredHeightRangeOne;
      this.heighttwo = this.data.generalDetail.preferredHeightRangeTwo;
      this.ageone = this.data.generalDetail.preferredAgeFrom;
      this.agetwo = this.data.generalDetail.preferredAgeTo;
      this.phystatus = this.data.generalDetail.physicalStatus;
      this.GetLooksUpdate(this.data.generalDetail.preferredLooks)
      this.maristatus = this.data.generalDetail.preferredPreviousMaritalStatus;
      this.GetLocationUpdate(this.data.generalDetail.prefferedLocation);
      this.GetRaasiUpdate(this.data.socialReligiousDetails.raasi);
      this.GetStarUpdate(this.data.socialReligiousDetails.star);
      this.GetSubCasteUpdate(this.data.socialReligiousDetails.preferredSubCaste);
      this.GetFoodPreferencesUpdate(this.data.socialReligiousDetails.preferredFoodPreference);
      this.GetGothramUpdate(this.data.socialReligiousDetails.gothram);
      this.GetSmokingUpdate(this.data.socialReligiousDetails.preferredSmoking);
      this.GetDrinkingUpdate(this.data.socialReligiousDetails.preferredDrinking);
       this.GetFamilyTypesUpdate(this.data.familyDetails.preferredFamilyType)
           this.GetFamilyValueUpdate(this.data.familyDetails.preferredFamilyValues)
      this.GetFamilyStatusUpdate(this.data.familyDetails.preferredFamilyStatus)

      this.religion = this.sociodetails.preferredReligion;
      this.caste = this.sociodetails.preferredCaste;
      this.manglik = this.sociodetails.manglik;
      this.quali = this.educationdetails.preferredEducationQualification;
      this.job = this.educationdetails.preferredJob;
      this.salary = this.educationdetails.preferredSalary;

      // this.sl = this.gendetails.preferredLooks.split(",");
      // // this.GetLooks();
      // for (var i=0;i<this.sl.length;i++)
      // {
      //   for (var  k=0;k<this.Looks.length;k++)
      //   if (this.Looks[k].id == this.sl[i])
      //   {
      //     this.selectedItemsLooks.push(this.Looks[k].id);
      //   }
      // }
      //this.selectedItemsLooks=this.gendetails.preferredLooks.split(",");
      //this.GetLooksUpdate()
    });
  }
  onSelectLook(id) {
    this.lookid = id
  }
  GetLooks() {
    this.registrationService.GetLooks().subscribe((response: any) => {
      this.Looks = response;

    });
  }
  GetLooksUpdate(id) {
    this.registrationService.GetLooksid(id).subscribe((response: any) => {
      this.oldLooks = response;
      this.selectedItemsLooks = this.oldLooks;
    });
  }
  GetLocationUpdate(id) {
    this.registrationService.GetLocationid(id).subscribe((response: any) => {
      this.locationold = response;
      this.selectedItemsLocation = this.locationold;
    });
  }
  GetRaasiUpdate(id){
    this.registrationService.GetRaasiid(id).subscribe((response: any) => {
      this.raasiold = response;
      this.selectedItemsRaasi = this.raasiold;
    });

  }
  GetStarUpdate(id){
    this.registrationService.GetStarid(id).subscribe((response: any) => {
      this.starold = response;
      this.selectedItemsStars = this.starold;
    });

  }
  GetSubCasteUpdate(id){
    this.registrationService.GetSubCasteid(id).subscribe((response: any) => {
      this.subcasteold = response;
      this.selectedItemsSubCates = this.subcasteold;
    });
  }
  GetFoodPreferencesUpdate(id){
    this.registrationService.GetFoodPreferenceid(id).subscribe((response: any) => {
      this.foodpreferenceold = response;
      this.selectedItemsFoodPreference = this.foodpreferenceold;
    });

  }
  GetGothramUpdate(id){
    this.registrationService.GetGothramid(id).subscribe((response: any) => {
      this.gothramold = response;
      this.selectedItemsGothrams = this.gothramold;
    });

  }
  GetSmokingUpdate(id){
    this.registrationService.GetSmokingid(id).subscribe((response: any) => {
      this.smokingold = response;
      this.selectedItemsSmokingTypes = this.smokingold;
    });
  }
  GetDrinkingUpdate(id){
    this.registrationService.GetDrinkingid(id).subscribe((response: any) => {
      this.drinkingold = response;
      this.selectedItemsDrinkingTypes = this.drinkingold;
    });
  }
  GetFamilyTypesUpdate(id){
    this.registrationService.GetFamilyTypeid(id).subscribe((response: any) => {
      this.familytypegold = response;
      this.selectedItemsFamilyTypes = this.familytypegold;
    });

  }
  GetFamilyStatusUpdate(id){
    this.registrationService.GetFamilyStatusid(id).subscribe((response: any) => {
      this.familystatusgold = response;
      this.selectedItemsFamilyStatuses = this.familystatusgold;
    });
  }
  GetFamilyValueUpdate(id){
    this.registrationService.GetFamilyValueid(id).subscribe((response: any) => {
      this.familyvaluegold = response;
      this.selectedItemsFamilyValues = this.familyvaluegold;
    });
  }
  GetSocioDetails() {
    this.bridegroomService.GetSoReligio(this.MatrimonyId).subscribe((response: any) => {
      this.sociodetails = response[0];
      this.religion = this.sociodetails.preferredReligion;
      this.caste = this.sociodetails.preferredCaste;
      this.manglik = this.sociodetails.manglik;

    });
  }
 
  GetEduDetails() {
    this.bridegroomService.GetEdu(this.MatrimonyId).subscribe((response: any) => {
      this.educationdetails = response[0];
      this.quali = this.educationdetails.preferredEducationQualification;
      this.job = this.educationdetails.preferredJob;
      this.salary = this.educationdetails.preferredSalary;
    });
  }
  GetfamiDetails() {
    this.bridegroomService.GetFami(this.MatrimonyId).subscribe((response: any) => {
      this.familydetails = response;
    });
  } onSelectSalary(id) {
    this.SalaryId = id
  }
  GetSalaryRanges() {
    this.bridegroomService.GetSalaryRanges().subscribe((response: any) => {
      this.PrefferedSalaries = response;
    });
  }
  onSelectFamilyStatus(id) {
    this.FamilyStatusId = id
  }
  GetFamilyStatusTypes() {
    this.bridegroomService.GetFamilyStatusesTypes().subscribe((response: any) => {
      this.FamilyStatuses = response;
    });
  }
  onSelectFamilyType(id) {
    this.FamilyTypeId = id
  }
  GetFamilyTypesTypes() {
    this.bridegroomService.GetFamilyTypeTypes().subscribe((response: any) => {
      this.FamilyTypes = response;
    });
  }
  onSelectFamilyValue(id) {
    this.FamilyValueId = id
  }
  GetFamilyValuesTypes() {
    this.bridegroomService.GetFamilyValueTypes().subscribe((response: any) => {
      this.FamilyValues = response;
    });
  }
  onSelectOccupation(id) {
    this.OccupationId = id
  }
  GetOccupationTypes() {
    this.bridegroomService.GetOccupationTypes().subscribe((response: any) => {
      this.Occupations = response;
    });
  }
  onSelectQualifications(id) {
    this.qualificationid = id
  }
  GetQualifications() {
    this.registrationService.GetQualifications().subscribe((response: any) => {
      this.Qualifications = response;
    });
  }
  onSelectManglik(id) {
    this.ManglikId = id
  }
  GetManglikTypes() {
    this.registrationService.GetManglikTypes().subscribe((response: any) => {
      this.Mangliks = response;
    });
  }
  onSelectDrinkingType(id) {
    this.DrinkingTypeId = id
  }
  GetDrinkingTypeTypes() {
    this.registrationService.GetDrinkingTypeTypes().subscribe((response: any) => {
      this.DrinkingTypes = response;
    });
  }
  onSelectSmokingeType(id) {
    this.SmokingTypeId = id
  }
  GetSmokingTypeTypes() {
    this.registrationService.GetSmokingTypeTypes().subscribe((response: any) => {
      this.SmokingTypes = response;
    });
  }
  onSelectGothram(id) {
    this.GothramId = id
  }
  GetGothramTypes() {
    this.registrationService.GetGothramTypes().subscribe((response: any) => {
      this.Gothrams = response;
    });
  }

  onSelectFoodPreferences(id) {
    this.FoodPreferenceId = id
  }
  GetFoodPreferencesTypes() {
    this.registrationService.GetFoodPreferenceTypes().subscribe((response: any) => {
      this.FoodPreferences = response;
    });
  }
  onSelecStar(id) {
    this.StarId = id
  }
  GetStarsTypes() {
    this.bridegroomService.GetStarTypes().subscribe((response: any) => {
      this.Stars = response;
    });
  }
  onSelectRaasi(id) {
    this.RaasiId = id
  }
  GetRaasiTypes() {
    this.registrationService.GetRaasiTypes().subscribe((response: any) => {
      this.Raasis = response;
    });
  }
  onSelectSubCaste(id) {
    this.SubcasteId = id
  }
  GetsubCasteTypes() {
    this.bridegroomService.GetsubCasteTypes().subscribe((response: any) => {
      this.subCastes = response;
    });
  }
  onSelectCaste(id) {
    this.casteId = id
  }
  GetCasteTypes() {
    this.bridegroomService.GetCasteTypes().subscribe((response: any) => {
      this.Castes = response;
    });
  }
  onSelectReligion(id) {
    this.religionId = id
  }
  GetReligionTypes() {
    this.registrationService.GetreligionTypes().subscribe((response: any) => {
      this.Religions = response;
    });
  }
  onSelectPhysicalStatus(id) {
    this.physicalstatusid = id
  }
  GetPhysicalStatusTypes() {
    this.registrationService.GetphysicalStatusTypes().subscribe((response: any) => {
      this.PhysicalStatuses = response;
    });
  }
  onSelectPreviousMaritalStatus(id) {
    this.maritalStatusId = id
  }
  GetMaritalStatus() {
    this.registrationService.GetPreviousMaritalStatus().subscribe((response: any) => {
      this.PerviousMaritalStatus = response;
    });
  }
  onSelectState(id) {
    this.stateid = id
  }
  GetStates() {
    this.registrationService.GetStates().subscribe((response: any) => {
      this.States = response;
    });
  }


  GetPriorDetails() {
    this.bridegroomService.GetPrior(this.MatrimonyId).subscribe((response: any) => {
      this.prioritydetails = response[0];
      this.priorone = this.prioritydetails.socioReligious;
      this.priortwo = this.prioritydetails.education;
      this.priorthree = this.prioritydetails.family;

    });
  }
  OnSubmitPreferredBrideGroomRegistration(form: NgForm) {
    if (form.controls["mothertoungue"].value == null && form.controls["mothertoungue"].value == "") {
      alert("Please Enter Your Mother Toungue")
    }
    else {
      MotherToungue: form.controls["mothertoungue"].value
    }
  }

}
