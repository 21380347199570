import { Component, OnInit } from '@angular/core';
import { StarViewModel } from 'src/app/shared/ViewModels/StarViewModel';
import { NgForm } from '@angular/forms';
import { StarService } from 'src/app/shared/star/star.service';
import { Router } from '@angular/router';
import { RaasiService } from 'src/app/shared/raasi/raasi.service';
import { RaasiViewModel } from 'src/app/shared/ViewModels/RaasiViewModel';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css']
})
export class StarComponent implements OnInit {
  StarList: any;
  star: any;
  starId: any; genid: any;
  
  RaasiList: RaasiViewModel[];
  
    constructor(private starService:StarService ,private rassiService:RaasiService, private router: Router) { }
  
    ngOnInit() {
      this.Star();
      this.Raasi();
      this.star = "";
    }
    ResetData(){
      this.star = "";
    }
    Raasi() {
      this.rassiService.getRaasi().subscribe((Data: RaasiViewModel[]) => {
        this.RaasiList = Data;
      })
    }
  
  
    Star() {
      this.starService.getStar().subscribe((Data: StarViewModel[]) => {
        this.StarList = Data;
      })
    }
  
    SaveStarForm(form: NgForm) {
      const inputRequest: StarViewModel = {
        ID: 0,
        RaasiType:this.Raasi,
        StarType: form.controls["star"].value,
        
      }
      this.starService.saveStar(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/star']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/star']);
          window.location.reload();
        }
      });
    }
  
    editstar(data: { starType: any; id: any; }) {
      this.star = data.starType;
      this.starId = data.id;
    }
  
    UpdateStar(form: NgForm) {
      const inputRequest1: StarViewModel = {
        ID: this.starId,
        RaasiType:this.Raasi,

        StarType: form.controls["star"].value,
      }
      this.starService.UpdateStar(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Star Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletestar(data: { id: any; }) {
      this.genid = data.id;
      this.starService.deletestar(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Star Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Star Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
      }
      onSelectRaasi(id) {
          this.Raasi = id
    }
  
  }
  
  
    

  