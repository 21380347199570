import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { AddNewFolder } from 'src/app/shared/ViewModels/AddNewFolder';
import { FolderviewService } from 'src/app/shared/FlderViewService/folderview.service';
import { Search } from 'src/app/shared/ViewModels/Search';
import { UserInfoOne } from 'src/app/shared/ViewModels/UserInfoOne';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllfoldersService } from 'src/app/shared/Services/AllFolders/allfolders.service';
import { NgForm } from '@angular/forms';
import { AllFolders } from 'src/app/shared/ViewModels/AllFolders';
import { AllfilesService } from 'src/app/shared/Services/AllFiles/allfiles.service';
import { MoveFolderOrDocument } from 'src/app/shared/ViewModels/MoveFolderOrDocument';
import { MoveService } from 'src/app/shared/Services/Move/move.service';

@Component({
  selector: 'app-allfolders',
  templateUrl: './allfolders.component.html',
  styleUrls: ['./allfolders.component.scss']
})
export class AllfoldersComponent implements OnInit {
  uid: any;
  allFolders: any;
  subf: any;
  allSubFolders: any;
  folderid: any;
  FolderName: any;
  roleid: any;
  ParentId: any;
  Id: any;
  search: any;
  SearchResult: any;
  public SearchEnabled: boolean = false;

  shareitem: any;
  CurrentFiles: any;
  remove: boolean;
  StoreFiles: any[];
  storedData: any;
  destinationFolder: any;
  getMoveData: any;
  sourceFolder: any;
  pid: any;
  selected = [];
  moveitem: any;
  Enablemove: any;
  CreatedOn: any;
  CusId: any;
  auid: any;


  constructor(private allfolderservice: AllfoldersService, private router: Router,
    private spinner: NgxSpinnerService, private allfilesservice: AllfilesService,
    private moveService: MoveService) {

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.auid = localStorage.getItem("userId");
      this.uid = localStorage.getItem("uid")
      //this.moveitem = localStorage.getItem("moveitemtomainfol")

    }
    
    this.allfolderservice.getfolders(this.uid).subscribe((respose: any) => {
      this.allFolders = respose;
      this.SearchEnabled = false;
      this.spinner.hide();
    });
  }
  GetAllFolders() {
    this.spinner.show();
    this.allfolderservice.getfolders(this.uid).subscribe((respose: any) => {
      this.allFolders = respose;
      this.SearchEnabled = false;
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.shareitem = true;
  }
  Share() {
    this.shareitem = true;
  }
  CancelShare() {
    this.shareitem = false;
  }
  MoveTo(data: any) {
    this.shareitem = true;
    // this.moveitem = "true";
    // this.Enablemove = true
    this.storedData = data;
    localStorage.setItem("MoveData", JSON.stringify(data));
  }

  MoveDash1(data: any) {
    this.shareitem = true;
    var getdata = localStorage.getItem("MoveData");
    this.getMoveData = JSON.parse(getdata);
    // this.moveitem = "true";
    // this.Enablemove = true;
    this.destinationFolder = 0;
    this.sourceFolder = this.getMoveData.FolderID;
    const inputRequest: MoveFolderOrDocument = {
      SourceId: this.sourceFolder,
      DestinationId: this.destinationFolder
    }
    this.moveService.saveMoveFolderOrDocument(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Moved Successfully");
        this.pid = data.ParentID;
        this.GetAllFolders();
        this.spinner.hide();
      }
      else {
        alert("Something went Wrong");
        this.GetAllFolders();
        this.spinner.hide();
      }
    });
  }

  MoveDash() {
    this.StoreFiles = this.selected;
  }

  toggle(data) {
    this.CurrentFiles = data;
    if (data != null) {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].Id == data.Id) {
          var index = this.selected.indexOf(data);
          this.selected.splice(index, 1);
          this.remove = true;
        }
      }
      if (this.remove == false) {
        this.selected.push(data);
      }
    }
    this.remove = false;
  }

  SaveNewFolder(form: NgForm) {
    const inputRequest: AllFolders = {
      Id: 0,
      FolderID: "0",
      RoleId: null,
      FolderName: form.controls["FolderName"].value,
      UserId: this.uid,
      Code: "Folder",
      ParentID: "0",
    }
    this.spinner.show();
    this.allfolderservice.addNewFolder(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Folder Added Succesfully");
          this.spinner.hide();
          this.GetAllFolders();
        }
      });
  }

  // Get all subfolders
  GetSubfolder(data) {
    this.subf = data.FolderName;
    
    this.router.navigate(['subfolders/' + data.FolderID]);
  }

  //EDIT FOLDERS
  EditFolder(folderview) {
    this.Id = folderview.Id;
    this.folderid = folderview.FolderID;
    this.FolderName = folderview.FolderName;
    this.ParentId = folderview.ParentID;
  }

  UpdateFolder(form: NgForm) {
    const inputRequest1: AllFolders = {
      FolderID: this.folderid,
      FolderName: form.controls["FolderName"].value,
      Code: "Folder",
      Id: this.Id,
      ParentID: this.ParentId,
      RoleId: 0,
      UserId: this.uid
    }
    this.spinner.show();
    this.allfolderservice.UpdateFolderName(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("Folder Name Updated Succesfully");
          this.spinner.hide();
          this.GetAllFolders();
        } else {
          alert("Something Went Wrong. Try again.!!");
          this.spinner.hide();
          this.GetAllFolders();
        }
      });
  }

  //DELETE FOLDERS
  Delete(data) {
    var res;
    if (data.FolderName == null) {
      res = confirm("Do You Want to Delete " + data.DocumentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.FolderName + "!!!");
    }
    if (res == true) {
      if (data.Hash) {
        var info = {
          Id: data.Id,
          FolderID: data.FolderId,
          FolderName: data.FolderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.FolderId,
          RoleId: null,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    else {
      this.spinner.hide();
      this.GetAllFolders();
    }
    this.spinner.show();
    this.allfilesservice.deletefolder(info).subscribe((data: any) => {
      alert("Deleted Successfully");
      this.spinner.hide();
      this.GetAllFolders();
    });
  }


  //SEARCH BAR
  onSearch(searchValue: string) {
    //this.spinner.show();
    this.search = searchValue;
    if (this.search.length != 0) {
      this.SearchEnabled = true;
    }
    else {
      this.SearchEnabled = false;
    }
    if (this.search.length > 2) {
      const inputRequest: Search = {
        Value: this.search,
        UserId: this.uid
      }
      this.spinner.show();
      this.allfolderservice.GetSearchedFiles(inputRequest).subscribe((data: any) => {
        if (data.Documents.length != 0 || data.Folders.length != 0) {
          this.SearchResult = data;
          this.spinner.hide();
        }
        else {
          alert("No Results Found");
          this.spinner.hide();
          this.GetAllFolders();
        }
      });
    }
  }


  Details(data: any) {

    this.FolderName = data.FolderName;
    this.CreatedOn = data.CreatedOn;
    this.CusId = data.UserId;
    //this.Type = "Folder";
  }




}
