import { Component, OnInit } from '@angular/core';
import { CompanyCustomerReg } from 'src/app/shared/ViewModels/CompanyCustomerReg';
import { NgForm } from '@angular/forms';
import { StudentCustomerReg } from 'src/app/shared/ViewModels/StudentCustomerReg';
import { IndividualCustomerReg } from 'src/app/shared/ViewModels/IndividualCustomerReg';

import { Router } from '@angular/router';
import { CustomerregistrationService } from 'src/app/shared/IdentityManagement/CustomerRegistration/customerregistration.service';
import { UserService } from 'src/app/shared/IdentityManagement/UserService/user.service';

@Component({
  selector: 'app-customerregistration',
  templateUrl: './customerregistration.component.html',
  styleUrls: ['./customerregistration.component.css']
})
export class CustomerregistrationComponent implements OnInit {
  test: Date = new Date();
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  phonevalidation = new RegExp(/^(?:\+?88)?01[15-9]\d{8}$/);

  individualcustomerregistration: boolean;//1
  companycustomerregistration: boolean;//2
  studentcustomerrefistration: boolean;//3

  selectindividual: boolean;
  selectcompany: boolean;
  selectstudent: boolean;
  ind: any; cmp: any; stu: any;
  firstname: any;  PayuPlans: any;
  lastname: any;
  mobile: number;
  email: any;
  serchfind: any;
  phovaild: any; genderid: any;
  Genders: any;
  qualificationid: any;  userid: string;
  Qualifications: any;

  constructor(private userService: UserService, private customerregistrationService: CustomerregistrationService, private router: Router) { 
    this.userid = localStorage.getItem("userId"); 

  }

  ngOnInit() {

    this.individualcustomerregistration = false;
    this.companycustomerregistration = false;
    this.studentcustomerrefistration = false;

    this.GetGenders();
    this.GetQualifications();

    this.stu = true;
    this.cmp = true;
    this.ind = true;

  }

  GetSelectedRegistration(id) {
    if (id == "Individual") {
      this.ind = true;
      this.stu = false;
      this.cmp = false;

      this.selectindividual = true;
      this.selectcompany = false;
      this.selectstudent = false;
    }
    if (id == "Company") {
      this.selectcompany = true;
      this.selectindividual = false;
      this.selectstudent = false;

      this.ind = false;
      this.stu = false;
      this.cmp = true;

    }
    if (id == "Student") {
      this.ind = false;
      this.stu = true;
      this.cmp = false;

      this.selectstudent = true;
      this.selectcompany = false;
      this.selectindividual = false;
    }

  }
  GetFriendDetails(){
    this.userService.getPayuPlans().subscribe((respose:any) =>{
      this.PayuPlans = respose;    
  });
}
  //IndividualCustomerDetails #1
  OnSubmitIndividualCustomer(form: NgForm) {

    this.serchfind = this.regexp.test(form.controls["email"].value);
    this.phovaild = this.regexp.test(form.controls["mobile"].value);

    if (form.controls["fname"].value == null || form.controls["fname"].value == "") {
      alert("Please Enter Your FirstName");
    }
    else if (form.controls["lname"].value == null || form.controls["lname"].value == "") {
      alert("Please Enter Your LastName");
    }
    else if (form.controls["uname"].value == null || form.controls["uname"].value == "") {
      alert("Please Enter Your UserName");
    }
    else if (form.controls["email"].value == null || form.controls["email"].value == "") {
      alert("Please Enter Your Email");
    }
    else if (!this.serchfind) {
      alert('Please Enter valid email');
    }
    else if (form.controls["mobile"].value == null || form.controls["mobile"].value == "") {
      alert("Please Enter Your Phone Number");
    }
    else if (form.controls["mobile"].value.length <= 9 || form.controls["mobile"].value.length > 10) {
      alert("Please Enter valid Number");
    }
    else {
      const inputRequest: IndividualCustomerReg = {
        Id: 0,
        UserId:null,
        FirstName: form.controls["fname"].value,
        LastName: form.controls["lname"].value,
        UserName: form.controls["uname"].value,
        Email: form.controls["email"].value,
        PhoneNumber: form.controls["mobile"].value,
        Landline: form.controls["landline"].value,
        Gender: this.genderid,
        Qualification: this.qualificationid,
        Purpose: form.controls["purpose"].value,
        Source: form.controls["source"].value,
        Address:form.controls["address"].value
      }
      this.customerregistrationService.saveIndividualCustomerDetails(inputRequest).subscribe((data: any) => {
        console.log(data)
        if (data != null) {
          alert("Registered Successfully");
          this.GetFriendDetails();
           this.router.navigate(['/payuplans', data]);
        }
        else {
          alert("Something went Wrong");
        }
      });
    }
  }

  //StudentCustomerDetails #2
  OnSubmitStudentCustomer(form: NgForm) {

    this.serchfind = this.regexp.test(form.controls["email"].value);
    this.phovaild = this.regexp.test(form.controls["mobile"].value);

    if (form.controls["fname"].value == null || form.controls["fname"].value == "") {
      alert("Please Enter Your FirstName");
    }
    else if (form.controls["lname"].value == null || form.controls["lname"].value == "") {
      alert("Please Enter Your LastName");
    }
    else if (form.controls["uname"].value == null || form.controls["uname"].value == "") {
      alert("Please Enter Your UserName");
    }
    else if (form.controls["email"].value == null || form.controls["email"].value == "") {
      alert("Please Enter Your Email");
    }
    else if (!this.serchfind) {
      alert('Please Enter valid email');
    }
    else if (form.controls["mobile"].value == null || form.controls["mobile"].value == "") {
      alert("Please Enter Your Phone Number");
    }
    else if (form.controls["mobile"].value.length <= 9 || form.controls["mobile"].value.length > 10) {
      alert("Please Enter valid Number");
    }
    else {
      const inputRequest: StudentCustomerReg = {
        Id: 0,
        UserId:this.userid,
        FirstName: form.controls["fname"].value,
        LastName: form.controls["lname"].value,
        UserName: form.controls["uname"].value,
        Email: form.controls["email"].value,
        PhoneNumber: form.controls["mobile"].value,
        Landline: form.controls["landline"].value,
        Gender: this.genderid,
        CurrentPursuing: this.qualificationid,
        Purpose: form.controls["purpose"].value,
        Source: form.controls["source"].value,
        Address:form.controls["address"].value
      }
      this.customerregistrationService.saveStudentCustomerDetails(inputRequest).subscribe((data: any) => {
        console.log(data)
        if (data != null) {
          alert("Registered Successfully");
          this.router.navigate(['/login']);
        }
        else {
          alert("Something went Wrong");
        }
      });
    }
  }

  //CompanyCustomerDetails #3
  OnSubmitCompanyCustomer(form: NgForm) {

    this.serchfind = this.regexp.test(form.controls["officialemail"].value);

    if (form.controls["companyname"].value == null || form.controls["companyname"].value == "") {
      alert("Please Enter Your Company Name");
    }
    else if (form.controls["companycategory"].value == null || form.controls["companycategory"].value == "") {
      alert("Please Enter Your Public or Private");
    }
    else if (form.controls["uname"].value == null || form.controls["uname"].value == "") {
      alert("Please Enter Your UserName");
    }
    else if (form.controls["website"].value == null || form.controls["website"].value == "") {
      alert("Please Enter Your Website");
    }
    else if (form.controls["officialemail"].value == null || form.controls["officialemail"].value == "") {
      alert("Please Enter Your Official Email");
    }
    else if (!this.serchfind) {
      alert('Please Enter valid email');
    }
    else {
      const inputRequest: CompanyCustomerReg = {
        Id: 0,
        UserId:this.userid,
        CompanyName: form.controls["companyname"].value,
        CompanyCategory: form.controls["companycategory"].value,
        UserName: form.controls["uname"].value,
        OfficialEmail: form.controls["officialemail"].value,
        Mobile: form.controls["website"].value,
        Landline: form.controls["landline"].value,
        Purpose: form.controls["purpose"].value,
        Source: form.controls["source"].value,
        Address:form.controls["address"].value,
        Website:form.controls["website"].value
      }
      this.customerregistrationService.saveCompanyCustomerDetails(inputRequest).subscribe((data: any) => {
        console.log(data)
        if (data != null) {
          alert("Registered Successfully");
          this.router.navigate(['/login']);
        }
        else {
          alert("Something went Wrong");
        }
      });
    }
  }

  onSelectGenders(id) {
    this.genderid = id
  }
  GetGenders() {
    this.userService.GetGenders().subscribe((response: any) => {
      this.Genders = response;
    });
  }

  onSelectQualifications(id) {
    this.qualificationid = id
  }
  GetQualifications() {
    this.userService.GetQualifications().subscribe((response: any) => {
      this.Qualifications = response;
    });
  }
}
