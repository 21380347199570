import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { SignupService } from '../shared/Services/SignUp/signup.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MobileVM } from '../shared/ViewModels/MobileVM';
import { MobileViewModel } from '../shared/ViewModels/MobileViewModel';
import { NgForm } from '@angular/forms';
import { InitialDetails } from '../shared/ViewModels/InitialDetails';
import { Subject, Observable } from '../../../node_modules/rxjs';
import { WebcamImage } from 'ngx-webcam';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    test: Date = new Date();
    focus;
    focus1;
    focus2;
    ImageFile:any;
    enableOTP: boolean;
    mobileNo: any;
    IsConfirm:any;
    enableMobileVerify: boolean;
    Showform: boolean;
    serchfind: boolean;
    
    phovaild: boolean;
    Seekers: any;
    Seekid: any;
    phonetwo: any;
    userID: any;
    confirm: boolean;
    identityverification: boolean;
    sigres: any;
    signatureverfiyid: Object;
    SignatureFile: any;
    SignatureFileTwo: any;
    imageres: Object;
    imageverfiyid: Object;
    signatureImage: any;
    sigimg: any;
    choosesign: boolean;
    uploadign: boolean;
    drawsign: boolean;
    userimage: any;
    uimg: any;
    imageurltwo: string | ArrayBuffer;
    files2: any;
    imageChangedEvent: any;
    croppedImage: any;
    signatureurltwo: string | ArrayBuffer;
    signatureurl: string | ArrayBuffer;
    imageurl: string | ArrayBuffer;
    SelectedFiles: string;
    files1: any;
    
    constructor(private signupservice: SignupService, private spinner: NgxSpinnerService, private router: Router,private httpService: HttpClient,) { }

    signupForm = new FormGroup({
        Email: new FormControl(),
        Password: new FormControl(),
        UserName: new FormControl(),
        MobileNumber: new FormControl(),
        AddressOne: new FormControl(),
        Addresstwo: new FormControl(),
        ZipCode: new FormControl(),
        Landmark: new FormControl(),
        DOB: new FormControl(),
    });


    ngOnInit() {
        this.enableOTP = false;
        this.Showform = false;
        this.enableMobileVerify = true;
        this.GetSeekers();
        setTimeout(() => {
            if (document.getElementById("sign")) {
              var w = document.getElementById("signf").offsetWidth;
              var a = document.querySelector("canvas");
              console.log(w)
              a.width = w;
              a.height = 150;
              a.style.border = "1px dotted #1e5ee5";
            }
            if (document.querySelector('video')) {
              document.querySelector('video').style.width = "100%";
              document.querySelector('video').style.height = "100%";
      
            }
          }, 1000);
    }

    SendOtp(form: NgForm) {
        this.spinner.show();
        const inputRequest: MobileVM = {
            Mobile: form.controls["Mobile"].value,
        }
        this.signupservice.SendOtp(inputRequest).subscribe(
            (data: any) => {
                if (data != null) {
                    this.mobileNo = data;
                    alert("Please Follow");
                    this.enableOTP = true;
                    this.enableMobileVerify = false;
                    this.Showform = false;
                    this.spinner.hide();

                } else {
                    alert("Something Went Wrong. Try again.!!");
                    this.spinner.hide();
                    window.location.reload();
                }
            });
    }
    onSelectIsConfirm(event) {
        if (event.target.checked) {
          this.confirm = true;
          this.identityverification=true;
        }
        else {
          this.confirm = false;
          this.identityverification=false;
        }
      }
    VerifyOTP(form: NgForm) {
        this.spinner.show();
        const inputRequest: MobileViewModel = {
            CustomerId: null,
            CustomerName: null,
            Email: null,
            MobileNumber: this.mobileNo,
            Otp: form.controls["otp"].value,
            Subject: null
        }
        this.signupservice.postVerifyOTP(inputRequest).subscribe(
            (data: any) => {
                if (data ==  1) {
                    alert("Your Otp is Succesfully Verified");
                    this.enableOTP = false;
                    this.enableMobileVerify = false;
                    this.Showform = true;
                    this.spinner.hide();

                } 
                else if (data ==  1) {
                    alert("Please Enter Cottect OTP");
                    this.enableOTP = false;
                    this.enableMobileVerify = false;
                    this.Showform = true;
                    this.spinner.hide();
                }
                else{
                    alert("Something Went Wrong. Try again.!!");
                    this.spinner.hide();
                    window.location.reload();
                }
            });
    }
    onSelectSeeking(id) {
        this.Seekid = id
    }
    GetSeekers() {
        this.signupservice.GetSeekersData().subscribe((response: any) => {
            this.Seekers = response;
        });
    }
    regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    phonevalidation = new RegExp(/^(?:\+?88)?01[15-9]\d{8}$/);
    phone = new RegExp(/^[6-9]\d{9}$/);
    OnSubmitInitialDetails(form: NgForm) {

        // this.serchfind = this.regexp.test(form.controls["email"].value);
        // this.phovaild = this.phonevalidation.test(form.controls["mobile"].value);
        // this.phonetwo = this.phone.test(form.controls["mobile"].value);

        this.spinner.show();

        // this.Mobile = this.regexp.test(form.controls["mobile"].value);
        // this.Email = this.regexp.test(form.controls["email"].value);
        // this.fName = form.controls["fname"].value;
        // this.lName = form.controls["lastname"].value;
        // this.Mobilenumber = form.controls["mobile"].value;
        // this.EmailID = form.controls["email"].value;
        // this.Mnumbe = form.controls["mobile"].value.length

        if (form.controls["fname"].value == null || form.controls["fname"].value == "") {
            alert("Please Enter First Name");
            this.spinner.hide();
        }
        else if (form.controls["lastname"].value == null || form.controls["lastname"].value == "") {
            alert("Please Enter Last Name");
            this.spinner.hide();
        }
        // else if (form.controls["mobile"].value == null || form.controls["mobile"].value == "") {
        //     alert("Please Enter Your Phone Number");
        //     this.spinner.hide();
        // }
        // else if (form.controls["mobile"].value.length <= 9 || form.controls["mobile"].value.length > 10) {
        //     alert("Please Enter valid Mobile Number");
        //     this.spinner.hide();
        // }
        // else if (!this.phonetwo) {
        //     alert('Please Enter valid Mobile Number');
        //     this.spinner.hide();
        // }
        else if (form.controls["email"].value == null || form.controls["email"].value == "") {
            alert("Please Enter Your Email");
            this.spinner.hide();
        }
        // else if (!this.serchfind) {
        //     alert('Please Enter valid Email');
        //     this.spinner.hide();
        // }
        else {
            const inputRequest: InitialDetails = {
                Id: 0,
                FirstName: form.controls["fname"].value,
                LastName: form.controls["lastname"].value,
                SeekingMatchFor: this.Seekid,
                PrefixCountryCode: null,
                PhoneNumber: this.mobileNo,
                Email: form.controls["email"].value
            }
            this.signupservice.saveInitialRegDetails(inputRequest).subscribe((data: any) => {
                if (data != null) {
                    alert("Enrolled Successfully");
                    this.userID = data
                    this.router.navigate(['/applicantInfo', data]);
                    this.spinner.hide();
                }
                else {
                    alert("Please Fill Details Properly");
                    this.spinner.hide();
                }
            });
        }
    }

    onChange(event: any) {
        var fileslist = "";
        this.files1 = [].slice.call(event.target.files);
        console.log(this.files1);
        fileslist = this.files1[0];
    
        this.SelectedFiles = fileslist;
        localStorage.setItem("validateval", "true")
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();
    
          reader.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader.onload = (event) => { // called once readAsDataURL is completed
            //this.imageurl = event.target.result;
          }
        }
      }
    
      onChangeforSignature(event: any) {
        var fileslistone = "";
        this.files2 = [].slice.call(event.target.files);
        console.log(this.files2);
        fileslistone = this.files2[0];
    
        this.SignatureFile = fileslistone;
        localStorage.setItem("validateval", "true");
        localStorage.setItem("validatesign", "false");
        if (event.target.files && event.target.files[0]) {
          var reader1 = new FileReader();
    
          reader1.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader1.onload = (event) => { // called once readAsDataURL is completed
          //  this.signatureurl = event.target.result;
          }
        }
      }
      onChangeforSignatureTwo(event: any) {
        var fileslistone = "";
        this.files2 = [].slice.call(event.target.files);
        console.log(this.files2);
        fileslistone = this.files2[0];
    
        this.SignatureFileTwo = fileslistone;
        localStorage.setItem("validateval", "true")
        if (event.target.files && event.target.files[0]) {
          var reader1 = new FileReader();
    
          reader1.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader1.onload = (event) => { // called once readAsDataURL is completed
          //  this.signatureurltwo = event.target.result;
          }
        }
      }
    
     
    
      onChangeforImage(event: any) {
        document.getElementById("openpopup").click();
        this.imageChangedEvent= event;
        var fileslistone = "";
        this.files2 = [].slice.call(event.target.files);
        console.log(this.files2);
        fileslistone = this.files2[0];
    
        this.ImageFile = fileslistone;
        this.SignatureFileTwo = fileslistone;
        localStorage.setItem("validateval", "true")
        localStorage.setItem("userverification", "false");
        if (event.target.files && event.target.files[0]) {
          var reader1 = new FileReader();
    
          reader1.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader1.onload = (event) => { // called once readAsDataURL is completed
           // this.imageurltwo = event.target.result;
          }
        }
      }
      handleFileInput1(files: File) {
        this.uimg = files[0];
      }
      public seconds: number;
      private trigger: Subject<void> = new Subject<void>();
    
      // latest snapshot
      public webcamImage: WebcamImage = null;
    
      public triggerSnapshot(): void {
        this.spinner.show()
        this.seconds = 3;
        setTimeout(() => {
          this.seconds = 2;
          setTimeout(() => {
            this.seconds = 1
            setTimeout(() => {
              this.trigger.next();
              this.seconds = null;
            }, 2000)
          }, 2000)
        }, 2000)
        this.spinner.hide();
    
      }
    
      public handleImage(webcamImage: WebcamImage): void {
        console.info("received webcam image", webcamImage);
        this.webcamImage = webcamImage;
        this.userimage = this.webcamImage.imageAsBase64;
        console.log(webcamImage);
      }
    
      public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
      }
    
    
    
      showImage(data) {
        this.drawsign = true;
        this.choosesign = false;
        this.uploadign = false;
    
        this.sigimg = data.split(',')[1];
        console.log(this.sigimg);
        this.signatureImage = data;
    
      }
      verifyimage() {
        const frmData = new FormData();
        frmData.append("image", this.ImageFile);
        frmData.append("capimg", this.webcamImage.imageAsBase64);
    
        this.spinner.show();
        // this.httpService.post('https://blockchainmatrimony.com/finspaceapi/api/User/verifyimage/', frmData).subscribe(
        this.httpService.post('https://blockchainmatrimony.com/finspaceapi/api/User/verifyimage/', frmData).subscribe(
          data => {
    
            if (data != 0) {
              this.imageverfiyid = data
              this.httpService.get('https://blockchainmatrimony.com/flaskapp/outputfin/' + data).subscribe(
                data => {
                  localStorage.setItem("validateval", "true");
                  localStorage.setItem("userverification", "true");
                  console.log("Python Response Data",data)
                  this.imageres = data;
                  alert("Verified Successfully!!");
                  this.spinner.hide();
                },
                (err: HttpErrorResponse) => {
                  // Show error, if any.
               //this.getimgverifieddetail(data);
                  localStorage.setItem("validateval", "true");
                  localStorage.setItem("userverification", "false");
    
              });
            }
            else {
              alert("Faces do not match!! Something went wrong. Please try again..!! ");
            }
          },
          
          (err: HttpErrorResponse) => {
            alert("Something went wrong. Please try again..!! ")
            console.log(err.message);    // Show error, if any.
          });
    
    
      }

    
      verifysignature() {
        if (this.SignatureFile != null && this.SignatureFileTwo != null) {
    
          var uid = localStorage.getItem("userId");
          const frmData = new FormData();
          frmData.append("userId", uid);
    
          frmData.append("fileUpload", this.SignatureFile);
          frmData.append("fileUploadTwo", this.SignatureFileTwo);
          // frmData.append("capimg", this.sigimg);
    
          this.spinner.show();
          //this.httpService.post('https://blockchainmatrimony.com/finspaceapi/api/User/verifysignature/', frmData).subscribe(
            this.httpService.post('https://blockchainmatrimony.com/finspaceapi/api/User/verifysignature/', frmData).subscribe(
              data => {
    
              //call flask api just like face recognition
              if (data != 0) {
                this.signatureverfiyid = data
                this.httpService.get('https://blockchainmatrimony.com/flaskapp/sigmatchfin/' + data).subscribe(
                  resp => {
                    localStorage.setItem("validateval", "true");
                    localStorage.setItem("validatesign", "true")
                    console.log("Python Response Data",resp)
                    this.sigres = resp;
                    alert("Verified Successfully!!");
                    this.spinner.hide();
    
                  },
                  (err: HttpErrorResponse) => {
                      // Show error, if any.
                  // this.getsignverifieddetail(data);
                   localStorage.setItem("validatesign", "false");
                      localStorage.setItem("validateval", "true")
                      this.spinner.hide();
                  });
                  
              }
              else {
                alert("Signature did not match..!! ");
              }
            },
            (err: HttpErrorResponse) => {
              alert("Something went wrong. Please try again..!! ");
              console.log(err.message);    // Show error, if any.
            });
    
        }
        else {
          alert("Select your image and Sign");
        }
      }
}
