import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/shared/country/country.service';
import { Router } from '@angular/router';
import { CountryViewModel } from 'src/app/shared/ViewModels/CountryViewModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
  CountryList: any;
  country: any;
  countryId: any;
  Id: any;
  
    constructor(private countryService:CountryService , private router: Router) { }
  
    ngOnInit() {
      this.Country();
      this.country = "";
    }
    ResetData(){
      this.country = "";
    }
  
    Country() {
      this.countryService.getCountry().subscribe((Data: CountryViewModel[]) => {
        this.CountryList = Data;
      })
    }
  
    SaveCountryForm(form: NgForm) {
      const inputRequest: CountryViewModel = {
        CountryId: 0,
        CountryName: form.controls["country"].value,
      }
      this.countryService.saveCountry(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/country']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/country']);
        }
      });
    }
  
    editcountry(data: { countryName: any; countryId: any; }) {
      this.country = data.countryName;
      this.countryId = data.countryId;
    }
  
    UpdateCountry(form: NgForm) {
      const inputRequest1: CountryViewModel = {
        CountryId: this.countryId,
        CountryName: form.controls["country"].value,
      }
      this.countryService.UpdateCountry(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Country Updated Succesfully");
            this.Country(); 
          } else {
            alert("Something Went Wrong. Try again.!!");
            this.Country();          }
        });
    }
    deletecountry(data: { countryId: any; }) {
      this.Id = data.countryId;
      this.countryService.deletecountry(this.Id).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Country Deleted Succesfully");
            window.location.reload();
          } else {
            alert("Country Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  
  
  
    

  
