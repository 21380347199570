import { Component, OnInit } from '@angular/core';
import { User } from '../user.model';
import { Router } from '@angular/router';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  users : User[];
  constructor(private userService:UserServiceService,private router : Router) { }

  ngOnInit() {
    this.loadusers();
  }

  loadusers(){
    this.userService.getUser().subscribe((respose:User[]) =>{
      this.users = respose
     console.log("user",this.users)
    });
  }

  DeleteUser(userID){
    this.userService.deleteUser(userID).subscribe((data:any)=>{
      this.loadusers();
    });
     }
     
     add(){
      this.router.navigate(['adduser']);
    }
  
    Redirect(id)
    {
      this.router.navigate(['edituser/'+ id]); 
    }

}
