import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TypeOfBodyViewModel } from '../ViewModels/TypeOfBodyViewModel';


@Injectable({
  providedIn: 'root'
})
export class TypeofbodyService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveTypeOfBody(data: TypeOfBodyViewModel) {
    return this.http.post(this.rooturl1 + "/api/TypeOfBody/PostSaveBodyType", data);
  }
  getTypeOfBody() {
    return this.http.get(this.rooturl1 + "/api/TypeOfBody/GetBodyType");
  }
  deletetypeofbody(id: number) {
    return this.http.delete(this.rooturl1 + "/api/TypeOfBody/DeleteBodyTypeData/" + id);
  }

  UpdateTypeOfBody(data: TypeOfBodyViewModel) {
    return this.http.put(this.rooturl1 + "/api/TypeOfBody/PutBodyType", data);
  }
}

 