import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateViewModel } from '../ViewModels/StateViewModel';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveState(data: StateViewModel) {
    return this.http.post(this.rooturl1 + "/api/StateMaster/PostSaveState", data);
  }
  getState() {
    return this.http.get(this.rooturl1 + "/api/StateMaster/GetState");
  }
  deletestate(id: number) {
    return this.http.delete(this.rooturl1 + "/api/StateMaster/DeleteStateData/" + id);
  }

  UpdateState(data: StateViewModel) {
    return this.http.put(this.rooturl1 + "/api/StateMaster/PutState", data);
  }
}