import { Component, OnInit } from '@angular/core';
import { TypeofbodyService } from 'src/app/shared/typeofbody/typeofbody.service';
import { Router } from '@angular/router';
import { TypeOfBodyViewModel } from 'src/app/shared/ViewModels/TypeOfBodyViewModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-typeofbody',
  templateUrl: './typeofbody.component.html',
  styleUrls: ['./typeofbody.component.css']
})
export class TypeofbodyComponent implements OnInit {

 
  TypeOfBodyList: any;
    typeofbody: any;
    typeofbodyId: any; genid: any;
  
    constructor(private typeofbodyService:TypeofbodyService, private router: Router) { }
  
    ngOnInit() {
      this.TypeOfBody();
      this.typeofbody= "";
    }
    ResetData(){
      this.typeofbody = "";
    }
  
    TypeOfBody() {
      this.typeofbodyService.getTypeOfBody().subscribe((Data:TypeOfBodyViewModel[]) => {
        this.TypeOfBodyList = Data;
      })
    }
  
    SaveTypeOfBodyForm(form: NgForm) {
      const inputRequest: TypeOfBodyViewModel = {
        ID: 0,
        TypeOfBody: form.controls["typeofbody"].value,
      }
      this.typeofbodyService.saveTypeOfBody(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/typeofbody']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/typeofbody']);
        }
      });
    }
  
    edittypeofbody(data: { typeOfBody: any; id: any; }) {
      this.typeofbody = data.typeOfBody;
      this.typeofbodyId = data.id;
    }
  
    UpdateTypeOfBody(form: NgForm) {
      const inputRequest1: TypeOfBodyViewModel = {
        ID: this.typeofbodyId,
        TypeOfBody: form.controls["typeofbody"].value,
      }
      this.typeofbodyService.UpdateTypeOfBody(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("TypeOfBody Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletetypeofbody(data: { id: any; }) {
      this.genid = data.id;
      this.typeofbodyService.deletetypeofbody(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("TypeOfBody Deleted Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  