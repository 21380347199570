import { Component, OnInit } from '@angular/core';
import { GenderViewModel } from 'src/app/shared/ViewModels/GenderViewModel';
import { NgForm } from '@angular/forms';
import { GenderService } from 'src/app/shared/gender/gender.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.css']
})
export class GenderComponent implements OnInit {

  GenderList: any;
  genderName: any;
  genderId: any; genid: any;

  constructor(private genderService:GenderService , private router: Router) { }

  ngOnInit() {
    this.Genders();
    this.genderName = "";
  }
  ResetData(){
    this.genderName = "";
  }

  Genders() {
    this.genderService.getGender().subscribe((Data: GenderViewModel[]) => {
      this.GenderList = Data;
    })
  }

  SaveGenderForm(form: NgForm) {
    const inputRequest: GenderViewModel = {
      ID: 0,
      Gender: form.controls["genderName"].value,
    }
    this.genderService.saveGender(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Added Successfully");
        this.router.navigate(['/gender']);
      }
      else {
        alert("Something went Wrong");
        this.router.navigate(['/gender']);
      }
    });
  }

  editgender(data: { gender: any; id: any; }) {
    this.genderName = data.gender;
    this.genderId = data.id;
  }
  

  UpdateGender(form: NgForm) {
    const inputRequest1: GenderViewModel = {
      ID: this.genderId,
      Gender: form.controls["genderName"].value,
    }
    this.genderService.UpdateGender(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("Gender Updated Succesfully");
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }
  
  deletegender(data: { id: any; }) {
    this.genid = data.id;
    this.genderService.deleteGender(this.genid).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Gender Deleted Succesfully");
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }

}
