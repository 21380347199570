import { Component, OnInit } from '@angular/core';
import { StateViewModel } from 'src/app/shared/ViewModels/StateViewModel';
import { NgForm } from '@angular/forms';
import { StateService } from 'src/app/shared/state/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

  
  StateList: any;
  state: any;
  stateId: any; genid: any;
  
    constructor(private stateService:StateService , private router: Router) { }
  
    ngOnInit() {
      this.State();
      this.state = "";
    }
    ResetData(){
      this.state = "";
    }
  
    State() {
      this.stateService.getState().subscribe((Data: StateViewModel[]) => {
        this.StateList = Data;
      })
    }
  
    SaveStateForm(form: NgForm) {
      const inputRequest: StateViewModel = {
        ID: 0,
        StateName: form.controls["state"].value,
      }
      this.stateService.saveState(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/state']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/state']);
        }
      });
    }
  
    editstate(data: { stateName: any; id: any; }) {
      this.state = data.stateName;
      this.stateId = data.id;
    }
  
    UpdateState(form: NgForm) {
      const inputRequest1: StateViewModel = {
        ID: this.stateId,
        StateName: form.controls["state"].value,
      }
      this.stateService.UpdateState(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("State Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletestate(data: { id: any; }) {
      this.genid = data.id;
      this.stateService.deletestate(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("State Deleted Succesfully");
            window.location.reload();
          } else {
            alert("State Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  