import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZodiacMasterViewModel } from '../ViewModels/ZodiacMasterViewModel';

@Injectable({
  providedIn: 'root'
})
export class ZodiacService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  SaveZodiac(data: ZodiacMasterViewModel) {
    return this.http.post(this.rooturl1 + "/api/ZodiacSignMaster/PostSaveZodiac", data);
  }
  getZodiac() {
    return this.http.get(this.rooturl1 + "/api/ZodiacSignMaster/GetZodiac");
  }
  deletezodiac(id: number) {
    return this.http.delete(this.rooturl1 + "/api/ZodiacSignMaster/DeleteZodiacdata/" + id);
  }

  UpdateZodiac(data: ZodiacMasterViewModel) {
    return this.http.put(this.rooturl1 + "/api/ZodiacSignMaster/PutZodiac", data);
  }
}