import { Component, OnInit } from '@angular/core';
import { SalaryViewModel } from 'src/app/shared/ViewModels/SalaryViewModel';
import { NgForm } from '@angular/forms';
import { SalaryService } from 'src/app/shared/salary/salary.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.css']
})
export class SalaryComponent implements OnInit {


  SalaryList: any;
  salary: any;
  salaryId: any; genid: any;
  
    constructor(private salaryService:SalaryService , private router: Router) { }
  
    ngOnInit() {
      this. Salary();
      this.salary = "";
    }
    ResetData(){
      this.salary = "";
    }
  
    Salary() {
      this.salaryService.getSalary().subscribe((Data:  SalaryViewModel[]) => {
        this.SalaryList = Data;
      })
    }
  
    SaveSalaryForm(form: NgForm) {
      const inputRequest: SalaryViewModel = {
        ID: 0,
        SalaryRange: form.controls["salary"].value,
      }
      this.salaryService.saveSalary(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/salary']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/salary']);
          window.location.reload();
        }
      });
    }
  
    editsalary(data: { salaryRange: any; id: any; }) {
      this.salary = data.salaryRange;
      this.salaryId = data.id;
    }
  
    UpdateSalary(form: NgForm) {
      const inputRequest1:SalaryViewModel = {
        ID: this.salaryId,
        SalaryRange: form.controls["salary"].value,
      }
      this.salaryService.UpdateSalary(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Salary Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletesalary(data: { id: any; }) {
      this.genid = data.id;
      this.salaryService.deletesalary(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Salary Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Salary Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

