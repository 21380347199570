import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccupationViewModel } from '../ViewModels/OccupationViewModel';

@Injectable({
  providedIn: 'root'
})
export class OccupationService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveOccupation(data: OccupationViewModel) {
    return this.http.post(this.rooturl1 + "/api/OccupationMaster/PostSaveOccupation", data);
  }
  getOccupation() {
    return this.http.get(this.rooturl1 + "/api/OccupationMaster/GetOccupation");
  }
  deleteoccupation(id: number) {
    return this.http.delete(this.rooturl1 + "/api/OccupationMaster/DeleteOccupationdata/" + id);
  }

  UpdateOccupation(data: OccupationViewModel) {
    return this.http.put(this.rooturl1 + "/api/OccupationMaster/PutOccupation", data);
  }
}