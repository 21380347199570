import { Injectable } from '@angular/core';
import { DrinkingViewModel } from '../ViewModels/DrinkingViewModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DrinkingService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveDrinking(data: DrinkingViewModel) {
    return this.http.post(this.rooturl1 + "/api/DrinkigTypeMaster/PostSaveDrinking", data);
  }
  getDrinking() {
    return this.http.get(this.rooturl1 + "/api/DrinkigTypeMaster/GetDrinking");
  }
  deletedrinking(id: number) {
    return this.http.delete(this.rooturl1 + "/api/DrinkigTypeMaster/DeleteDrinkingdata/" + id);
  }

  UpdateDrinking(data: DrinkingViewModel) {
    return this.http.put(this.rooturl1 + "/api/DrinkigTypeMaster/PutDrinking", data);
  }
}


 