import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllFolders } from '../../ViewModels/AllFolders';
import { Search } from '../../ViewModels/Search';

@Injectable({
  providedIn: 'root'
})
export class AllfoldersService {

  readonly url = "https://blockchainmatrimony.com/matrimonydms";

  //readonly url = "http://analyticals.co/haspitdmsapi";

 constructor(private http: HttpClient) { }
 getfolders(id) {
   return this.http.get(this.url + "/api/FolderCreation/GetallFolder/" + id);
 }
 
 addNewFolder(data: AllFolders) {
   return this.http.post(this.url + "/api/FolderCreation/PostFolderMaster", data);
 }
 UpdateFolderName(data){
   return this.http.put(this.url + "/api/FolderCreation/PutFolderName" , data);
 }
 deletefolder(aa) {
   return this.http.post(this.url + "/api/FolderCreation/PostDeleteContent", aa);
 }

 GetSearchedFiles(data:Search){
   return this.http.post(this.url + "/api/Search/PostSearch", data);
 }
}
