import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeightViewModel } from '../ViewModels/HeightViewModel';

@Injectable({
  providedIn: 'root'
})
export class HeightService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveHeight(data: HeightViewModel) {
    return this.http.post(this.rooturl1 + "/api/HeightMaster/PostSaveHeight", data);
  }
  getHeight() {
    return this.http.get(this.rooturl1 + "/api/HeightMaster/GetHeight");
  }
  deleteheight(id: number) {
    return this.http.delete(this.rooturl1 + "/api/HeightMaster/DeleteHeightdata/" + id);
  }

  UpdateHeight(data: HeightViewModel) {
    return this.http.put(this.rooturl1 + "/api/HeightMaster/PutHeight", data);
  }
}