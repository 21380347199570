import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  readonly url = "http://localhost:8538";


  readonly planurl = "http://localhost:8538"


  constructor(private http: HttpClient) {

  }

  userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded;', 'No-Auth': 'True' });
    return this.http.post(this.rooturl1 + '/token', data, { headers: reqHeader });
  }

  regStage(uid) {
    return this.http.get(this.rooturl1 + '/api/AuManagement/GetRegistrationStage/' + uid);
  }

  selectMenubyMenuId(usrole) {
    return this.http.get(this.rooturl1 + '/api/MenuMaster/GetMenubyUserRole/' + usrole);
  }

  selectMenubyUserRoleDetails(usrole) {
    return this.http.get(this.rooturl1 + '/api/MenuMaster/GetMenubyUserRole/' + usrole);
  }

}
