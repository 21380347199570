import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { FillDetailsBrideGroomComponent } from './Registration/fill-details-bride-groom/fill-details-bride-groom.component';
import { HttpClientModule } from '@angular/common/http';
import { BridegGroomRegistrationComponent } from './Registration/brideg-groom-registration/brideg-groom-registration.component';

import { TandCComponent } from './Registration/tand-c/tand-c.component';
import { AboutAttributesInfoComponent } from './Registration/about-attributes-info/about-attributes-info.component';
import { UploadpictureComponent } from './Registration/uploadpicture/uploadpicture.component';

import { CastetypeComponent } from './Masters/castetype/castetype.component';
import { CountryComponent } from './Masters/country/country.component';
import { ComplextionComponent } from './Masters/complextion/complextion.component';
import { CurrencyComponent } from './Masters/currency/currency.component';
import { DesignationComponent } from './Masters/designation/designation.component';
import { DrinkingComponent } from './Masters/drinking/drinking.component';
import { EmployedinComponent } from './Masters/employedin/employedin.component';
import { FamilystatusComponent } from './Masters/familystatus/familystatus.component';
import { FamilytypeComponent } from './Masters/familytype/familytype.component';
import { FamilyvalueComponent } from './Masters/familyvalue/familyvalue.component';
import { FoodpreferenceComponent } from './Masters/foodpreference/foodpreference.component';
import { GanaComponent } from './Masters/gana/gana.component';
import { StarComponent } from './Masters/star/star.component';
import { RaasiComponent } from './Masters/raasi/raasi.component';
import { GenderComponent } from './Masters/gender/gender.component';
import { GothramComponent } from './Masters/gothram/gothram.component';
import { HeightComponent } from './Masters/height/height.component';
import { LookComponent } from './Masters/look/look.component';
import { ManglikComponent } from './Masters/manglik/manglik.component';
import { MaritalstatusComponent } from './Masters/maritalstatus/maritalstatus.component';
import { NoofsiblingsComponent } from './Masters/noofsiblings/noofsiblings.component';
import { OccupationComponent } from './Masters/occupation/occupation.component';
import { PhysicalstatusComponent } from './Masters/physicalstatus/physicalstatus.component';
import { QualificationComponent } from './Masters/qualification/qualification.component';
import { RoleComponent } from './Masters/role/role.component';
import { SalaryComponent } from './Masters/salary/salary.component';
import { SmokingComponent } from './Masters/smoking/smoking.component';
import { StateComponent } from './Masters/state/state.component';
import { SubcasteComponent } from './Masters/subcaste/subcaste.component';
import { TypeofbodyComponent } from './Masters/typeofbody/typeofbody.component';
import {WebcamModule} from 'ngx-webcam';
import { PreferredPartnerInfoComponent } from './Registration/preferred-partner-info/preferred-partner-info.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { YourMatchesComponent } from './your-matches/your-matches.component';
import { PayuComponent } from './USRM/payu/payu.component';
import { UserlistComponent } from './USRM/User/userlist/userlist.component';
import { AddmenumasterComponent } from './USRM/MenuMaster/addmenumaster/addmenumaster.component';
import { EditmenumasterComponent } from './USRM/MenuMaster/editmenumaster/editmenumaster.component';
import { MenumasterlistComponent } from './USRM/MenuMaster/menumasterlist/menumasterlist.component';
import { AllfoldersComponent } from './USRM/AllFolders/allfolders/allfolders.component';
import { AllfilesComponent } from './USRM/AllFiles/allfiles.component';
import { AddnewfolderComponent } from './USRM/addnewfolder/addnewfolder.component';
import { ImageviewpopupComponent } from './USRM/imageviewpopup/imageviewpopup.component';
import { AdduserComponent } from './USRM/User/adduser/adduser.component';
import { EdituserComponent } from './USRM/User/edituser/edituser.component';
import { FriendsComponent } from './USRM/friends/friends.component';
import { TransactionComponent } from './USRM/transaction/transaction.component';
import { AllTransactionsComponent } from './USRM/all-transactions/all-transactions.component';
import { PayuPlansComponent } from './USRM/payu-plans/payu-plans.component';
import { PlanComponent } from './USRM/plan/plan.component';
import { UserProfileComponent } from './USRM/user-profile/user-profile.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SaveuserchatComponent } from './saveuserchat/saveuserchat.component';
import { FullDetailsComponent } from './full-details/full-details.component';
import { ZodiacComponent } from './Masters/zodiac/zodiac.component';
import { UpdateBrideGroomDetailsComponent } from './UpdateProfile/update-bride-groom-details/update-bride-groom-details.component';
import { ForgotPasswordComponent } from './USRM/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './USRM/change-password/change-password.component';
import { UpdateAboutAttributesInfoComponent } from './UpdateProfile/update-about-attributes-info/update-about-attributes-info.component';
import { UpdatePictureComponent } from './UpdateProfile/update-picture/update-picture.component';
import { SubfoldersComponent } from './USRM/subfolders/subfolders.component';
import { CustomerregistrationComponent } from './IdentityManagement/customerregistration/customerregistration.component';
import { IdentityRegistrationFormComponent } from './IdentityManagement/identity-registration-form/identity-registration-form.component';
import { AdminModuleComponent } from './IdentityManagement/admin-module/admin-module.component';
import { BiometricsComponent } from './IdentityManagement/biometrics/biometrics.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ProvidingServicesComponent } from './ProvidingServices/providing-services/providing-services.component';
import { HoroscopeMatchingComponent } from './Horoscope/horoscope-matching/horoscope-matching.component';
import { UpdatePrefferedPartnerInfoComponent } from './UpdateProfile/update-preffered-partner-info/update-preffered-partner-info/update-preffered-partner-info.component';
import { AgmCoreModule } from '@agm/core';
import { UploadSignaturesComponent } from './USRM/upload-signatures/upload-signatures/upload-signatures.component';
import { CustomerReportComponent } from './USRM/customer-report/customer-report/customer-report.component';
import { DIMAllFoldersComponent } from './USRM/DIMAllFolders/dimall-folders/dimall-folders.component';
import { RequestMsgComponent } from './request-msg/request-msg.component';
import { LogintoSeeProfileComponent } from './loginto-see-profile/loginto-see-profile.component';

import { SignaturePadModule } from '@ng-plus/signature-pad';



@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    UserlistComponent,
    AddmenumasterComponent,
    EditmenumasterComponent,
    MenumasterlistComponent,
    AllfoldersComponent,
   AllfilesComponent,
   AddnewfolderComponent,
   ImageviewpopupComponent,
   AdduserComponent,
   EdituserComponent,
   FriendsComponent,
   TransactionComponent,
   AllTransactionsComponent,
   PayuComponent,
   PayuPlansComponent,
   PlanComponent,
   UserProfileComponent,
  //  CustomerregistrationComponent,
  //  BiometricsComponent,
  //  AdminModuleComponent,
   UserlistComponent,
   
    FillDetailsBrideGroomComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    BridegGroomRegistrationComponent,
    TandCComponent,
    AboutAttributesInfoComponent,
    UploadpictureComponent,
    CastetypeComponent,
    CountryComponent,
    ComplextionComponent,
    CurrencyComponent,
    DesignationComponent,
    DrinkingComponent,
    EmployedinComponent,
    FamilystatusComponent,
    FamilytypeComponent,
    FamilyvalueComponent,
    FoodpreferenceComponent,
    GanaComponent,
    StarComponent,
    RaasiComponent,
    GenderComponent,
    GothramComponent,
    HeightComponent,
    LookComponent,
    ManglikComponent,
    MaritalstatusComponent,
    NoofsiblingsComponent,
    OccupationComponent,
    PhysicalstatusComponent,
    QualificationComponent,
    RoleComponent,
    SalaryComponent,
    SmokingComponent,
    StateComponent,
    SubcasteComponent,
    TypeofbodyComponent,
    PreferredPartnerInfoComponent,
    YourMatchesComponent,    
    PayuComponent, SaveuserchatComponent, FullDetailsComponent, ZodiacComponent,
    UpdateBrideGroomDetailsComponent, UpdateAboutAttributesInfoComponent, UpdatePictureComponent, SubfoldersComponent,
    PayuComponent, SaveuserchatComponent, FullDetailsComponent, UpdateBrideGroomDetailsComponent, 
    UpdateAboutAttributesInfoComponent, UpdatePictureComponent, AdminModuleComponent, 
    BiometricsComponent, 
    CustomerregistrationComponent, IdentityRegistrationFormComponent, ProvidingServicesComponent, HoroscopeMatchingComponent,
    CustomerregistrationComponent, IdentityRegistrationFormComponent, ProvidingServicesComponent, UpdatePrefferedPartnerInfoComponent, UploadSignaturesComponent, CustomerReportComponent, DIMAllFoldersComponent, RequestMsgComponent, LogintoSeeProfileComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    WebcamModule,

    HomeModule,
    HttpClientModule,
    NgxSpinnerModule,
    WebcamModule,
    PdfViewerModule,
    WebcamModule,
    SignaturePadModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDocViewerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBlD_9z9fYHm0AGnlDkEeEC1_AFjSVDnmw',
      libraries: ['places']
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
