import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CasteTypeViewModel } from '../ViewModels/CasteTypeViewModel';

@Injectable({
  providedIn: 'root'
})
export class CastetypeService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveCasteType(data: CasteTypeViewModel) {
    return this.http.post(this.rooturl1 + "/api/Caste/PostSaveCasteType", data);
  }
  getCasteType() {
    return this.http.get(this.rooturl1 + "/api/Caste/GetCasteType");
  }
  deletecastetype(id: number) {
    return this.http.delete(this.rooturl1 + "/api/Caste/DeleteCasteTypedata/" + id);
  }

  UpdateCasteType(data: CasteTypeViewModel) {
    return this.http.put(this.rooturl1 + "/api/Caste/PutCasteType", data);
  }
}


  

 