import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactPersonDetails } from '../../ViewModels/ContactPersonDetails';
import { BrideGroomDetails } from '../../ViewModels/BrideGroomDetails';
import { AboutBrideGroomDetails } from '../../ViewModels/AboutBrideGroomDetails';

@Injectable({
  providedIn: 'root'
})
export class RegistrationprocessService {
  GetLocations() {
    throw new Error("Method not implemented.");
  }
  
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  readonly url = "http://localhost:8538";


  readonly planurl = "http://localhost:8538"


  constructor(private http: HttpClient) {

  }
  GetLooks() {
    return this.http.get(this.rooturl1 + "/api/LookTypeMasters/GetLookTypeMasters");
  }
  GetLooksid(id) {
    return this.http.get(this.rooturl1 + "/api/LookTypeMasters/GetOldLooks/"+id);
  }
  GetLocationid(id) {
    return this.http.get(this.rooturl1 + "/api/LookTypeMasters/GetOldLocation/"+id);
  }
  GetStates() {
    return this.http.get(this.rooturl1 + "/api/StateMasters/GetStateMasters");
  }
  GetCountries() {
    return this.http.get(this.rooturl1 + "/api/CountryMasters/GetCountryMasters");
  }
  saveContactPersonDetails(data:ContactPersonDetails){
    return this.http.post(this.rooturl1 +"/api/BrideGroomDetails/PostContactPersonDetails", data);
  }
  saveBrideGrromCompleteDetails(data: BrideGroomDetails) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostBrideGroomCompleteDetails", data);
  }
  GetSalaryRanges() {
    return this.http.get(this.rooturl1 + "/api/SalaryRangeMasters/GetSalaryRangeMasters");
  }
  GetDrinkingTypeTypes() {
    return this.http.get(this.rooturl1 + "/api/DrinkingTypeMasters/GetDrinkingTypeMasters");
  }
  GetDrinkingid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldDrinking/"+id);

  }
  GetSmokingTypeTypes() {
    return this.http.get(this.rooturl1 + "/api/SmokingTypeMasters/GetSmokingTypeMasters");
  }
  GetSmokingid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldSmoking/"+id);
  }

  
  GetPreviousMaritalStatus() {
    return this.http.get(this.rooturl1 + "/api/PreviousMaritalStatusTypeMasters/GetPreviousMaritalStatusTypeMasters");
  }

  GetComplextions() {
    return this.http.get(this.rooturl1 + "/api/ComplextionTypeMasters/GetComplextionTypeMasters");
  }
  GetHeights() {
    return this.http.get(this.rooturl1 + "/api/HeightMaster/GetHeight");
  }

  GetBodyTypes() {
    return this.http.get(this.rooturl1 + "/api/TypeOfBodyMasters/GetTypeOfBodyMasters");
  }

  GetphysicalStatusTypes() {
    return this.http.get(this.rooturl1 + "/api/PhysicalStatusMasters/GetPhysicalStatusMasters");
  }

  GetreligionTypes() {
    return this.http.get(this.rooturl1 + "/api/ReligionMasters/GetReligionMasters");
  }

  GetCasteTypes(id:any) {
    return this.http.get(this.rooturl1 + "/api/CasteMasters/GetReligiousCasteMasters/" + id);
  }

  GetsubCasteTypes(id:any) {
    return this.http.get(this.rooturl1 + "/api/SubCasteTypeMasters/GetSubCasteTypeofCaste/" + id);
  }
  GetSubCasteid(id){
    return this.http.get(this.rooturl1 + "/api/SubCasteTypeMasters/GetOldSubCaste/"+id);

  }

  GetRaasiTypes() {
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetRaasiTypeMasters");
  }
  GetRaasiid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldRaasi/"+id);


  }
  GetZodiacs() {
    return this.http.get(this.rooturl1 + "/api/SubCasteTypeMasters/GetZodiac");
  }  

  GetStarTypes(id:any) {
    return this.http.get(this.rooturl1 + "/api/StarTypeMasters/GetRasiStars/" + id);
  }
  GetStarid(id){
    
    return this.http.get(this.rooturl1 + "/api/StarTypeMasters/GetOldStar/"+id);

  }
 
  

  GetFoodPreferenceTypes() {
    return this.http.get(this.rooturl1 + "/api/FoodPreferenceTypes/GetFoodPreferenceTypes");
    
  }
  GetFoodPreferenceid(id){
    return this.http.get(this.rooturl1 + "/api/FoodPreferenceTypes/GetOldFood/"+id);
 
  }

  GetGothramTypes() {
    return this.http.get(this.rooturl1 + "/api/GothramTypeMasters/GetGothramTypeMasters");
  }
  GetGothramid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldGothrm/"+id);

  }

  GetManglikTypes() {
    return this.http.get(this.rooturl1 + "/api/ManglikTypeMasters/GetManglikTypeMasters");
  }

  GetOccupationTypes() {
    return this.http.get(this.rooturl1 + "/api/OccupationMasters/GetOccupationMasters");
  }

  GetDesiginationTypes() {
    return this.http.get(this.rooturl1 + "/api/DesignationMasters/GetDesignationMasters");
  }
  GetEmployedIn(){
    return this.http.get(this.rooturl1 + "/api/EmployedInMaster/GetEmploy");
  }

  GetFamilyValueTypes() {
    return this.http.get(this.rooturl1 + "/api/FamilyValueTypeMasters/GetFamilyValueTypeMasters");
  }
  GetFamilyValueid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldFamilyValue/"+id);

  }

  GetFamilyTypeTypes() {
    return this.http.get(this.rooturl1 + "/api/FamilyTypeMasters/GetFamilyTypeMasters");
  }
  GetFamilyTypeid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldFamilyType/"+id);


  }

  GetFamilyStatusesTypes() {
    return this.http.get(this.rooturl1 + "/api/FamilyStatusTypeMasters/GetFamilyStatusTypeMasters");
  }
  GetFamilyStatusid(id){
    return this.http.get(this.rooturl1 + "/api/RaasiTypeMasters/GetOldFamilyStatus/"+id);

  }
  GetNumberOfSiblingsTypes() {
    return this.http.get(this.rooturl1 + "/api/NoOfSiblingsMasters/GetNoOfSiblingsMasters");
  }
  GetMarriageTypeTypes() {
    return this.http.get(this.rooturl1 + "/api/MarriageTypeMasters/GetMarriageTypeMasters");
  }
  GetGenders() {
    return this.http.get(this.rooturl1 + "/api/GenderMasters/GetGenderMasters");
  }
  GetQualifications() {
    return this.http.get(this.rooturl1 + "/api/QualificationMasters/GetQualificationMasters");
  }

  saveAboutBrideGroomCompleteDetails(data: AboutBrideGroomDetails) {
    return this.http.post(this.rooturl1 + "/api/InitialReg/PostAboutBrideGroomDetails", data);
  }

  postImageFile(caption: string, fileupload: File, long: string, lat: string) {
    const url = "http://localhost:15000/api/AuManagement/UploadCustomerImage";
    const formData: FormData = new FormData();
    formData.append("Image", fileupload, fileupload.name);
    formData.append("MenuId", caption);
    formData.append("Longitude", long);
    formData.append("Latitude", lat);
    return this.http.post(url, formData);
  }


}
