import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { Plan } from 'src/app/shared/ViewModels/plan';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  closeResult:any;
  planname:any;
  period:any;
  capacity:any;
  price:any;
  AllPlans: any;
  deleteplan: any;
  plann: any ;
  PId:any;


    PlanType = new FormGroup({
    PlanName: new FormControl(0),
    roleName:new FormControl(),
    createdBy:new FormControl(),


  });

  constructor(private userservice:UserServiceService,private modalService: NgbModal) { }

  GetAllPlanList(){
    this.userservice.PlantList().subscribe((respose:any) =>{
      this.AllPlans = respose;
       });
}

  ngOnInit() {
    this.GetAllPlanList();

  }

  AddPlan(plan) {
    this.modalService.open(plan).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // this.m=this.accid;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  saveplan() { 
    const inputRequest:Plan={   
      Id: 0, 
      Type: this.planname,
      Period:this.period,
      Price:this.price,
      StorageCapacity:this.capacity,
    }
    this.userservice.SavePlan(inputRequest).subscribe((data:any)=>{
      if(data!=null){
        alert("Saved Successfully");
       // this.route.navigate(['/allfolders']);
        window.location.reload();
      }
      else{
       alert("Something went Wrong");
       // this.route.navigate(['/allfolders']);
        window.location.reload();
      }
    });
  }

  DeletePlan(f){
    var check=confirm("Are you sure you want to delete  " + f.Type +"?");
    if(check){
      this.userservice.DeletePlan(f.Id).subscribe((respose:any) =>{
        this.deleteplan = respose;
        alert("Deleted Successfully!!!")
        this.GetAllPlanList();
         });
    }

  }

  
  EditPlan(f,plan1) {
    this.planname=f.Type;
    this.period=f.Period;
    this.price=f.Price;
    this.capacity=f.StorageCapacity;
    this.PId=f.Id;
    this.modalService.open(plan1).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }

  updateplan() { 
    const inputRequest:Plan={   
      Id: this.PId, 
      Type: this.planname,
      Period:this.period,
      Price:this.price,
      StorageCapacity:this.capacity,
    }
    this.userservice.UpdatePlan(inputRequest).subscribe((data:any)=>{
      // if(data!=null){
        alert("Updated Successfully");
       // this.route.navigate(['/allfolders'])
        window.location.reload();
    
    });
  }

}
