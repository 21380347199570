import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { BridegGroomRegistrationComponent } from './Registration/brideg-groom-registration/brideg-groom-registration.component';

import { FillDetailsBrideGroomComponent } from './Registration/fill-details-bride-groom/fill-details-bride-groom.component';
import { TandCComponent } from './Registration/tand-c/tand-c.component';
import { AboutAttributesInfoComponent } from './Registration/about-attributes-info/about-attributes-info.component';

import { CastetypeComponent } from './Masters/castetype/castetype.component';
import { CountryComponent } from './Masters/country/country.component';
import { ComplextionComponent } from './Masters/complextion/complextion.component';
import { CurrencyComponent } from './Masters/currency/currency.component';
import { DesignationComponent } from './Masters/designation/designation.component';
import { DrinkingComponent } from './Masters/drinking/drinking.component';
import { EmployedinComponent } from './Masters/employedin/employedin.component';
import { FamilystatusComponent } from './Masters/familystatus/familystatus.component';
import { FamilytypeComponent } from './Masters/familytype/familytype.component';
import { FamilyvalueComponent } from './Masters/familyvalue/familyvalue.component';
import { FoodpreferenceComponent } from './Masters/foodpreference/foodpreference.component';
import { GanaComponent } from './Masters/gana/gana.component';
import { StarComponent } from './Masters/star/star.component';
import { RaasiComponent } from './Masters/raasi/raasi.component';
import { GenderComponent } from './Masters/gender/gender.component';
import { GothramComponent } from './Masters/gothram/gothram.component';
import { HeightComponent } from './Masters/height/height.component';
import { LookComponent } from './Masters/look/look.component';
import { ManglikComponent } from './Masters/manglik/manglik.component';
import { MaritalstatusComponent } from './Masters/maritalstatus/maritalstatus.component';
import { NoofsiblingsComponent } from './Masters/noofsiblings/noofsiblings.component';
import { OccupationComponent } from './Masters/occupation/occupation.component';
import { PhysicalstatusComponent } from './Masters/physicalstatus/physicalstatus.component';
import { QualificationComponent } from './Masters/qualification/qualification.component';
import { RoleComponent } from './Masters/role/role.component';
import { SalaryComponent } from './Masters/salary/salary.component';
import { SmokingComponent } from './Masters/smoking/smoking.component';
import { StateComponent } from './Masters/state/state.component';
import { SubcasteComponent } from './Masters/subcaste/subcaste.component';
import { TypeofbodyComponent } from './Masters/typeofbody/typeofbody.component'
import { UploadpictureComponent } from './Registration/uploadpicture/uploadpicture.component';
import { PreferredPartnerInfoComponent } from './Registration/preferred-partner-info/preferred-partner-info.component';
import { YourMatchesComponent } from './your-matches/your-matches.component';
import { PayuPlansComponent } from './USRM/payu-plans/payu-plans.component';
import { PayuComponent } from './USRM/payu/payu.component';
import { SaveuserchatComponent } from './saveuserchat/saveuserchat.component';
import { FullDetailsComponent } from './full-details/full-details.component';
import { ZodiacComponent } from './Masters/zodiac/zodiac.component';
import { UpdateBrideGroomDetailsComponent } from './UpdateProfile/update-bride-groom-details/update-bride-groom-details.component';
import { ChangePasswordComponent } from './USRM/change-password/change-password.component';
import { ForgotPasswordComponent } from './USRM/forgot-password/forgot-password.component';
import { UpdateAboutAttributesInfoComponent } from './UpdateProfile/update-about-attributes-info/update-about-attributes-info.component';
import { UpdatePictureComponent } from './UpdateProfile/update-picture/update-picture.component';
import { PlanComponent } from './USRM/plan/plan.component';
import { AdminModuleComponent } from './IdentityManagement/admin-module/admin-module.component';
import { BiometricsComponent } from './IdentityManagement/biometrics/biometrics.component';

import { CustomerregistrationComponent } from './IdentityManagement/customerregistration/customerregistration.component';
import { IdentityRegistrationFormComponent } from './IdentityManagement/identity-registration-form/identity-registration-form.component';
import { AllfoldersComponent } from './USRM/AllFolders/allfolders/allfolders.component';
import { SubfoldersComponent } from './USRM/subfolders/subfolders.component';
import { ProvidingServicesComponent } from './ProvidingServices/providing-services/providing-services.component';
import { HoroscopeMatchingComponent } from './Horoscope/horoscope-matching/horoscope-matching.component';
import { UpdatePrefferedPartnerInfoComponent } from './UpdateProfile/update-preffered-partner-info/update-preffered-partner-info/update-preffered-partner-info.component';
import { AllTransactionsComponent } from './USRM/all-transactions/all-transactions.component';
import { FriendsComponent } from './USRM/friends/friends.component';
import { UploadSignaturesComponent } from './USRM/upload-signatures/upload-signatures/upload-signatures.component';
import { CustomerReportComponent } from './USRM/customer-report/customer-report/customer-report.component';
import { DIMAllFoldersComponent } from './USRM/DIMAllFolders/dimall-folders/dimall-folders.component';
import { RequestMsgComponent } from './request-msg/request-msg.component';
import { LogintoSeeProfileComponent } from './loginto-see-profile/loginto-see-profile.component';




const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'register', component: SignupComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  {
    path: 'ChangePassword',
    component: ChangePasswordComponent
  },
  { path: 'loginprofile/:id', component: LogintoSeeProfileComponent },
  { path: 'applicantInfo/:id', component: BridegGroomRegistrationComponent },

  { path: 'SeekerInfo', component: FillDetailsBrideGroomComponent },
  { path: 'TandC', component: TandCComponent },
  {
    path: 'AboutAttributesInfo',
    component: AboutAttributesInfoComponent
  },
  {
    path: 'castetype',
    component: CastetypeComponent
  },
  {
    path: 'country',
    component: CountryComponent
  },
  // {
  //   path: 'country',
  //   component: CountryComponent
  // },
  {
    path: 'complexion',
    component: ComplextionComponent
  },
  {
    path: 'currency',
    component: CurrencyComponent
  },
  {
    path: 'designation',
    component: DesignationComponent
  },
  {
    path: 'drinking',
    component: DrinkingComponent
  },
  {
    path: 'employedin',
    component: EmployedinComponent
  },
  {
    path: 'familystatus',
    component: FamilystatusComponent
  },
  {
    path: 'familytype',
    component: FamilytypeComponent
  },
  {
    path: 'familyvalue',
    component: FamilyvalueComponent
  },
  {
    path: 'foodpreference',
    component: FoodpreferenceComponent
  },
  {
    path: 'gana',
    component: GanaComponent
  },
  {
    path: 'star',
    component: StarComponent
  },
  {
    path: 'raasi',
    component: RaasiComponent
  },
  {
    path: 'gender',
    component: GenderComponent
  },
  {
    path: 'gothram',
    component: GothramComponent
  },
  {
    path: 'height',
    component: HeightComponent
  },
  {
    path: 'look',
    component: LookComponent
  },
  {
    path: 'manglik',
    component: ManglikComponent
  },
  {
    path: 'maritalstatus',
    component: MaritalstatusComponent
  },
  {
    path: 'noofsiblings',
    component: NoofsiblingsComponent
  },
  {
    path: 'occupation',
    component: OccupationComponent
  },
  {
    path: 'physicalstatus',
    component: PhysicalstatusComponent
  },
  {
    path: 'qualification',
    component: QualificationComponent
  },
  {
    path: 'role',
    component: RoleComponent
  },
  {
    path: 'salary',
    component: SalaryComponent
  },
  {
    path: 'smoking',
    component: SmokingComponent
  },
  {
    path: 'state',
    component: StateComponent
  },

  {
    path: 'subcastetype',
    component: SubcasteComponent
  },
  {
    path: 'typeofbody',
    component: TypeofbodyComponent
  },
  {
    path: 'zodiac',
    component: ZodiacComponent
  },
  {
    path: 'UploadPicture',
    component: UploadpictureComponent
  },
  {
    path: 'PreferredPartnerInfo',
    component: PreferredPartnerInfoComponent
  },
  {
    path: 'YourMatches',
    component: YourMatchesComponent
  },
  {
    path: 'payment/:id',
    component: PayuComponent
  },
  {
    path: 'payuplans/:id',
    component: PayuPlansComponent
  },
  {
    path: 'SaveUserChat',
    component: SaveuserchatComponent
  },
  {
    path: 'reqmsg',
    component: RequestMsgComponent
  },
  {
    path: 'fulldetails/:id',
    component: FullDetailsComponent
  },
  {
    path: 'updateprofile/:id',
    component: UpdateBrideGroomDetailsComponent
  },
  {
    path: 'updateaboutattributes',
    component: UpdateAboutAttributesInfoComponent
  },
  {
    path: 'updatepicture',
    component: UpdatePictureComponent
  },
  {
    path: 'updateprefferedpartner/:id',
    component: UpdatePrefferedPartnerInfoComponent
  },
  {
    path: 'payuplans/:id',
    component: PayuPlansComponent
  },

  // {
  //   path: 'biometrics',
  //   component: BiometricsComponent
  // },
  {
    path: 'plan',
    component: PlanComponent
  },
  {
    path: 'adminFtn',
    component: AdminModuleComponent
  },
  {
    path: 'biometrics',
    component: BiometricsComponent
  },

  {
    path: ' ',
    component: CustomerregistrationComponent
  },
  {
    path: 'uploadsignatures',
    component: UploadSignaturesComponent
  },
  {
    path: 'customerreport',
    component: CustomerReportComponent
  },
  {
    path: 'DIMAllfolder',
    component: DIMAllFoldersComponent
  },
  {
    path: 'youridentityform',
    component: IdentityRegistrationFormComponent
  },
  {
    path: 'allfolders',
    component: AllfoldersComponent
  },

  { path: 'subfolders/:id', component: SubfoldersComponent },
  { path: 'allfolders/:id', component: AllfoldersComponent },
   {
    path: 'services',
    component: ProvidingServicesComponent
  },
  {
    path: 'horoscope',
    component: HoroscopeMatchingComponent
  },
  {
    path:'alltransactions',
    component:AllTransactionsComponent
  },
  {
    path: 'friends',
    component: FriendsComponent
  },
  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
