import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FolderviewService } from 'src/app/shared/FlderViewService/folderview.service';

@Component({
  selector: 'app-imageviewpopup',
  templateUrl: './imageviewpopup.component.html',
  styleUrls: ['./imageviewpopup.component.scss']
})
export class ImageviewpopupComponent implements OnInit {
  closeResult:any;
  msg:string;
  m:string;ppp:any;filedata:any;
  constructor(private modalService: NgbModal,private folderviewservice:FolderviewService) { 
    if(localStorage.getItem("IsLoggedIn")=="true")
      {
        this.filedata = localStorage.getItem("data");
        this.ppp = JSON.parse(this.filedata);
      }
  }

  ngOnInit() {
    // this.folderviewservice.viewDocument(aa).subscribe((data:any)=>{
    //   this.pdfUrl = data;
    // });
  }
  // open(content) {
  //   this.modalService.open(content).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     this.m=this.msg;
  //   });
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return  `with: ${reason}`;
  //   }
  // }
}
