import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/IdentityManagement/UserService/user.service';

@Component({
  selector: 'app-upload-signatures',
  templateUrl: './upload-signatures.component.html',
  styleUrls: ['./upload-signatures.component.css']
})
export class UploadSignaturesComponent implements OnInit {
  fileupload: File;
  imageUrl: any;
  uid: any;
  isLoggedIn: boolean;
  roleid: string;
  userName: string;
  pid: string;

  constructor(private userservice:UserService,private router:Router) { 
    if(localStorage.getItem("IsLoggedIn")=="true")
    {
      this.isLoggedIn=true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
    }
  }

  ngOnInit() {
  }
  
  handleFIeInput(file: FileList) {
    this.fileupload = file.item(0);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileupload);
  }
  OnSubmit(Image) {
    this.userservice.postFile(this.uid, this.fileupload).subscribe(
      data => {
        console.log('done');
        //Caption.value = null;
        Image.value = null;
        this.imageUrl = "/assets/img/img.png";
        if(data != null){
          alert("File Uploaded Succesfully");

        }
              
        else {
          alert("Something went wrong")
        }

      });
  }

}
