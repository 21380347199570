import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Friend } from '../../ViewModels/Friend';

@Injectable({
  providedIn: 'root'
})
export class SmartContractService {

  readonly rootUrl = 'https://blockchainmatrimony.com/matrimonydms'

  //readonly rootUrl = 'http://analyticals.co/haspitdmsapi'

  constructor(private http: HttpClient) { }
  SaveFrienddetails(data: Friend) {
    return this.http.post(this.rootUrl + "/api/FriendList/PostFriendList", data);
  }
  friendlist(id) {
    return this.http.get(this.rootUrl + "/api/FriendList/GetFriendList/" + id);

  }
  AcceptReq(fid) {
    return this.http.post(this.rootUrl + "/api/FriendList/AcceptRequest", fid);
  }
}
