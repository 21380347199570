import { Component, OnInit } from '@angular/core';
import { CurrencyViewModel } from 'src/app/shared/ViewModels/CurrencyViewModel';
import { NgForm } from '@angular/forms';
import { CountryViewModel } from 'src/app/shared/ViewModels/CountryViewModel';
import { CurrencyService } from 'src/app/shared/currency/currency.service';
import { CountryService } from 'src/app/shared/country/country.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {
  CurrencyList: any;
  currency: any;
  currencyId: any; genid: any;
  CasteTypeList: CurrencyViewModel[];
  CountryList: CountryViewModel[];
  
    constructor(private currencyService:CurrencyService , private countryService:CountryService,  private router: Router) { }
  
    ngOnInit() {
      this.Currency();
      this.Country();
      this.currency = "";
    }
    ResetData(){
      this.currency = "";
    }
  
    Country() {
      this.countryService.getCountry().subscribe((Data: CountryViewModel[]) => {
        this.CountryList = Data;
      })
    }
    Currency() {
      this.currencyService.getCurrency().subscribe((Data: CurrencyViewModel[]) => {
        this.CurrencyList = Data;
      })
    }
  
    SaveCurrencyForm(form: NgForm) {
      const inputRequest: CurrencyViewModel = {
        ID: 0,
        CountryId:this.Country,
        Currency: form.controls["currency"].value,
      }
      this.currencyService.saveCurrency(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/currency']);
          window.location.reload();
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/currency']);
          window.location.reload();
        }
      });
    }
  
    editcurrency(data: { currency: any; id: any; }) {
      this.currency = data.currency;
      this.currencyId = data.id;
      
    }
  
    UpdateCurrency(form: NgForm) {
      const inputRequest1: CurrencyViewModel = {
        ID: this.currencyId,
        CountryId:this.Country,
        Currency: form.controls["currency"].value,
      }
      this.currencyService.UpdateCurrency(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Currency Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletecurrency(data: { id: any; }) {
      this.genid = data.id;
      this.currencyService.deletecurrency(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Currency Deleted Succesfully");
            window.location.reload();
          } else {
            alert("Currency Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }

    onSelectCountry(id) {
      this.Country = id
    }
  
  }
  
  
    
