import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { Subscription } from 'rxjs';
import { MenuMaster } from '../MenuMaster/menumaster.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from '../MessageService';
import { UserServiceService } from '../Services/UserService/user-service.service';
import { RequestListVM } from '../ViewModels/RequestVM';
import { BridegroomService } from '../Services/BridegroomService/bridegroom.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    private toggleButton: any;
    private sidebarVisible: boolean;
    public isLoggedIn: boolean = false;
    message: any;
    subscription: Subscription;
    role: number;
    uid: any; accid: any; name: any;
    mastermenus: MenuMaster[];
    menuroles: MenuMaster[];
    uname: any;
    roleid: any;
    value: string;
    menus: any;
    usermaster: boolean;
    Reqmsg: any;
    lenofreqmsg: boolean;
    replyenable: boolean;

    constructor(public location: Location, private router: Router,private bridegroomService: BridegroomService, private userService: UserServiceService, private spinner: NgxSpinnerService, private element: ElementRef, private messageService: MessageService) {

        if (localStorage.getItem("IsLoggedIn") == "true") {
            this.isLoggedIn = true;
            this.uid = localStorage.getItem("userId");
            this.accid = localStorage.getItem("uid");
            this.name = localStorage.getItem("UserName");
            this.roleid = localStorage.getItem("role");
            this.value = localStorage.getItem("value");
           // this.getmenu();
          //  this.getmastermenu();
        }
        if (window.location.reload && localStorage.getItem("IsLoggedIn") == "true") {
            this.message = true;
            this.isLoggedIn = true;
        }
        this.subscription = this.messageService.getMessage().subscribe(message => {
            this.message = message;
            if (this.message.text == "true") {
                this.isLoggedIn = true;

            }

        });
        this.subscription = this.messageService.getMessage1().subscribe(name => {
            this.uname = name;
            if (this.message.text == "true") {
                //this.isLoggedIn=true; #FFB236  #0e4ca1

            }

        });

        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        localStorage.getItem("IsLoggedIn");
      
        // this.router.events.subscribe((event) => {
        //     this.isCollapsed = true;
        //     if (event instanceof NavigationStart) {
        //         if (event.url != this.lastPoppedUrl)
        //             this.yScrollStack.push(window.scrollY);
        //     } else if (event instanceof NavigationEnd) {
        //         if (event.url == this.lastPoppedUrl) {
        //             this.lastPoppedUrl = undefined;
        //             window.scrollTo(0, this.yScrollStack.pop());
        //         } else
        //             window.scrollTo(0, 0);
        //     }
        // });
        // this.location.subscribe((ev: PopStateEvent) => {
        //     this.lastPoppedUrl = ev.url;
        // });
    }
 
    goto() {
        this.router.navigate(['/updateprofile', this.uid]);
    }
    gotopriority() {
        this.router.navigate(['/updateprefferedpartner', this.uid]);
    }
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '#/home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '#/documentation') {
            return true;
        }
        else {
            return false;
        }
    }

    // getmenu() {
    //     this.spinner.show();
    //     this.userService.getallmenus().subscribe((respose: any) => {
    //         this.menus = respose;
    //         this.spinner.hide();

    //     });
    // }
    // getmastermenu() {
    //     this.spinner.show();
    //     this.userService.getallmastermenus(this.roleid).subscribe((respose: any) => {
    //         this.mastermenus = respose;
    //         this.spinner.hide();

    //     });
    // }

    plans() {
        this.router.navigate(['/payuplans', this.uid]);
    }
    workflow() {
        this.router.navigate(['http://106.51.135.44/matrimonyidwfweb']);
    }

    Logout() {
        this.isLoggedIn = false;
        localStorage.setItem("IsLoggedIn", "false");
        localStorage.removeItem('userToken');
        this.router.navigate(['/']);
    }





}
