import { Component, OnInit } from '@angular/core';
import { AboutBrideGroomDetails } from 'src/app/shared/ViewModels/AboutBrideGroomDetails';
import { NgForm } from '@angular/forms';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BridegroomService } from 'src/app/shared/Services/BridegroomService/bridegroom.service';

@Component({
  selector: 'app-update-about-attributes-info',
  templateUrl: './update-about-attributes-info.component.html',
  styleUrls: ['./update-about-attributes-info.component.css']
})
export class UpdateAboutAttributesInfoComponent implements OnInit {

  MatrimonyId: any;
  Details: any;
  personalitytraits: any;
  lifegoals: any;
  cannotcompro: any;
  personalityattributesinpartner: any;
  passions: any;

  constructor(private userService: UserServiceService, private router: Router, private spinner: NgxSpinnerService, private bridegroomService: BridegroomService) {
    this.MatrimonyId= localStorage.getItem('uid');
   }
  ngOnInit() {
    this.GetAttributesinfo();
  }

  
  GetAttributesinfo() {
    this.spinner.show();
    this.bridegroomService.GetAttributesinfo(this.MatrimonyId).subscribe((response: any) => {     
      this.Details = response;
      this.spinner.hide();
      this.personalitytraits = this.Details.aboutAttributesInfo.personalityAttributes,
      this.lifegoals = this.Details.aboutAttributesInfo.lifeGoals,
      this.cannotcompro = this.Details.aboutAttributesInfo.thingsThatCantComproOrChanged,
      this.personalityattributesinpartner = this.Details.aboutAttributesInfo.personalityTraits,
      this.passions = this.Details.aboutAttributesInfo.passions      
    });
  }

  OnSubmitAboutBrideGroomRegistration(form: NgForm) {
    this.spinner.show();
    if (form.controls["personalitytraits"].value == null && form.controls["personalitytraits"].value == "") {
      alert("Please Enter Your Name")
    }
    else if (form.controls["cannotcompro"].value == null && form.controls["cannotcompro"].value == "") {
      alert("Please Enter Your Middle Name")
    }
    else if (form.controls["passions"].value == null && form.controls["passions"].value == "") {
      alert("Please Provide Us Your Date Of Birth");
    }
    else if (form.controls["lifegoals"].value == null && form.controls["lifegoals"].value == "") {
      alert("Please Provide Us Your Date Of Birth");
    }
    else {
      const inputRequest: AboutBrideGroomDetails = {
        BrideGroomId: this.MatrimonyId,
        LifeGoals: form.controls["lifegoals"].value,
        PersonalityTraits: form.controls["personalitytraits"].value,
        Passions: form.controls["passions"].value,
        ThingsThatCantComproOrChanged: form.controls["cannotcompro"].value,
        PersonalityAttributes: form.controls["personalityattributesinpartner"].value

      }
      this.bridegroomService.saveAboutBrideGroomCompleteDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Updated Your About Details Successfully");
          this.router.navigate(['/YourMatches']);
          this.spinner.hide();
        }
        else {
          alert("Please Fill Details Properly");
          this.spinner.hide();
        }
      });
    }
  }

}
