import { Component, OnInit } from '@angular/core';
import { FamilyValueViewModel } from 'src/app/shared/ViewModels/FamilyValueViewModel';
import { NgForm } from '@angular/forms';
import { FamilyvalueService } from 'src/app/shared/familyvalue/familyvalue.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-familyvalue',
  templateUrl: './familyvalue.component.html',
  styleUrls: ['./familyvalue.component.css']
})
export class FamilyvalueComponent implements OnInit {
  FamilyValueList: any;
  familyvalue: any;
  familyvalueId: any; genid: any;
  
    constructor(private familyvalueService:FamilyvalueService , private router: Router) { }
  
    ngOnInit() {
      this. FamilyValue();
      this.familyvalue = "";
    }
    ResetData(){
      this.familyvalue = "";
    }
  
  
    FamilyValue() {
      this.familyvalueService.getFamilyValue().subscribe((Data:  FamilyValueViewModel[]) => {
        this.FamilyValueList = Data;
      })
    }
  
    SaveFamilyValueForm(form: NgForm) {
      const inputRequest: FamilyValueViewModel = {
        ID: 0,
        FamilyValueType: form.controls["familyvalue"].value,
      }
      this.familyvalueService.saveFamilyValue(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/familyvalue']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/familyvalue']);
          window.location.reload();
        }
      });
    }
  
    editfamilyvalue(data: { familyValueType: any; id: any; }) {
      this.familyvalue = data.familyValueType;
      this.familyvalueId = data.id;
    }
  
    UpdateFamilyValue(form: NgForm) {
      const inputRequest1: FamilyValueViewModel = {
        ID: this.familyvalueId,
        FamilyValueType: form.controls["familyvalue"].value,
      }
      this.familyvalueService.UpdateFamilyValue(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("FamilyValue Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletefamilyvalue(data: { id: any; }) {
      this.genid = data.id;
      this.familyvalueService.deletefamilyvalue(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("FamilyValue Deleted Succesfully");
            window.location.reload();
        } else {
            alert("FamilyValue Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

