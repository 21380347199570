import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleViewModel } from '../ViewModels/RoleViewModel';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveRole(data: RoleViewModel) {
    return this.http.post(this.rooturl1 + "/api/RoleMaster/PostSaveRole", data);
  }
  getRole() {
    return this.http.get(this.rooturl1 + "/api/RoleMaster/GetRole");
  }
  deleterole(id: number) {
    return this.http.delete(this.rooturl1 + "/api/RoleMaster/DeleteRoledata/" + id);
  }

  UpdateRole(data: RoleViewModel) {
    return this.http.put(this.rooturl1 + "/api/RoleMaster/PutRole", data);
  }
}



  