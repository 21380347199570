import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GothramViewModel } from 'src/app/shared/ViewModels/GothramViewModel';
import { GothramService } from 'src/app/shared/gothram/gothram.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gothram',
  templateUrl: './gothram.component.html',
  styleUrls: ['./gothram.component.css']
})
export class GothramComponent implements OnInit {
  GothramList: any;
  gothram: any;
  gothramId: any; genid: any;
  
    constructor(private gothramService:GothramService , private router: Router) { }
  
    ngOnInit() {
      this.Gothram();
      this.gothram = "";
    }
    ResetData(){
      this.gothram = "";
    }
  
  
    Gothram() {
      this.gothramService.getGothram().subscribe((Data: GothramViewModel[]) => {
        this.GothramList = Data;
      })
    }
  
    SaveGothramForm(form: NgForm) {
      const inputRequest: GothramViewModel = {
        ID: 0,
        GothramName: form.controls["gothram"].value,
      }
      this.gothramService.saveGothram(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/gothram']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/gothram']);
          window.location.reload();
        }
      });
    }
  
    editgothram(data: { gothramName: any; id: any; }) {
      this.gothram = data.gothramName;
      this.gothramId = data.id;
    }
  
    UpdateGothram(form: NgForm) {
      const inputRequest1: GothramViewModel = {
        ID: this.gothramId,
        GothramName: form.controls["gothram"].value,
      }
      this.gothramService.UpdateGothram(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Gothram Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletegothram(data: { id: any; }) {
      this.genid = data.id;
      this.gothramService.deletegothram(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Gothram Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Gothram Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  