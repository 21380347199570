import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SmokingViewModel } from '../ViewModels/SmokingViewModel';

@Injectable({
  providedIn: 'root'
})
export class SmokingService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  SaveSmoking(data: SmokingViewModel) {
    return this.http.post(this.rooturl1 + "/api/SmokingTypeMaser/PostSaveSmoking", data);
  }
  getSmoking() {
    return this.http.get(this.rooturl1 + "/api/SmokingTypeMaser/GetSmoking");
  }
  deletesmoking(id: number) {
    return this.http.delete(this.rooturl1 + "/api/SmokingTypeMaser/DeleteSmokingdata/" + id);
  }

  UpdateSmoking(data: SmokingViewModel) {
    return this.http.put(this.rooturl1 + "/api/SmokingTypeMaser/PutSmoking", data);
  }
}