import { Component, OnInit } from '@angular/core';
import { FamilyTypeViewModel } from 'src/app/shared/ViewModels/FamilyTypeViewModel';
import { NgForm } from '@angular/forms';
import { FamilytypeService } from 'src/app/shared/familytype/familytype.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-familytype',
  templateUrl: './familytype.component.html',
  styleUrls: ['./familytype.component.css']
})
export class FamilytypeComponent implements OnInit {
  FamilyTypeList: any;
  familytype: any;
  familytypeId: any; genid: any;
  
    constructor(private familytypeService:FamilytypeService , private router: Router) { }
  
    ngOnInit() {
      this. FamilyType();
      this.familytype = "";
    }
    ResetData(){
      this.familytype = "";
    }
  
  
    FamilyType() {
      this.familytypeService.getFamilyType().subscribe((Data:  FamilyTypeViewModel[]) => {
        this.FamilyTypeList = Data;
      })
    }
  
    SaveFamilyTypeForm(form: NgForm) {
      const inputRequest: FamilyTypeViewModel = {
        ID: 0,
        FamilyType: form.controls["familytype"].value,
      }
      this.familytypeService.saveFamilyType(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/familytype']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/familytype']);
          window.location.reload();
        }
      });
    }
  
    editfamilytype(data: { familyType: any; id: any; }) {
      this.familytype = data.familyType;
      this.familytypeId = data.id;
    }
  
    UpdateFamilyType(form: NgForm) {
      const inputRequest1:FamilyTypeViewModel = {
        ID: this.familytypeId,
        FamilyType: form.controls["familytype"].value,
      }
      this.familytypeService.UpdateFamilyType(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("FamilyType Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletefamilytype(data: { id: any; }) {
      this.genid = data.id;
      this.familytypeService.deletefamilytype(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("FamilyType Deleted Succesfully");
            window.location.reload();
        } else {
            alert("FamilyType Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

