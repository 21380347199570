import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/IdentityManagement/UserService/user.service';
import { IDMUserId } from 'src/app/shared/ViewModels/IdentityManagementVM/IDMUserId';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.css']
})
export class CustomerReportComponent implements OnInit {
  DisplayDetails: any;
  isLoggedIn: boolean;
  uid: any;
  userName: any;
  today: Date = new Date();


  constructor(private userservice:UserService) { 


    if(localStorage.getItem("IsLoggedIn")=="true")
    {
      this.isLoggedIn=true;
      this.uid = localStorage.getItem("userId");
      this.userName = localStorage.getItem("UserName");
    }
  }

  ngOnInit() {
    this.GetCustomerReportDetails();
  }

  GetCustomerReportDetails() {
    const inputRequest : IDMUserId = {
      Id : this.uid
    }
    this.userservice.getcustomerreportdetails(inputRequest).subscribe((respose: any) => {
     
      if(respose == "Failed"){
        alert("Sorry CEO Did Not Verify Your Documents Yet!! ")

      }
      else{
        this.DisplayDetails = respose;

      }
    });
  }
}

 