import { Component, OnInit } from '@angular/core';
import { GanaViewModel } from 'src/app/shared/ViewModels/GanaViewModel';
import { NgForm } from '@angular/forms';
import { GanaService } from 'src/app/shared/gana/gana.service';
import { Router } from '@angular/router';
import { StarService } from 'src/app/shared/star/star.service';
import { StarViewModel } from 'src/app/shared/ViewModels/StarViewModel';

@Component({
  selector: 'app-gana',
  templateUrl: './gana.component.html',
  styleUrls: ['./gana.component.css']
})
export class GanaComponent implements OnInit {
  GanaList: any;
  gana: any;
  ganaId: any; genid: any;
 StarList: StarViewModel[];
  BrideStar: any;
  GroomStar: any;
  
  
    constructor(private starService:StarService , private ganaService:GanaService,  private router: Router) { }
  
    ngOnInit() {
      this.Gana();
      this.Star();
      this.gana = "";
    }
    ResetData(){
      this.gana = "";
    }
  
    Star() {
      this.starService.getStar().subscribe((Data: StarViewModel[]) => {
        this.StarList = Data;
      })
    }
    Gana() {
      this.ganaService.getGana().subscribe((Data: GanaViewModel[]) => {
        this.GanaList = Data;
      })
    }
  
    SaveGanaForm(form: NgForm) {
      const inputRequest: GanaViewModel = {
        ID: this.ganaId,
        BrideStar: this.BrideStar,
        GroomStar : this.GroomStar,
        Gana: form.controls["gana"].value,
      }
      this.ganaService.saveGana(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/gana']);
          // window.location.reload();
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/gana']);
          // window.location.reload();
        }
      });
    }
  
    editgana(data: { gana: any; id: any; }) {
      this.gana = data.gana;
      this.ganaId = data.id;
      
    }
  
    UpdateGana(form: NgForm) {
      const inputRequest1: GanaViewModel = {
        ID: this.ganaId,
        BrideStar: this.BrideStar,
        GroomStar : this.GroomStar,
        Gana: form.controls["gana"].value,
      }
      this.ganaService.UpdateGana(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Gana Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletegana(data: { id: any; }) {
      this.genid = data.id;
      this.ganaService.deletegana(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Gana Deleted Succesfully");
            //window.location.reload();
          } else {
            alert("Gana Went Wrong. Try again.!!");
           // window.location.reload();
          }
        });
    }

    onSelectBride(bid) {
      this.BrideStar = bid
    }
    onSelectGroom(gid) {
      this.GroomStar = gid
    }
    
  
  
  }
  
  
    


 