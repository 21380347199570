import { Component, OnInit } from '@angular/core';
import { DrinkingService } from 'src/app/shared/drinking/drinking.service';
import { Router } from '@angular/router';
import { DrinkingViewModel } from 'src/app/shared/ViewModels/DrinkingViewModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-drinking',
  templateUrl: './drinking.component.html',
  styleUrls: ['./drinking.component.css']
})
export class DrinkingComponent implements OnInit {
  
  
  DrinkingList: any;
  drinking: any;
  drinkingId: any; genid: any;
  
    constructor(private drinkingService:DrinkingService , private router: Router) { }
  
    ngOnInit() {
      this. Drinking();
      this.drinking = "";
    }
  
    ResetData(){
      this.drinking = "";
    }
    Drinking() {
      this.drinkingService.getDrinking().subscribe((Data:  DrinkingViewModel[]) => {
        this.DrinkingList = Data;
      })
    }
  
    SaveDrinkingForm(form: NgForm) {
      const inputRequest: DrinkingViewModel = {
        ID: 0,
        DrinkingType: form.controls["drinking"].value,
      }
      this.drinkingService.saveDrinking(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/drinking']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/drinking']);
          window.location.reload();
        }
      });
    }
  
    editdrinking(data: { drinkingType: any; id: any; }) {
      this.drinking = data.drinkingType;
      this.drinkingId = data.id;
    }
  
    UpdateDrinking(form: NgForm) {
      const inputRequest1: DrinkingViewModel = {
        ID: this.drinkingId,
        DrinkingType: form.controls["drinking"].value,
      }
      this.drinkingService.UpdateDrinking(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Drinking Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletedrinking(data: { id: any; }) {
      this.genid = data.id;
      this.drinkingService.deletedrinking(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Drinking Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Drinking Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  

