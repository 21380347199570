import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import { parse } from 'url';
import 'rxjs/add/operator/bufferCount';
import { ModalServiceService } from 'src/app/shared/Modalpopup/modal-service.service';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { AddNewFolder } from 'src/app/shared/ViewModels/AddNewFolder';

@Component({
  selector: 'app-addnewfolder',
  templateUrl: './addnewfolder.component.html',
  styleUrls: ['./addnewfolder.component.scss']
})
export class AddnewfolderComponent implements OnInit {
  currenturl:any;
  previousUrl:any;
  userid:any;
  roleid:any;
  code:string;
  ParentId:any;
  data:any;
  aa:any;
  f1:any;
  Id:any;
  pid:any;mm:any;
  constructor(private modalService: ModalServiceService,private userservice:UserServiceService, 
    private router : Router,private route:ActivatedRoute) 
    {
      if(localStorage.getItem("IsLoggedIn")=="true")
      {
        this.userid=localStorage.getItem("userId");
        this.roleid = localStorage.getItem("role");
        this.data = localStorage.getItem("subfolders");
        this.mm = localStorage.getItem("parentid");
        this.pid = JSON.parse(this.mm);
        this.aa = JSON.parse( this.data);
      }
      this.route.params.subscribe(params=>{
        if(params["id"]){
          this.Id = params["id"];
        }
      });
    }

  ngOnInit() {   
  }
  

  closeModal(id: string) {
    this.modalService.close(id);
  }

  AddNewFolder(form:NgForm){
    if(this.Id  == 1){
      this.code="Folder";
    }
    else{
      this.code="SubFolder";
      this.ParentId = this.aa;
    }
    if(this.Id == 1){    
        const inputRequest:AddNewFolder={   
          Id: 0, 
          FolderID: "0",
          RoleId:this.roleid,
          FolderName:form.controls["foldername"].value,
          UserId:this.userid,
          Code:this.code,
          ParentID: "0",
          Click: null
        }
        this.userservice.addNewFolder(inputRequest).subscribe((data:any)=>{
          if(data!=null){
            alert("Added Successfully");
            this.router.navigate(['/allfolders']);
          }
          else{
            alert("Something went Wrong");
            this.router.navigate(['/allfolders']);
          }
        });
    }
    else{
      if(this.aa.Count == 0){
        this.f1 = "0";
      }
      else{
        this.f1 = this.aa[0].FolderID;
      }
      const inputRequest:AddNewFolder={   
        Id: 0, 
        FolderID: "0",
        RoleId:this.roleid,
        FolderName:form.controls["foldername"].value,
        UserId:this.userid,
        Code:this.code,
        ParentID: this.pid,
        Click: null
      }
      this.userservice.addNewFolder(inputRequest).subscribe((data:any)=>{
        if(data!=null){
          alert("Added Successfully");
          this.router.navigate(['/allfolders']);
        }
        else{
          alert("Something went Wrong");
          this.router.navigate(['/allfolders']);
        }
      });
    }
    
    
  }

}

// Id: 0,
// FolderID: 0,
// FolderName: $scope.folder.FolderName,
// UserId: $scope.authData.userId,
// Code: $scope.Code,
// ParentID: 0,
// RoleId: uid.userrole,
// Click: $rootScope.Press