import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BridegroomService } from 'src/app/shared/Services/BridegroomService/bridegroom.service';
import { RegistrationprocessService } from 'src/app/shared/Services/Registration/registrationprocess.service';
import { NgForm } from '@angular/forms';
import { BrideGroomDetails } from 'src/app/shared/ViewModels/BrideGroomDetails';

@Component({
  selector: 'app-update-bride-groom-details',
  templateUrl: './update-bride-groom-details.component.html',
  styleUrls: ['./update-bride-groom-details.component.css']
})
export class UpdateBrideGroomDetailsComponent implements OnInit {

  stateid: number;
  States: any;
  countryid: number;
  Countries: any;
  genderid: number;
  Genders: any;
  qualificationid: number;
  Qualifications: any;
  maritalStatusId: number;
  PerviousMaritalStatus: any;
  complextionid: any;
  Complextions: any;
  BodyTypes: any;
  physicalstatusid: any;
  PhysicalStatuses: any;
  religionId: any;
  Religions: any;
  casteId: any;
  Castes: any;
  SubcasteId: any;
  subCastes: any;
  RaasiId: any;
  Raasis: any;
  Stars: any;
  StarId: any;
  FoodPreferenceId: any;
  FoodPreferences: any;
  Gothrams: any;
  GothramId: any;
  ManglikId: any;
  Mangliks: any;
  OccupationId: any;
  Occupations: any;
  DesiginationId: any;
  Desiginations: any;
  FamilyValueId: any;
  FamilyValues: any;
  FamilyTypeId: any;
  FamilyTypes: any;
  FamilyStatusId: any;
  FamilyStatuses: any;
  NumberOfSiblingsId: any;
  Siblings: any;
  MarriageTypeId: any;
  MarriageTypes: any;
  bodytypeid: any;
  DrinkingTypeId: any;
  DrinkingTypes: any;
  SmokingTypeId: any;
  SmokingTypes: any;
  fatherqualificationid: any;
  fathercasteId: any;
  fathersubcasteId: any;
  mothercasteId: any;
  mothersubcasteId: any;
  motherqualificationid: any;
  fatherOccupationId: any;
  motherOccupationId: any;
  MatrimonyId: string;
  SalaryId: any;
  SalariesRange: any;
  GothramIdMeteranal: any;
  Details: any;
  fname: string;
  mname: any;
  lname: any;
  dob: any;
  bridegroomemail: any;
  bridegroommobile: any;

  heightid: string;
  Heights: any;
  weight: any;
  noofchild: any;
  mothertoungue: any;
  landmark: any;
  residenceaddress: any;
  pincode: any;

  JobORBusiness: any;
  institutionname: any;
  natureofjob: any;
  familynativeplace: any;
  fathersname: any;
  fathersage: any;
  fathersmobile: any;
  fathersemail: any;
  mothersname: any;
  mothersage: any;
  mothersmobile: any;
  mothersemail: any;
  familynetworth: any;
  abttgroombride: any;
  currentcity: any;
 
  EmployedInId: any;
  EmployedIns: any;
  orgName: any;
  numofsis: any;
  numofbro: any;
  numofbromarried: any;
  aboutfamily: any;
  BrideGroomId: any;
  Gender: any;
  uid: any;
  ZodiacId:any;
  Zodiacs: any;
  numofsismarried: any;
  

  constructor(private route:ActivatedRoute,private registrationService:RegistrationprocessService, private userService: UserServiceService, private router: Router, private spinner: NgxSpinnerService, private bridegroomService: BridegroomService) {
    this.MatrimonyId = localStorage.getItem('uid');
    this.spinner.hide();
    this.route.params.subscribe(params=>{
      if(params["id"]){
        this.uid= params["id"];
      }
    });
  }

  ngOnInit() {
    this.GetDetails();
    this.GetMarriageTypeTypes();
    this.GetNumberOfSiblingsTypes();
    this.GetFamilyStatusTypes();
    this.GetFamilyTypesTypes();
    this.GetFamilyValuesTypes();
    this.GetDesiginationTypes();
    this.GetOccupationTypes();
    this.GetManglikTypes();
    this.GetGothramTypes();
    this.GetFoodPreferencesTypes();
    this.GetStarsTypes();
    this.GetRaasiTypes();
    this.GetsubCasteTypes();
    this.GetCasteTypes();
    this.GetReligionTypes();
    this.GetPhysicalStatusTypes();
    this.GetBodyTypes();
    this.GetComplextions();
    this.GetMaritalStatus();
    this.GetQualifications();
    this.GetEmployedIn();
    this.GetGenders();
    this.GetZodiacs();
    this.GetStates();
    this.GetCountries();
    this.GetDrinkingTypeTypes();
    this.GetSmokingTypeTypes();
    this.GetSalaryRanges();
    this.GetHeights();
  }

  GetDetails() {
    //this.spinner.show();
    this.bridegroomService.GetDetails(this.MatrimonyId).subscribe((response: any) => {      
      this.Details = response;
      //this.spinner.hide();
        this.BrideGroomId = this.Details.brideGroomDetails.brideGroomId,
        this.fname = this.Details.brideGroomDetails.firstName,
        this.mname = this.Details.brideGroomDetails.middleName,
        this.lname = this.Details.brideGroomDetails.lastName,
        this.dob = this.Details.brideGroomDetails.dateOfBirth,
        this.bridegroomemail = this.Details.brideGroomDetails.brideGroomEmail,
        this.bridegroommobile = this.Details.brideGroomDetails.brideGroomMobile,
        this.genderid = this.Details.brideGroomDetails.gender,        
        this.bodytypeid = this.Details.brideGroomDetails.typeOfBody,
        this.complextionid = this.Details.brideGroomDetails.complextion,
        this.physicalstatusid = this.Details.brideGroomDetails.physicalStatus,
        this.heightid = this.Details.brideGroomDetails.height,
        this.weight = this.Details.brideGroomDetails.weight,
        this.maritalStatusId = this.Details.brideGroomDetails.previousMaritalStatus,
        this.mothertoungue = this.Details.brideGroomDetails.motherToungue,
        this.noofchild = this.Details.brideGroomDetails.noOfChildern,
        this.landmark = this.Details.brideGroomDetails.landmark,
        this.abttgroombride = this.Details.brideGroomDetails.aboutGroomBride,  
        this.residenceaddress = this.Details.brideGroomDetails.currentResidenceAddress,
        this.currentcity = this.Details.brideGroomDetails.currentCity,
        this.stateid = this.Details.brideGroomDetails.state,
        this.countryid = this.Details.brideGroomDetails.country,
        this.pincode = this.Details.brideGroomDetails.pincode,
        this.religionId = this.Details.socialReligiousDetails.religionByBirth,
        this.casteId = this.Details.socialReligiousDetails.casteByBirth,
        this.SubcasteId = this.Details.socialReligiousDetails.subcasteByBirth,
        this.RaasiId = this.Details.socialReligiousDetails.raaasi,
        this.ZodiacId=this.Details.socialReligiousDetails.zodiacSign,
        this.StarId = this.Details.socialReligiousDetails.star,
        this.ManglikId = this.Details.socialReligiousDetails.manglik,
        this.GothramId = this.Details.socialReligiousDetails.gothram,
        this.GothramIdMeteranal = this.Details.socialReligiousDetails.gothramMeteranal,
        this.FoodPreferenceId = this.Details.socialReligiousDetails.foodPreferences,
     
        this.SmokingTypeId = this.Details.socialReligiousDetails.smoking,
        this.DrinkingTypeId = this.Details.socialReligiousDetails.drinking,
        this.qualificationid = this.Details.eduandOccDetails.highestQualification,
        this.JobORBusiness = this.Details.eduandOccDetails.jobORBusiness,
        this.institutionname = this.Details.eduandOccDetails.institutionName,
        this.DesiginationId = this.Details.eduandOccDetails.designation,
        this.SalaryId = this.Details.eduandOccDetails.annualIncome,
        this.natureofjob = this.Details.eduandOccDetails.describeTheNatureofJobORBusiness,
        this.FamilyTypeId = this.Details.familyDetails.familyType,
        this.FamilyValues = this.Details.familyDetails.familyValues,
        this.FamilyStatusId = this.Details.familyDetails.familyStatus,
        this.familynativeplace = this.Details.familyDetails.familyNativePlace,
        this.NumberOfSiblingsId = this.Details.familyDetails.noOfSiblings,
        this.fathersname = this.Details.parentsDetails.fatherName,
        this.fathersage = this.Details.parentsDetails.fatherAge,
        this.fatherqualificationid = this.Details.parentsDetails.fatherEducation,
        this.fatherOccupationId = this.Details.parentsDetails.fatherOccupation,
        this.fathercasteId = this.Details.parentsDetails.fatherCaste,
        this.fathersubcasteId = this.Details.parentsDetails.fatherSubcaste,
        this.fathersmobile = this.Details.parentsDetails.fatherPhoneNumber,
        this.fathersemail = this.Details.parentsDetails.fatherEmail,
        this.mothersname = this.Details.parentsDetails.motherName,
        this.mothersage = this.Details.parentsDetails.motherAge,
        this.motherqualificationid = this.Details.parentsDetails.motherEducation,
        this.motherOccupationId = this.Details.parentsDetails.motherOccupation,
        this.mothercasteId = this.Details.parentsDetails.motherCaste,
        this.mothersubcasteId = this.Details.parentsDetails.motherSubcaste,
        this.mothersmobile = this.Details.parentsDetails.motherPhoneNumber,
        this.mothersemail = this.Details.parentsDetails.motherEmail,
        this.MarriageTypeId = this.Details.parentsDetails.marriageType,
        this.familynetworth = this.Details.parentsDetails.familyNetWorth,
        this.aboutfamily = this.Details.familyDetails.aboutFamily,              
        this.EmployedInId = this.Details.eduandOccDetails.employeedIn,
        this.orgName = this.Details.eduandOccDetails.organizationName,
        this.numofbro = this.Details.familyDetails.noOfBrothers,
        this.numofsis = this.Details.familyDetails.noOfSisters,
        this.numofbromarried = this.Details.familyDetails.noOfBrothersMarried,
        this.numofsismarried = this.Details.familyDetails.noOfSistersMarried       
        
    });
  }

  OnSubmitUpdateBrideGroomRegistration(form: NgForm) {
    // this.spinner.show();
    if (form.controls["fname"].value == null && form.controls["fname"].value == "") {
      alert("Please Enter Your Name")
    }
    else if (form.controls["mname"].value == null && form.controls["mname"].value == ""){
      alert("Please Enter Your Middle Name")
    }
    else if (this.genderid == null && this.genderid == undefined){
      alert("Please Select Your Sex")
    }
    else if (form.controls["dob"].value == null && form.controls["dob"].value == ""){
      alert("Please Provide Us Your Date Of Birth");
    }
    else if (form.controls["bridegroomemail"].value == null && form.controls["bridegroomemail"].value == ""){
      alert("Please Provide Us Your Bride / Groom E-Mail")
    }
    else if (form.controls["bridegroommobile"].value == null && form.controls["bridegroommobile"].value == ""){
      alert("Please Provide Us Your Bride/Groom Mobile")
    }
    else if (this.maritalStatusId == null && this.maritalStatusId == undefined){
      alert("Please Provide Us Your Previous Marital Status")
    }
    else if (this.complextionid == null && this.complextionid == undefined){
      alert("Please Provide Us Your Complextion")
    }
    // else if (form.controls["height"].value == null && form.controls["height"].value == ""){
    //   alert("Please Enter Your Height")
    // }
    else if (this.heightid == null && this.heightid == undefined){
      alert("Please Enter Your Height")
    }
    else if (form.controls["weight"].value == null && form.controls["weight"].value == ""){
      alert("Please Enter Your Weight")
    }
    else if (this.bodytypeid == null && this.bodytypeid == undefined){
      alert("Please Enter Your Body Type")
    }
    else if (form.controls["mothertoungue"].value == null && form.controls["mothertoungue"].value == ""){
      alert("Please Enter Your Mother Toungue")
    }
    else if (this.physicalstatusid == null && this.physicalstatusid == undefined){
      alert("Please Enter Your Physical Status Type")
    }
    else if (form.controls["residenceaddress"].value == null && form.controls["residenceaddress"].value == ""){
      alert("Please Enter Your Residence Address")
    }
    else if (this.stateid == null && this.stateid == undefined){
      alert("Please Select Your State")
    }
    else if (this.countryid == null && this.countryid == undefined){
      alert("Please Select Your Country")
    }
    else if (form.controls["pincode"].value == null && form.controls["pincode"].value == ""){
      alert("Please Enter Your Pincode")
    }
    else if (this.religionId == null && this.religionId == undefined){
      alert("Please Select Your Religion")
    }
    else if (this.casteId == null && this.casteId == undefined){
      alert("Please Select Your Caste")
    }
    else if (this.SubcasteId == null && this.SubcasteId == undefined){
      alert("Please Select Your Sub-Caste")
    }
    else if (this.RaasiId == null && this.RaasiId == undefined){
      alert("Please Select Your Raasi")
    }
    else if (this.ZodiacId == null && this.ZodiacId == undefined){
      alert("Please Select Your Zodiac Sign")
    }
    else if (this.StarId == null && this.StarId == undefined){
      alert("Please Select Your Star")
    }
    else if (this.FoodPreferenceId == null && this.FoodPreferenceId == undefined){
      alert("Please Select Your Food Preference")
    }
    else if (this.GothramId == null && this.GothramId == undefined){
      alert("Please Select Your Gothram")
    }
    else if (this.ManglikId == null && this.ManglikId == undefined){
      alert("Please Select Your Manglik")
    }
    else if (this.DrinkingTypeId == null && this.DrinkingTypeId == undefined){
      alert("Please Select Your Drinking Type")
    }
    else if (this.SmokingTypeId == null && this.SmokingTypeId == undefined){
      alert("Please Select Your Smoking Type")
    }
    else if (this.qualificationid == null && this.qualificationid == undefined){
      alert("Please Select Your Qualification")
    }
    else if (this.EmployedInId == null && this.EmployedInId == undefined){
      alert("Please Select Your EmployedIn")
    }
    else if (form.controls["institutionname"].value == null && form.controls["institutionname"].value == ""){
      alert("Please Enter Your Institution Name")
    }
    else if (this.OccupationId == null && this.OccupationId == undefined){
      alert("Please Select Your Occupation")
    }
    else if (this.DesiginationId == null && this.DesiginationId == undefined){
      alert("Please Select Your Desigination")
    }
    else if (this.SalaryId == null && this.SalaryId == ""){
      alert("Please Select Your Annual Income Range")
    }
    else if (form.controls["natureofjob"].value == null && form.controls["natureofjob"].value == ""){
      alert("Please Explain shortly about your Nature Of Job")
    }
    else if (this.FamilyTypeId == null && this.FamilyTypeId == undefined){
      alert("Please Select Your Family Type")
    }
    else if (this.FamilyValueId == null && this.FamilyValueId == undefined){
      alert("Please Select Your  Family Value")
    }
    else if (this.FamilyStatusId == null && this.FamilyStatusId == undefined){
      alert("Please Select Your Family Status")
    }
    else if (this.NumberOfSiblingsId == null && this.NumberOfSiblingsId == undefined){
      alert("Please Select Your the Number Of Siblings")
    }
    else if (form.controls["familynativeplace"].value == null && form.controls["familynativeplace"].value == ""){
      alert("Please Enter Your Native Place")
    }
    else if (form.controls["fathersname"].value == null && form.controls["fathersname"].value == ""){
      alert("Please Enter Your Father's Name")
    }
    else if (form.controls["fathersage"].value == null && form.controls["fathersage"].value == ""){
      alert("Please Enter Your Father's Age")
    }
    else if (this.fatherqualificationid == null && this.fatherqualificationid == undefined){
      alert("Please Select Your Father's Qualification")
    }
    else if (this.fathercasteId == null && this.fathercasteId == undefined){
      alert("Please Select Your Father's Caste")
    }
    else if (this.fathersubcasteId == null && this.fathersubcasteId == undefined){
      alert("Please Select Your Father's Sub-Caste")
    }
    else if (form.controls["fathersemail"].value == null && form.controls["fathersemail"].value == ""){
      alert("Please Enter Your Father's Email")
    }
    else if (form.controls["fathersmobile"].value == null && form.controls["fathersmobile"].value == ""){
      alert("Please Enter Your Father's Mobile")
    }
    else if (form.controls["mothersname"].value == null && form.controls["mothersname"].value == ""){
      alert("Please Enter Your Mother's Name")
    }
    else if (form.controls["mothersage"].value == null && form.controls["mothersage"].value == ""){
      alert("Please Enter Your Mother's Age")
    }
    else if (this.motherqualificationid == null && this.motherqualificationid == undefined){
      alert("Please Select Your Mother's Qualification")
    }
    else if (this.mothercasteId == null && this.mothercasteId == undefined){
      alert("Please Select Your Mother's Caste")
    }
    else if (this.mothersubcasteId == null && this.mothersubcasteId == undefined){
      alert("Please Select Your Mother's Sub-Caste")
    }
    else if (form.controls["mothersemail"].value == null && form.controls["mothersemail"].value == ""){
      alert("Please Enter Your Mother's Email")
    }
    else if (form.controls["mothersmobile"].value == null && form.controls["mothersmobile"].value == ""){
      alert("Please Enter Your Mother's Mobile")
    }
    else if (this.MarriageTypeId == null && this.MarriageTypeId == undefined){
      alert("Please Select Your Marriage Type")
    }
    else if (form.controls["familynetworth"].value == null && form.controls["familynetworth"].value == ""){
      alert("Please Enter Your Family Net Worth")
    }
    else if (form.controls["landmark"].value == null && form.controls["landmark"].value == ""){
      alert("Please Provide Us Your Landmark To Address")
    }
    else if (form.controls["terms"].value == null && form.controls["terms"].value == ""){
      alert("Please Agree to Terms and Conditions.")
    }
    else {
      const inputRequest: BrideGroomDetails = {
        BrideGroomId: this.MatrimonyId,
        FirstName: form.controls["fname"].value,
        MiddleName: form.controls["mname"].value,
        LastName: form.controls["lname"].value,
        DateOfBirth: form.controls["dob"].value,
        BrideGroomEmail: form.controls["bridegroomemail"].value,
        BrideGroomMobile: form.controls["bridegroommobile"].value,
        Gender: this.genderid,
        TypeOfBody: this.bodytypeid,
        Complextion: this.complextionid,
        PhysicalStatus: this.physicalstatusid,
        Height: this.heightid,
        Weight: form.controls["weight"].value,
        PreviousMaritalStatus: this.maritalStatusId,
        MotherToungue: form.controls["mothertoungue"].value,
        NoOfChildern: form.controls["noofchild"].value,
        Landmark: form.controls["landmark"].value,
        CurrentResidenceAddress: form.controls["residenceaddress"].value,
        State: this.stateid,
        Country: this.countryid,
        Pincode: form.controls["pincode"].value,
        ReligionByBirth: this.religionId,
        CasteByBirth: this.casteId,
        SubcasteByBirth: this.SubcasteId,
        Raaasi: this.RaasiId,
        ZodiacSign:this.ZodiacId,
        Star: this.StarId,
        Manglik: this.ManglikId,
        Gothram: this.GothramId,
        GothramMeteranal: this.GothramIdMeteranal,
        FoodPreferences: this.FoodPreferenceId,
       
        Smoking: this.SmokingTypeId,
        Drinking: this.DrinkingTypeId,
        HighestQualification: this.qualificationid,
        JobORBusiness: this.OccupationId,
        InstitutionName: form.controls["institutionname"].value,
        Designation: this.DesiginationId,
        AnnualIncome: this.SalaryId,
        DescribeTheNatureofJobORBusiness: form.controls["natureofjob"].value,
        FamilyType: this.FamilyTypeId,
        FamilyValues: this.FamilyValueId,
        FamilyStatus: this.FamilyStatusId,
        FamilyNativePlace: form.controls["familynativeplace"].value,
        NoOfSiblings: this.NumberOfSiblingsId,
        FatherName: form.controls["fathersname"].value,
        FatherAge: form.controls["fathersage"].value,
        FatherEducation: this.fatherqualificationid,
        FatherOccupation: this.fatherOccupationId,
        FatherCaste: this.fathercasteId,
        FatherSubcaste: this.fathersubcasteId,
        FatherPhoneNumber: form.controls["fathersmobile"].value,
        FatherEmail: form.controls["fathersemail"].value,
        MotherName: form.controls["mothersname"].value,
        MotherAge: form.controls["mothersage"].value,
        MotherEducation: this.motherqualificationid,
        MotherOccupation: this.motherOccupationId,
        MotherCaste:this.mothercasteId ,
        MotherSubcaste: this.mothersubcasteId,
        MotherPhoneNumber: form.controls["mothersmobile"].value,
        MotherEmail: form.controls["mothersemail"].value,
        MarriageType: this.MarriageTypeId,
        FamilyNetWorth: form.controls["familynetworth"].value,
        AboutGroomBride:form.controls["abttgroombride"].value,
        CurrentCity:form.controls["currentcity"].value,
        EmployeedIn: this.EmployedInId,
        OrganizationName:form.controls["orgName"].value,
        NoOfBrothers:form.controls["numofbro"].value,
        NoOfSisters:form.controls["numofsis"].value,
        NoOfBrothersMarried:form.controls["numofbromarried"].value,
        NoOfSistersMarried:form.controls["numofsismarried"].value,
        AboutFamily:form.controls["aboutfamily"].value,
        
      }
      this.bridegroomService.UpdateBrideGrromCompleteDetails(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Updated Your Details Successfully");
          this.router.navigate(['/index']);
          this.spinner.hide();
        }
        else {
          alert("Please Fill Details Properly");
          this.spinner.hide();
        }
      });
    }
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
  onSelectSalary(id) {
    this.SalaryId = id
  }
  GetSalaryRanges() {
    this.bridegroomService.GetSalaryRanges().subscribe((response: any) => {
      this.SalariesRange = response;
    });
  }
  onSelectDrinkingType(id) {
    this.DrinkingTypeId = id
  }
 
  GetDrinkingTypeTypes() {
    this.registrationService.GetDrinkingTypeTypes().subscribe((response: any) => {
      this.DrinkingTypes = response;
    });
  }
  onSelectHeight(id) {
    this.heightid = id
  }
  GetHeights() {
    this.registrationService.GetHeights().subscribe((response: any) => {
      this.Heights = response;
    });
  }
  onSelectEmployedIn(id) {
    this.EmployedInId = id
  }
  GetEmployedIn() {
    this.registrationService.GetEmployedIn().subscribe((response: any) => {
      this.EmployedIns = response;
    });
  }
  onSelectSmokingeType(id) {
    this.SmokingTypeId = id
  }
  GetSmokingTypeTypes() {
    this.registrationService.GetSmokingTypeTypes().subscribe((response: any) => {
      this.SmokingTypes = response;
    });
  }
  onSelectMarriageType(id) {
    this.MarriageTypeId = id
  }
  GetMarriageTypeTypes() {
    this.registrationService.GetMarriageTypeTypes().subscribe((response: any) => {
      this.MarriageTypes = response;
    });
  }
  onSelectNumberOfSiblings(id) {
    this.NumberOfSiblingsId = id
  }
  GetNumberOfSiblingsTypes() {
    this.registrationService.GetNumberOfSiblingsTypes().subscribe((response: any) => {
      this.Siblings = response;
    });
  }
  onSelectFamilyStatus(id) {
    this.FamilyStatusId = id
  }
  GetFamilyStatusTypes() {
    this.bridegroomService.GetFamilyStatusesTypes().subscribe((response: any) => {
      this.FamilyStatuses = response;
    });
  }
  onSelectFamilyType(id) {
    this.FamilyTypeId = id
  }
  GetFamilyTypesTypes() {
    this.bridegroomService.GetFamilyTypeTypes().subscribe((response: any) => {
      this.FamilyTypes = response;
    });
  }
  onSelectFamilyValue(id) {
    this.FamilyValueId = id
  }
  GetFamilyValuesTypes() {
    this.bridegroomService.GetFamilyValueTypes().subscribe((response: any) => {
      this.FamilyValues = response;
    });
  }
  onSelectDesignation(id) {
    this.DesiginationId = id
  }
  GetDesiginationTypes() {
    this.registrationService.GetDesiginationTypes().subscribe((response: any) => {
      this.Desiginations = response;
    });
  }
  onSelectOccupation(id) {
    this.OccupationId = id
  }
  onSelectFatherOccupaction(id) {
    this.fatherOccupationId = id
  }
  onSelectMotherOccupaction(id) {
    this.motherOccupationId = id
  }
  GetOccupationTypes() {
    this.bridegroomService.GetOccupationTypes().subscribe((response: any) => {
      this.Occupations = response;
    });
  }
  onSelectManglik(id) {
    this.ManglikId = id
  }
  GetManglikTypes() {
    this.registrationService.GetManglikTypes().subscribe((response: any) => {
      this.Mangliks = response;
    });
  }
  onSelectGothram(id) {
    this.GothramId = id
  }
  onSelectGothramMeteranal(id) {
    this.GothramIdMeteranal = id
  }
  GetGothramTypes() {
    this.registrationService.GetGothramTypes().subscribe((response: any) => {
      this.Gothrams = response;
    });
  }
  onSelectFoodPreferences(id) {
    this.FoodPreferenceId = id
  }
  GetFoodPreferencesTypes() {
    this.registrationService.GetFoodPreferenceTypes().subscribe((response: any) => {
      this.FoodPreferences = response;
    });
  }
  onSelecStar(id) {
    this.StarId = id
  }
  GetStarsTypes() {
    this.bridegroomService.GetStarTypes().subscribe((response: any) => {
      this.Stars = response;
    });
  }
  onSelectRaasi(id) {
    this.RaasiId = id
  }
  GetRaasiTypes() {
    this.registrationService.GetRaasiTypes().subscribe((response: any) => {
      this.Raasis = response;
    });
  }
  onSelectZodiac(id) {
    this.ZodiacId = id
  }
  GetZodiacs() {
    this.registrationService.GetZodiacs().subscribe((response: any) => {
      this.Zodiacs = response;
    });
  }
  onSelectSubCaste(id) {
    this.SubcasteId = id
  }
  onSelectFatherCaste(id) {
    this.fathercasteId = id
  }
  onSelectFathersubCaste(id) {
    this.fathersubcasteId = id
  }
  onSelectMotherCaste(id) {
    this.mothercasteId = id
  }
  onSelectMothersubCaste(id) {
    this.mothersubcasteId = id
  }
  GetsubCasteTypes() {
    this.bridegroomService.GetsubCasteTypes().subscribe((response: any) => {
      this.subCastes = response;
    });
  }
  onSelectCaste(id) {
    this.casteId = id
  }
  GetCasteTypes() {
    this.bridegroomService.GetCasteTypes().subscribe((response: any) => {
      this.Castes = response;
    });
  }
  onSelectReligion(id) {
    this.religionId = id
  }
  GetReligionTypes() {
    this.registrationService.GetreligionTypes().subscribe((response: any) => {
      this.Religions = response;
    });
  }
  onSelectPhysicalStatus(id) {
    this.physicalstatusid = id
  }
  GetPhysicalStatusTypes() {
    this.registrationService.GetphysicalStatusTypes().subscribe((response: any) => {
      this.PhysicalStatuses = response;
    });
  }
  onSelectBodyType(id) {
    this.bodytypeid = id
  }
  GetBodyTypes() {
    this.registrationService.GetBodyTypes().subscribe((response: any) => {
      this.BodyTypes = response;
    });
  }
  onSelectComplextion(id) {
    this.complextionid = id
  }
  GetComplextions() {
    this.registrationService.GetComplextions().subscribe((response: any) => {
      this.Complextions = response;
    });
  }
  onSelectPreviousMaritalStatus(id) {
    this.maritalStatusId = id
  }
  GetMaritalStatus() {
    this.registrationService.GetPreviousMaritalStatus().subscribe((response: any) => {
      this.PerviousMaritalStatus = response;
    });
  }
  onSelectMotherQualifications(id) {
    this.motherqualificationid = id
  }
  onSelectFatherQualifications(id) {
    this.fatherqualificationid = id
  }
  onSelectQualifications(id) {
    this.qualificationid = id
  }
  GetQualifications() {
    this.registrationService.GetQualifications().subscribe((response: any) => {
      this.Qualifications = response;
    });
  }
  GetGender(Gender: any): any {
    for (let index = 0; index < this.Genders.length; index++) {
      if (Gender == this.Genders[index].id) {
        return this.Genders[index];
      }
    }
  }
  onSelectGenders(id) {
    this.genderid = id
  }
  GetGenders() {
    this.registrationService.GetGenders().subscribe((response: any) => {
      this.Genders = response;
    });
  }
  
  onSelectState(id) {
    this.stateid = id
  }
  GetStates() {
    this.registrationService.GetStates().subscribe((response: any) => {
      this.States = response;
    });
  }

  onSelectCountry(id) {
    this.countryid = id
  }
  GetCountries() {
    this.registrationService.GetCountries().subscribe((response: any) => {
      this.Countries = response;
    });
  }
  

}
