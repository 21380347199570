import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DesignationViewModel } from '../ViewModels/DesignationViewModel';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {
  readonly rooturl1 = "https://blockchainmatrimony.com/matrimonyusmapi";

  constructor(private http: HttpClient) { }

  saveDesignation(data: DesignationViewModel) {
    return this.http.post(this.rooturl1 + "/api/DesignationMaster/PostSaveDesignation", data);
  }
  getDesignation() {
    return this.http.get(this.rooturl1 + "/api/DesignationMaster/GetDesignation");
  }
  deletedesignation(id: number) {
    return this.http.delete(this.rooturl1 + "/api/DesignationMaster/DeleteDesignationdata/" + id);
  }

  UpdateDesignation(data: DesignationViewModel) {
    return this.http.put(this.rooturl1 + "/api/DesignationMaster/PutDesignation", data);
  }
}