import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FolderviewService {
// readonly url = "http://localhost:8538";
readonly url = "http://analyticals.co/digitalidentitydmsapi";


constructor(private http: HttpClient) { }

viewDocument(data){
  return this.http.post(this.url +"/api/UploadDocument/DownloadandViewDocument",data);
}
GetDataUsed(id){
  return this.http.post(this.url +"/api/UploadDocument/PostAvailabledataAsync",id);
}
}
