import { Component, OnInit } from '@angular/core';
import { MaritalStatusViewModel } from 'src/app/shared/ViewModels/MaritalStatusViewModel';
import { NgForm } from '@angular/forms';
import { MaritalstatusService } from 'src/app/shared/maritalstatus/maritalstatus.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maritalstatus',
  templateUrl: './maritalstatus.component.html',
  styleUrls: ['./maritalstatus.component.css']
})
export class MaritalstatusComponent implements OnInit {

  MaritalStatusList: any;
  maritalstatus: any;
  maritalstatusId: any; genid: any;

  constructor(private maritalstatusService:MaritalstatusService , private router: Router) { }

  ngOnInit() {
    this.Maritalstatus();
    this.maritalstatus = "";
  }
  
  ResetData(){
    this.maritalstatus = "";
  }

  Maritalstatus() {
    this.maritalstatusService.getMaritalStatus().subscribe((Data: MaritalStatusViewModel[]) => {
      this.MaritalStatusList = Data;
    })
  }

  SaveMaritalStatusForm(form: NgForm) {
    const inputRequest: MaritalStatusViewModel = {
      ID: 0,
      MaritalStatus: form.controls["maritalstatus"].value,
    }
    this.maritalstatusService.saveMaritalStatus(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Added Successfully");
        this.router.navigate(['/maritalstatus']);
      }
      else {
        alert("Something went Wrong");
        this.router.navigate(['/maritalstatus']);
      }
    });
  }

  editmaritalstatus(data: { maritalStatus: any; id: any; }) {
    this.maritalstatus = data.maritalStatus;
    this.maritalstatusId = data.id;
  }

  UpdateMaritalStatus(form: NgForm) {
    const inputRequest1: MaritalStatusViewModel = {
      ID: this.maritalstatusId,
      MaritalStatus: form.controls["maritalstatus"].value,
    }
    this.maritalstatusService.UpdateMaritalStatus(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("MaritalStatus Updated Succesfully");
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }
  deletemaritalstatus(data: { id: any; }) {
    this.genid = data.id;
    this.maritalstatusService.deletemaritalstatus(this.genid).subscribe(
      (data: any) => {
        if (data != null) {
          alert("MaritalStatus Deleted Succesfully");
          window.location.reload();
        } else {
          alert("Something Went Wrong. Try again.!!");
          window.location.reload();
        }
      });
  }

}


  
 