import { Component, OnInit } from '@angular/core';
import { ChatDetail } from '../shared/ViewModels/ChatDetail';
import { UserServiceService } from '../shared/Services/UserService/user-service.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserId } from '../shared/ViewModels/UserIdViewModel';
import { IndividualChatDetails } from '../shared/ViewModels/IndividualChatDetails';

@Component({
  selector: 'app-saveuserchat',
  templateUrl: './saveuserchat.component.html',
  styleUrls: ['./saveuserchat.component.css']
})
export class SaveuserchatComponent implements OnInit {

  chatdetail: ChatDetail;
  Users: any;
  userid: any;
  isLoggedIn: boolean;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any; chaatto: any;
  aaaaa: ChatDetail;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any;
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  constructor(private userservice: UserServiceService, private router: Router, ) {
    this.isLoggedIn = true;
    this.userid = localStorage.getItem("userId");
    this.currentuser = localStorage.getItem("UserName");
    this.BMid = localStorage.getItem("uid")

  }

  ngOnInit() {
    this.GetUsers();
    this.loadChatDetail();
    this.GetCurrentUserImage();
  }

  OnSelectUser(id) {
    this.touserid = id;
    this.userservice.GetToUser(this.touserid ).subscribe((respose: any) => {
      this.touser = respose;
    });   
  }
  SaveUserChat(form: NgForm) {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: this.touserid,
      ChatToName: this.touser.userName,
      ChatMessage: form.controls["chatmessage"].value,
      IsEnabled: true,
      ChatFromUId:this.BMid,
      ChatToUserUId:this.touser.uid,

    }
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Message Sent Successfully");
      }
    })
  }

  GetUsers() {
    const inputRequest: UserId = {
      userid: this.userid
    }
    this.userservice.GetAllUsersExceptMe(inputRequest).subscribe((respose: any) => {
      this.Users = respose;
    });
  }

  GetCurrentUserImage() {
    this.userservice.getCurrentUSerImage(this.userid).subscribe((respose: any) => {
      this.UserImage = respose;
    });
  }


  loadChatDetail() {
    this.userservice.GetChatforParticularUser(this.BMid).subscribe((chat: any) => {
      this.chatdetail = chat
    });
  }

  OpenChatWindow(chat) {
    this.chatvar = chat;
    this.chattouserid = chat.ChatFrom;
    this.chaatto = chat.UserID;
    this.chatopen = true;
    const inputRequest: IndividualChatDetails = {
      Chatid: chat.Id,
      ChatFrom: chat.UserID,
      ChatTo: chat.ChatFrom
    }
    this.userservice.GetIndividualChatRecivedDetails(inputRequest).subscribe((data: any) => {
      this.IndividualChatRecivedDetails = data.recivedchatdetails;
      this.IndividualChatSentDetails = data.sentchatdetails;

      if (this.userid == chat.ChatTo) {
        this.uid = true;

      }
    })

  }
  goto() {
    this.router.navigate(['#/saveUserChat']);
  }
  SendIndividualMessage(form: NgForm) {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: "",
      ChatTo: this.chaatto,
      ChatToName: "",
      ChatMessage: form.controls["individualchatmessage"].value,
      IsEnabled: true,
      ChatFromUId:this.BMid,
      ChatToUserUId:this.chaatto
    }
    this.aaaaa = inputRequest;
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.OpenChatWindow(this.chatvar);
      }
    })

  }

  CloseChatWindow() {
    this.chatopen = false;

  }
}
