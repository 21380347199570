import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllFolders } from '../../ViewModels/AllFolders';
import { DocuSignVM } from '../../ViewModels/DocuSignVM';
import { Share } from '../../ViewModels/Share';

@Injectable({
  providedIn: 'root'
})
export class AllfilesService {

  readonly url = "https://blockchainmatrimony.com/matrimonydms";

  //readonly url = "http://analyticals.co/haspitdmsapi";

  constructor(private http: HttpClient) { }
  getsubfolders(id) {
    return this.http.get(this.url + "/api/UploadDocument/Getdocument/" + id);
  }
  deletefolder(aa) {
    return this.http.post(this.url + "/api/FolderCreation/PostDeleteContent", aa);
  }

  UpdateFolderName(data){
    return this.http.put(this.url + "/api/FolderCreation/PutFolderName" , data);
  }
  
  addNewSubFolder(data: AllFolders) {
    return this.http.post(this.url + "/api/FolderCreation/PostFolderMaster", data);
  }
  getbackdata(pid) {
    return this.http.get(this.url + "/api/UploadDocument/GetBackdocument/" + pid);
  }

  GetDataUsed(id){
    return this.http.get(this.url +"/api/Search/GetAvailabledata/"+id);
  }

  DocuSign(data: DocuSignVM) {
    return this.http.post(this.url + "/api/DocumentSign/PostDocuSign", data);
  }

  MicrosoftSign(data: DocuSignVM) {
    return this.http.post(this.url + "/api/DocumentSign/PostMicrosoftSign", data);
  }

  Esign(data: DocuSignVM) {
    return this.http.post(this.url + "/api/DocumentSign/PostSpireSign", data);
  }
  ShareFiles(data: Share) {
    return this.http.post(this.url + "/api/Contract/ShareFiles", data);
  }
}
