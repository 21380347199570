import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PhysicalStatusViewModel } from 'src/app/shared/ViewModels/PhysicalStatusViewModel';
import { PhysicalstatusService } from 'src/app/shared/physicalstatus/physicalstatus.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-physicalstatus',
  templateUrl: './physicalstatus.component.html',
  styleUrls: ['./physicalstatus.component.css']
})
export class PhysicalstatusComponent implements OnInit {

  PhysicalStatusList: any;
  physicalstatus: any;
  physicalstatusId: any; genid: any;
  
    constructor(private physicalstatusService:PhysicalstatusService , private router: Router) { }
  
    ngOnInit() {
      this.PhysicalStatus();
      this.physicalstatus = "";
    }
    ResetData(){
      this.physicalstatus = "";
    }
  
    PhysicalStatus() {
      this.physicalstatusService.getPhysicalStatus().subscribe((Data: PhysicalStatusViewModel[]) => {
        this.PhysicalStatusList = Data;
      })
    }
  
    SavePhysicalStatusForm(form: NgForm) {
      const inputRequest: PhysicalStatusViewModel = {
        ID: 0,
        PhysicalType:form.controls["physicalstatus"].value,
      }
      this.physicalstatusService.savePhysicalStatus(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/physicalstatus']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/physicalstatus']);
        }
      });
    }
  
    editphysicalstatus(data: { physicalType: any; id: any; }) {
      this.physicalstatus = data.physicalType;
      this.physicalstatusId = data.id;
    }
  
    UpdatePhysicalStatus(form: NgForm) {
      const inputRequest1: PhysicalStatusViewModel = {
        ID: this.physicalstatusId,
        PhysicalType: form.controls["physicalstatus"].value,
      }
      this.physicalstatusService.UpdatePhysicalStatus(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("PhysicalStatus Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletephysicalstatus(data: { id: any; }) {
      this.genid = data.id;
      this.physicalstatusService.deletephysicalstatus(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("PhysicalStatus Deleted Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  