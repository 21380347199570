import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { UserServiceService } from 'src/app/shared/Services/UserService/user-service.service';
import { ChangePassword } from 'src/app/shared/ViewModels/ChangePassword';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  p1: any;
  p2: any;
  displayUID: string;

  constructor(private userService:UserServiceService, private spinner: NgxSpinnerService) {
    this.displayUID = localStorage.getItem("userId");
     }

  ngOnInit() {
  }
  ChangePassword(form:NgForm)
  {
    this.spinner.show();

    this.p1=form.controls["passwordone"].value;
    this.p2=form.controls["passwordtwo"].value;
    if(this.p1 != this.p2){
        alert("Enter Password Correctly")
    }
    const inputRequest:ChangePassword={
      CustomerId:this.displayUID,
      MobileNumber:"",
      Password:form.controls["passwordone"].value,
      ConfirmPassword:form.controls["passwordtwo"].value,
    }
    this.userService.postchangePasswordAfterLogin(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          alert("Your Password Was Successfully Changed");
          this.spinner.hide();
        }else{
          alert("Something Went Wrong. Try again.!!");
          this.spinner.hide();

          window.location.reload();
        } 
      });
  }

}
