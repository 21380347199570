import { Component, OnInit } from '@angular/core';
import { SubCasteTypeViewModel } from 'src/app/shared/ViewModels/SubCasteViewModel';
import { NgForm } from '@angular/forms';
import { SubcasteService } from 'src/app/shared/subcaste/subcaste.service';
import { CasteTypeViewModel } from 'src/app/shared/ViewModels/CasteTypeViewModel';
import { CastetypeService } from 'src/app/shared/castetype/castetype.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subcaste',
  templateUrl: './subcaste.component.html',
  styleUrls: ['./subcaste.component.css']
})
export class SubcasteComponent implements OnInit {

  SubCasteTypeList: any;
  subcaste: any;
  subcasteId: any; genid: any;
  CasteTypeList: CasteTypeViewModel[];
  subcastetype: string;
  
  
    constructor(private subcasteService:SubcasteService , private castetypeService:CastetypeService,  private router: Router) { }
  
    ngOnInit() {
      this.SubCasteType();
      this.CasteType();
      this.subcastetype = "";
    }
    ResetData(){
      this.subcastetype = "";
    }
  
    CasteType() {
      this.castetypeService.getCasteType().subscribe((Data: CasteTypeViewModel[]) => {
        this.CasteTypeList = Data;
      })
    }
    SubCasteType() {
      this.subcasteService.getSubCasteType().subscribe((Data: SubCasteTypeViewModel[]) => {
        this.SubCasteTypeList = Data;
      })
    }
  
    SaveSubCasteTypeForm(form: NgForm) {
      const inputRequest: SubCasteTypeViewModel = {
        ID: 0,
        CastTypeId:this.CasteType,
        SubCasteType: form.controls["subcastetype"].value,
      }
      this.subcasteService.saveSubCasteType(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/subcastetype']);
          window.location.reload();
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/subcastetype']);
          window.location.reload();
        }
      });
    }
  
    editsubcastetype(data: { subCasteType: any; id: any; }) {
      this.subcastetype = data.subCasteType;    
      this.subcasteId = data.id;
    }
  
    UpdateSubCasteType(form: NgForm) {
      const inputRequest1: SubCasteTypeViewModel = {
        ID: this.subcasteId,
        CastTypeId:this.CasteType,
        // CastTypeId:form.controls["casttype"].value,
        SubCasteType: form.controls["subcastetype"].value,
      }
      this.subcasteService.UpdateSubCasteType(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("SubCasteType Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletesubcastetype(data: { id: any; }) {
      this.genid = data.id;
      this.subcasteService.deletesubcastetype(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("SubCasteType Deleted Succesfully");
            window.location.reload();
          } else {
            alert("SubCasteType Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }

    onSelectCast(id) {
      this.CasteType = id
    }
  
  }
  
  
    

  