import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CastetypeService } from 'src/app/shared/castetype/castetype.service';
import { Router } from '@angular/router';
import { CasteTypeViewModel } from 'src/app/shared/ViewModels/CasteTypeViewModel';

@Component({
  selector: 'app-castetype',
  templateUrl: './castetype.component.html',
  styleUrls: ['./castetype.component.css']
})
export class CastetypeComponent implements OnInit {
  CasteTypeList: any;
  castetype: any;
  castetypeId: any; genid: any;
  
    constructor(private castetypeService:CastetypeService , private router: Router) { }
  
    ngOnInit() {
      this.CasteType();
      this.castetype = "";
    }
    ResetData(){
      this.castetype = "";
    }
  
    CasteType() {
      this.castetypeService.getCasteType().subscribe((Data: CasteTypeViewModel[]) => {
        this.CasteTypeList = Data;
      })
    }
  
    SaveCasteTypeForm(form: NgForm) {
      const inputRequest: CasteTypeViewModel = {
        ID: 0,
        CasteName: form.controls["castetype"].value,
      }
      this.castetypeService.saveCasteType(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/castetype']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/castetype']);
          window.location.reload();
        }
      });
    }
  
    editcastetype(data: { casteName: any; id: any; }) {
      this.castetype = data.casteName;
      this.castetypeId = data.id;
    }
  
    UpdateCasteType(form: NgForm) {
      const inputRequest1: CasteTypeViewModel = {
        ID: this.castetypeId,
        CasteName: form.controls["castetype"].value,
      }
      this.castetypeService.UpdateCasteType(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("CasteType Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
    deletecastetype(data: { id: any; }) {
      this.genid = data.id;
      this.castetypeService.deletecastetype(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("CasteType Deleted Succesfully");
            window.location.reload();
          } else {
            alert("CasteType Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  