import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SmokingViewModel } from 'src/app/shared/ViewModels/SmokingViewModel';
import { SmokingService } from 'src/app/shared/smoking/smoking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smoking',
  templateUrl: './smoking.component.html',
  styleUrls: ['./smoking.component.css']
})
export class SmokingComponent implements OnInit {
  SmokingList: any;
  smoking: any;
  smokingId: any; genid: any;
  
    constructor(private smokingService:SmokingService , private router: Router) { }
  
    ngOnInit() {
      this. Smoking();
      this.smoking = "";
    }
    ResetData(){
      this.smoking = "";
    }
  
    Smoking() {
      this.smokingService.getSmoking().subscribe((Data:SmokingViewModel[]) => {
        this.SmokingList = Data;
      })
    }
  
    SaveSmokingForm(form: NgForm) {
      const inputRequest: SmokingViewModel = {
        ID: 0,
        SmokingType: form.controls["smoking"].value,
      }
      this.smokingService.SaveSmoking(inputRequest).subscribe((data: any) => {
        if (data != null) {
          alert("Added Successfully");
          this.router.navigate(['/smoking']);
        }
        else {
          alert("Something went Wrong");
          this.router.navigate(['/smoking']);
          window.location.reload();
        }
      });
    }
  
    editsmoking(data: { smokingType: any; id: any; }) {
      this.smoking = data.smokingType;
      this.smokingId = data.id;
    }
    
  
    UpdateSmoking(form: NgForm) {
      const inputRequest1: SmokingViewModel = {
        ID: this.smokingId,
        SmokingType: form.controls["smoking"].value,
      }
      this.smokingService.UpdateSmoking(inputRequest1).subscribe(
        (data: any) => {
          if (data == 1) {
            alert("Smoking Updated Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
 

    deletesmoking(data: { id: any; }) {
      this.genid = data.id;
      this.smokingService.deletesmoking(this.genid).subscribe(
        (data: any) => {
          if (data != null) {
            alert("Smoking Deleted Succesfully");
            window.location.reload();
        } else {
            alert("Smoking Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  
  }
  
  
    

  
