import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { AllfilesService } from 'src/app/shared/Services/AllFiles/allfiles.service';
import { AllfoldersService } from 'src/app/shared/Services/AllFolders/allfolders.service';
import { MoveService } from 'src/app/shared/Services/Move/move.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveFolderOrDocument } from 'src/app/shared/ViewModels/MoveFolderOrDocument';
import { MoveDocData } from 'src/app/shared/ViewModels/MoveDocData';
import { NgForm } from '@angular/forms';
import { AllFolders } from 'src/app/shared/ViewModels/AllFolders';
import { Search } from 'src/app/shared/ViewModels/Search';
import { DocuSignVM } from 'src/app/shared/ViewModels/DocuSignVM';
import { DownloadVM } from 'src/app/shared/ViewModels/DownloadVM';
import { Share } from 'src/app/shared/ViewModels/Share';
import { SmartContractService } from 'src/app/shared/Services/SmartContract/smart-contract.service';

@Component({
  selector: 'app-subfolders',
  templateUrl: './subfolders.component.html',
  styleUrls: ['./subfolders.component.css']
})
export class SubfoldersComponent implements OnInit {

  FileFoldID: any; allSubFolders: any;
  subf: any; fid: any; uid: any;
  subfolderdetails: any;
  roleid: any; sMsg: string;
  subfolderid: any;
  SubFolderName: any;
  userName: string | Blob;
  fl: string; pid: any;
  ParentId: any; id: any;
  imgdata: any; pdfdata: any;
  xlsdata: any; xlsDownloadpath: any;
  pdfdocname: any; videodata: any; Pid: any;
  currentfoldername: any;
  search: any; SearchResult: any;
  public SearchEnabled: boolean = false;
  shareitem: boolean;
  getMoveData: any; destinationFolder: any;
  sourceFolder: any; storedData: any;
  getSubFolderMoveData: any;
  getSubMoveData: any; getDocData: any;
  IsDOCorFolder: any; DownloadSaved: any;
  Hash: any; DocumentName: any;
  ImageType: any; FolderName: any;
  selected = []; CurrentFiles: any;
  public remove: any = false;
  public showcheck: any = false;
  splittedvalue: any; val: string;
  public continueshow: any = false;
  public showsettings: any = false;
  pdffile: any;
  SignType: boolean;
  responsemail: any;
  Demo: any;
  DataUsed: any;
  Friends: any;
  sharefile: boolean;
  SharedResult: any;
  worddata: any;
  pptdata: any;
  zipdata: any;
  txtdata: any;
  anyfiledata: any;
  docsize: any;
  DateCreated: any;
  CustId: any;
  CreatedOn: any;
  CusId: any;
  movefile: boolean;
  wordfile: any;
  wordsignfile: any;
  xlssignfile: any;
  pptfile: any;
  txtfile: any;
  videofile: any;
  imagefile: any;
  bmid: string;

  constructor(private router: ActivatedRoute, private route: Router, private httpService: HttpClient, private allfolderservice: AllfoldersService,
    private allfilesservice: AllfilesService, private spinner: NgxSpinnerService, private domSanitizer: DomSanitizer,
    private moveService: MoveService, private smartcontract: SmartContractService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("uid");
      this.bmid = localStorage.getItem("userId");
      this.userName = localStorage.getItem("UserName");
      var getSubDate = localStorage.getItem("CurrentSubFolder");
      this.getSubMoveData = JSON.parse(getSubDate);
    }
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.val = (params["id"]);
        this.splittedvalue = this.val.split("-");
        this.GetDetails(this.splittedvalue[0]);
        this.showcheck = false;
        this.continueshow = false;
        this.showsettings = true;
      }
      if (this.splittedvalue[1] != null) {
        this.showcheck = true;
        this.continueshow = true;
        this.showsettings = false;
      }

      this.SignType = false;
    });
  }

  ngOnInit() {
    this.shareitem = false;
    this.SubFolderName = "";
    //this.movefile = true;
    this.sharefile = true;
      //this.GetDataUsed();
    //this.GetFriendDetails();
  }
  ShareFile() {
    this.sharefile = false;
  }
  CancelShareFile() {
    this.sharefile = true;

  }


  GetDataUsed() {
    this.allfilesservice.GetDataUsed(this.uid).subscribe((respose: any) => {
      if (respose != null) {
        this.DataUsed = respose;
      }
      else {
        this.DataUsed = 0;
      }
    });
  }

  transform(html) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(html);
  }

  Share() {
    this.shareitem = true;
  }

  MoveDoc(data: any, id: any) {
    localStorage.setItem("IsDOCorFolder", id);
    localStorage.setItem("DocData", JSON.stringify(data));
  }

  MoveTo(data: any, id: any) {
    //this.shareitem = true;
    this.storedData = data;
    this.IsDOCorFolder = id;
    localStorage.setItem("IsDOCorFolder", id);
    localStorage.setItem("MoveData", JSON.stringify(data));
  }

  MoveDash1(data: any) {
   // this.shareitem = true;
    //Folder or SubFolder Data
    var getdata = localStorage.getItem("MoveData");
    this.getMoveData = JSON.parse(getdata);
    var isDOC = localStorage.getItem("IsDOCorFolder");
    //Doc Data
    var docdata = localStorage.getItem("DocData");
    this.getDocData = JSON.parse(docdata);
    if (isDOC == "Doc") {
      this.destinationFolder = this.FileFoldID;
      this.sourceFolder = this.getMoveData.FolderID;
      const inputRequest: MoveFolderOrDocument = {
        SourceId: this.sourceFolder,
        DestinationId: this.destinationFolder
      }
      this.moveService.saveMoveFolderOrDocument(inputRequest).subscribe((data: any) => {
        if (data == "1") {
          alert("Moved Successfully");
          this.pid = data.ParentID;
          this.GetDetails(this.pid);
          this.route.navigate(['/allfolders']);
          this.spinner.hide();
        }
        else {
          alert("Something went Wrong");
          this.GetDetails(this.pid);
          this.spinner.hide();
        }
      });
    } else if (isDOC == "Img") {
      const inputRequest1: MoveDocData = {
        AliasUid: this.getDocData.UserID,
        DId: this.FileFoldID,
        SId: this.getDocData.FolderId,
        BId: this.getDocData.BlockId
      }
      this.moveService.moveDocument(inputRequest1).subscribe((data: any) => {
        if (data == "1") {
          alert("Document Moved Successfully");
          this.GetDetails(this.pid);
          this.route.navigate(['/allfolders']);
          this.spinner.hide();
        }
        else {
          alert("Something went Wrong");
          this.spinner.hide();
        }
      });
    }
  }

  MoveDash(data: any) {
    this.shareitem = true;
    this.destinationFolder = this.storedData;
    this.sourceFolder = this.getMoveData.FolderID;
    const inputRequest: MoveFolderOrDocument = {
      SourceId: this.sourceFolder,
      DestinationId: this.destinationFolder
    }
    this.moveService.saveMoveFolderOrDocument(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("Moved Successfully");
        this.pid = data.ParentID;
        this.GetDetails(this.pid);
        this.spinner.hide();
      }
      else {
        alert("Something went Wrong");
        this.GetDetails(this.pid);
        this.spinner.hide();
      }
    });
  }

  CancelShare() {
    this.shareitem = true;
  }

  // get subfolders and files from main folder
  GetDetails(id) {
    this.FileFoldID = id;
    this.spinner.show();
    this.allfilesservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.SearchEnabled = false;
      this.currentfoldername = this.allSubFolders.FileName;
      this.fid = this.allSubFolders.ParentId;
      this.spinner.hide();
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
      for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
        if (this.allSubFolders.RetData[i].file != null) {
          this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file)
        }
      }
    });
  }

  // get folders and files from subfolder
  GetSubfolderdetails(data) {
    this.subfolderdetails = data;
    this.currentfoldername = data.FolderName;
    this.FileFoldID = data.FolderID;
    this.spinner.show();
    this.allfilesservice.getsubfolders(data.FolderID).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.spinner.hide();
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.ParentId));
      for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
        if (this.allSubFolders.RetData[i].file != null) {
          this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file)
        }
      }

    });
  }

  // Save New SubFolder
  SaveNewSubFolder(form: NgForm) {
    if (this.allSubFolders.SubFolders.length == 0) {
      this.fl = "0";
    }
    else {
      this.fl = this.allSubFolders.SubFolders[0].FolderID;
    }
    const inputRequest: AllFolders = {
      Id: 0,
      FolderID: this.fl,
      RoleId: this.roleid,
      FolderName: form.controls["SubFolderName"].value,
      UserId: this.uid,
      Code: "SubFolder",
      ParentID: this.allSubFolders.ParentId,
    }
    // this.spinner.show();
    this.allfilesservice.addNewSubFolder(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert("SubFolder Added Successfully");
        this.pid = data.ParentID;
        this.GetDetails(this.pid);
        this.spinner.hide();
      }
      else {
        alert("Something went Wrong");
        this.GetDetails(this.pid);
        this.spinner.hide();
      }
    });
  }

  //EDIT AND UPDATE SUB FOLDER
  EditSubFolder(data) {
    this.subfolderid = data.FolderID;
    this.SubFolderName = data.FolderName;
    this.ParentId = data.ParentID;
    this.id = data.Id
  }

  UpdateSubFolder(form: NgForm) {
    const inputRequest1: AllFolders = {
      FolderID: this.subfolderid,
      FolderName: form.controls["SubFolderName"].value,
      Code: "Folder",
      Id: this.id,
      ParentID: this.ParentId,
      RoleId: 0,
      UserId: this.uid
    }
    this.spinner.show();
    this.allfilesservice.UpdateFolderName(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          alert("Folder Name Updated Succesfully");
          this.spinner.hide();
          this.GetDetails(this.FileFoldID);
        } else {
          alert("Something Went Wrong. Try again.!!");
          this.spinner.hide();
          this.GetDetails(this.FileFoldID);
        }
      });
  }

  //DELETE SUB FOLDER OR DOC
  Delete(data) {
    var res;
    if (data.FolderName == null) {
      res = confirm("Do You Want to Delete " + data.DocumentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.FolderName + "!!!");
    }
    if (res == true) {
      if (data.Hash) {
        var info = {
          Id: data.Id,
          FolderID: data.FolderId,
          FolderName: data.FolderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.FolderId,
          RoleId: null,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    else {
      this.spinner.hide();
      this.GetDetails(this.FileFoldID);
    }
    this.spinner.show();
    this.allfilesservice.deletefolder(info).subscribe((data: any) => {
      alert("Deleted Successfully");
      //this.GetDataUsed();
      this.spinner.hide();
      this.GetDetails(this.FileFoldID);
    });
  }

  //UPLOAD DOCUMENT
  onChange(event: any) {
    let files = [].slice.call(event.target.files);
    const frmData = new FormData();
    for (var i = 0; i < files.length; i++) {
      frmData.append("fileUpload", files[i]);
    }
    frmData.append("userId", this.uid);
    frmData.append("username", this.userName);
    frmData.append("folderid", this.allSubFolders.ParentId);
    this.spinner.show();
    this.httpService.post('https://blockchainmatrimony.com/matrimonydms/api/UploadDocument/NewDocumentUpload/', frmData).subscribe(
      data => {
        this.sMsg = data as string;
        console.log(this.sMsg);
        if (this.sMsg == "1") {
          alert("File Uploaded Successfully");
          this.GetDetails(this.FileFoldID);
          //this.GetDataUsed();
          this.spinner.show();
        }
        else if (this.sMsg == "2") {
          alert("Storege Full");
          this.spinner.show();
        }
        else 
        {
          alert("Something went wrong. Try again");
          this.spinner.show();

        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

//view Image
ViewDoc(img) {
  this.imgdata = img
  this.imagefile = img.SignFile
}

//view pdf
ViewPdf(pdf) {
  this.pdffile = pdf.SignFile
  this.pdfdata = pdf.file
  this.pdfdocname = pdf.DocumentName
}

//view xls
ViewXls(xls) {
  this.xlsdata = xls
  this.xlssignfile = xls.SignFile
}
//view word
Viewword(word) {
  this.worddata = word
  this.wordfile = word.file;
  this.wordsignfile = word.SignFile
}
// view video
ViewVideo(video) {
  this.videodata = video
  this.videofile = video.SignFile
}
// view ppt
ViewPpt(ppt){
this.pptdata = ppt
this.pptfile = ppt.SignFile
}
//view zip
Viewzip(zip){
this.zipdata = zip
}
Viewtxt(txt){
  this.txtdata = txt
  this.txtfile = txt.SignFile
}
Viewanyfile(anyfiles){
  this.anyfiledata = anyfiles
}

  //Go back from subfolder to its parent folder
  GetBack(allSubFolders) {
    this.spinner.show();
    this.allfilesservice.getbackdata(allSubFolders.ParentId).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.currentfoldername = this.allSubFolders.FileName
      this.Pid = this.allSubFolders.ParentId;
      this.FileFoldID = this.allSubFolders.ParentId;
      this.allSubFolders.folderID = this.allSubFolders.ParentId;
      this.spinner.hide();
      if (this.allSubFolders.ParentId == "0") {
        localStorage.setItem('formdata', JSON.stringify(this.allSubFolders));
        this.route.navigate(['/allfolders']);
        this.spinner.hide();
      }
    });
  }

  //SEARCH BAR
  onSearch(searchValue: string) {
    this.search = searchValue;
    if (this.search.length != 0) {
      this.SearchEnabled = true;
    }
    else {
      this.SearchEnabled = false;
    }
    if (this.search.length > 2) {
      this.spinner.show();
      const inputRequest: Search = {
        Value: this.search,
        UserId: this.uid
      }
      this.spinner.show();
      this.allfolderservice.GetSearchedFiles(inputRequest).subscribe((data: any) => {
        if (data.Documents.length != 0 || data.Folders.length != 0) {
          this.SearchResult = data;
          this.spinner.hide();
          this.SearchResult = data;
          for (let i = 0; i < this.SearchResult.Documents.length; i++) {
            if (this.SearchResult.Documents[i].file != null) {
              this.SearchResult.Documents[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.SearchResult.Documents[i].file)
            }
          }
        }
        else {
          alert("No Results Found");
          this.spinner.hide();
          this.GetDetails(this.FileFoldID);
        }
      });
    }
  }

  // to download files
  goto(img) {
    const inputRequest1: DownloadVM = {
      Id: "0",
      AliasUserId: img.UserID,
      DocumentDownloadedDateTime: null,
      DocumentName: img.DocumentName
    }
    this.moveService.goDownloads(inputRequest1).subscribe((respose: any) => {
      this.DownloadSaved = respose;
      this.spinner.hide();
      this.route.navigate(['/subfolders']);
    });
  }

  Details(data: any) {
    this.Hash = data.Hash;
    if (this.Hash) {
      this.DocumentName = data.DocumentName;
      this.ImageType = data.DocumentType;
      this.docsize = data.DocumentSize
      this.DateCreated = data.date;
      this.CustId = data.UserID;
    }
    this.FolderName = data.FolderName;
    this.CreatedOn = data.CreatedOn;
    this.CusId = data.UserId;
    //this.Type = "Folder";
  }

  attachDocument(data: any) {
    this.CurrentFiles = data;
    if (data != null) {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].Id == data.Id) {
          var index = this.selected.indexOf(data);
          this.selected.splice(index, 1);
          this.remove = true;
        }
      }
      if (this.remove == false) {
        this.selected.push(data);
      }
    }
    this.remove = false;
  }

  Continue() {
    localStorage.setItem('SelectedFiles', JSON.stringify(this.selected));
    window.location.reload();
    window.close();
    localStorage.setItem("IsAttach", "0");
  }

  //Sign Document
  SignDocument() {
    this.SignType = true;
  }

  Docsign() {
    if (this.pdffile != null) {
      this.pdffile.file = this.pdffile.SignFile;
    }
    const inputRequest: DocuSignVM = {
      UserId: this.uid,
      DocumentDetails: this.pdffile,
      UserName: this.userName
    };
    this.spinner.show();
    this.allfilesservice.DocuSign(inputRequest).subscribe((respose: any) => {
      this.spinner.hide();
      this.responsemail = respose;
      window.location.reload();
    });
  }

  Microsoftsign() {
    if (this.pdffile != null) {
      this.pdffile.file = this.pdffile.SignFile;
    }
    const inputRequest: DocuSignVM = {
      UserId: this.uid,
      DocumentDetails: this.pdffile,
      UserName: this.userName
    };
    this.spinner.show();
    this.allfilesservice.MicrosoftSign(inputRequest).subscribe((respose: any) => {
      this.spinner.hide();
      this.responsemail = respose;
      window.location.reload();
    });
  }

  Esign() {
    if (this.pdffile != null) {
      this.pdffile.file = this.pdffile.SignFile;
    }
    const inputRequest: DocuSignVM = {
      UserId: this.uid,
      DocumentDetails: this.pdffile,
      UserName: this.userName
    };
    this.spinner.show();
    this.allfilesservice.Esign(inputRequest).subscribe((respose: any) => {
      this.spinner.hide();
      this.responsemail = respose;
      window.location.reload();
    });

  }

  GetFriendDetails() {
    this.spinner.show();
    this.smartcontract.friendlist(this.uid).subscribe((respose: any) => {
      this.Friends = respose;
      this.spinner.hide();
    });
  }
  toggle(data) {
    this.CurrentFiles = data;
    if (data != null) {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].Id == data.Id) {
          var index = this.selected.indexOf(data);
          this.selected.splice(index, 1);
          this.remove = true;
        }
      }
      if (this.remove == false) {
        this.selected.push(data);
      }
    }
    this.remove = false;
  }
  OnSelectFriendtoShare(id) {
    const inputRequest: Share = {
      SelectedFiles: this.selected,
      FriendListId: id,
      UserId: this.uid
    }
    if (this.selected.length == 0) {
      alert("Please Select atleast one file")
    }
    else if (id != null){
      this.spinner.show();
      this.allfilesservice.ShareFiles(inputRequest).subscribe((data: any) => {
        this.SharedResult = data;
        if(this.SharedResult == "sent"){
          alert("File Sent Successfully!");
          var fol = this.CurrentFiles;
          if (fol.Hash) {
            var info = {
              Id: fol.Id,
              FolderID: fol.FolderId,
               FolderName: this.currentfoldername,
              UserId: fol.UserID,
              Code: "Document",
              ParentID: fol.FolderId,
             
            };
            this.GetSubfolderdetails(info);
            this.spinner.hide();
            this.sharefile = true;
          }
        }
              
      });
    }
    
    else {
      alert("Select Users");
      this.spinner.hide();

    }
  }
  


 
}
